<template>
  <el-table class="wt-table" ref="elTable" cell-class-name="wt-cell" row-class-name="wt-table-row" header-cell-class-name="wt-header-cell" header-row-class-name="wt-header-row"
            :data="dataList" @selection-change="handleSelectionChange"
            v-loading="form.table ? form.table.loading : false"
            element-loading-background="rgba(0, 0, 0, 0.3)"
            :height="form.table && form.table.height ? form.table.height : null" v-bind="form.table ? form.table : {}" >
    <slot></slot>
    <template slot="empty">
      <div class="wt-table-empty">
        <span class="wt-table-empty-text">{{form.table && form.table.emptyText ? form.table.emptyText : '暂无数据，请选择搜索条件后查询'}}</span>
      </div>
    </template>
  </el-table>
</template>

<script>
export default {
  name: 'WtFormTable',
  isAutoComponent: true,
  props: ['form', 'height', 'isTableLoading', 'size'],
  data() {
    return {
    }
  },
  methods: {
    handleSelectionChange(val) {
      this.$emit('selection-change', val)
    },
    doLayout() {
      this.$refs.elTable.doLayout()
    }
  },
  computed: {
    dataList() {
      if (this.form == null || this.form.formResult == null) {
        return []
      }
      return this.form.formResult.list
    }
  }
}
</script>

<style lang="scss">
</style>
