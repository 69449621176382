<template>
  <div class="page-container">
    <wt-data-form class="wt-table-full" ref="dataForm" :form.sync="form"
                  request-list-url="/tCaseInfo/list"
                  request-delete-url="/tCaseInfo/delete"
                  request-detail-url="/tCaseInfo/detail">
      <template slot="search">
        <wt-search-form-item title="案例名称" v-model="form.formData.name" type="input"/>
        <wt-search-form-item title="创建日期" v-model="form.formData.createTimeRange"  @on-input-change="dateChange" type="daterange"/>
      </template>
      <template slot="toolbar">
        <wt-button type="primary" v-has-any-premission="['WDGLINFO:ADD']" @click="onClickAdd">新建案例</wt-button>
      </template>
      <template slot="table">
        <el-table-column type="index" label="序号"></el-table-column>
        <el-table-column label="案例名称">
          <template slot-scope="scope">
            <wt-text-button @click="onClickView(scope.row)">{{scope.row.name}}</wt-text-button>
          </template>
        </el-table-column>
        <el-table-column prop="createUserName" label="操作人" width="120"></el-table-column>
        <el-table-column prop="createTime" label="创建时间" width="150" :sortable='true'></el-table-column>
        <el-table-column label="操作" fixed="right" width="150">
          <template slot-scope="scope">
            <wt-text-button v-has-any-premission="['ALGLINFO:VIEW']" @click="onClickView(scope.row)">查看</wt-text-button>
            <wt-text-button v-has-any-premission="['ALGLINFO:UPDATE']" @click="onClickEdit(scope.row)">编辑</wt-text-button>
            <wt-text-button v-has-any-premission="['ALGLINFO:DELETE']" @click="onClickDelete(scope.row)" class="danger">删除</wt-text-button>
          </template>
        </el-table-column>
      </template>
    </wt-data-form>
    <!--弹层 开始-->
     <wt-page-dialog ref="alglDetailDialog" title="新增案例">
       <algl-detail :view-type="form.detailViewType" :detail-data="form.detailData"
                  @on-refresh="$refs.dataForm.requestDataList()" @on-close="$refs.alglDetailDialog.close()"></algl-detail>
    </wt-page-dialog>
    <!--弹层 结束-->
  </div>
</template>

<script>
import { mapState } from 'vuex'

export default {
  name: 'alglInfo',
  data() {
    return {
      form: {
        isFromLoading: false,
        detailViewType: 'edit',
        detailData: {},
        extFormData: {},
        formData: {
          name: '',
          createTimeRange: []
        },
        pageData: { currentPage: 1, pageSize: 20, sortName: '', sortOrder: '' },
        formResult: {}
      },
      detailForm: {
        name: '',
        file: null
      },
      fileList: []
    }
  },
  mounted() {
    this.$refs.dataForm.requestDataList()
  },
  created() {
  },
  methods: {
    fileReady(file) {
      console.log('change', file)
      this.detailForm.file = file
    },
    fileInput(data) {
      console.log('input==', data)
      this.detailForm.file = data.fileUrl
    },
    onClickConfirm() {
      console.log('firm')
      const that = this
      if (!this.detailForm.name) {
        this.$message({
          message: '请输入文档名',
          type: 'error',
          customClass: 'message-override'
        })
      } else if (!this.detailForm.file) {
        this.$message({
          message: '请输入上传文档',
          type: 'error',
          customClass: 'message-override'
        })
      }
      const params = new FormData()
      params.append('file', this.detailForm.file.raw)
      params.append('name', this.detailForm.name)
      that.$wtRequest({
        url: '/tDocumentInfo/add',
        method: 'post',
        data: params
      }).then((resp) => {
        if (resp.code === '0') {
          that.$refs.dataForm.requestDataList()
          that.$message({
            message: '新建成功',
            type: 'success',
            customClass: 'message-override'
          })
        } else {
          that.$message({
            message: '新建失败',
            type: 'error',
            customClass: 'message-override'
          })
        }
        that.$refs.wtWdglDialog.close()
      }).catch((err) => {
        console.log(err)
        that.$message({
          message: '新建失败',
          type: 'error',
          customClass: 'message-override'
        })
        that.$refs.wtWdglDialog.close()
      })
    },
    dateChange() {
      console.log('change')
    },
    onModuleCurrentChanged(nodeData, node) {
      console.log(nodeData, node)
      this.form.formData.checkedModuleCodes = node.checkedKeys
      this.$nextTick(() => {
        this.$refs.dataForm.onClickSearch()
      })
    },
    onClickDownload(rowData) {
      window.open('rowData.url', '_blank')
    },
    onClickEdit(rowData) {
      this.form.detailViewType = 'edit'
      this.form.detailData = rowData
      this.$refs.alglDetailDialog.open()
    },
    onClickView(rowData) {
      this.form.detailViewType = 'view'
      this.form.detailData = rowData
      this.$refs.alglDetailDialog.open()
    },
    onClickAdd() {
      this.form.detailViewType = 'add'
      this.$refs.alglDetailDialog.open()
    },
    onClickDelete(rowData) {
      this.$refs.dataForm.onClickDelete(rowData.id)
    },
    // 删除角色
    requestRoleDelete(rowData) {
      this.$wtRequest({
        url: '/auth/roleDelete',
        method: 'post',
        data: {
          list: [rowData.id]
        }
      }).then(res => {
        if (res.isSuccess) {
          this.$message({ type: 'success', message: '删除成功' })
          this.$refs.dataForm.requestDataList()
        }
      })
    }
  },
  computed: {
    ...mapState({})
  }
}
</script>
<style lang="scss">
  .page-container {
  }
  .message-override {
    z-index: 3000 !important;
  }
</style>
