<template>
  <div class="page-container">
    <div class="main">
    <wt-data-form class="wt-table-full" ref="dataForm" :form.sync="form" @on-request-data="resetRequestData"
                  request-list-url="/report/list"
                  request-delete-url="/"
                  request-detail-url="/">
      <template slot="search">
        <wt-search-form-item title="所属公司" v-model="form.formData.companyId" type="select" :dataList="companyOptions"/>
        <wt-search-form-item title="项目名称" v-model="form.formData.name" type="input"/>
        <wt-search-form-item title="项目业态" v-model="form.formData.scenesCode" type="select" :dataList="scenesOptions"/>
        <wt-search-form-item title="创建日期" v-model="form.formData.createTimeRange" type="daterange"/>
      </template>
      <template slot="toolbar">
        <div class="toolbar">
          <div class="total">共{{total}}个项目</div>
          <div class="right">
            <wt-button type="primary" v-has-any-premission="['BBGLINFO:ADD']" @click="onClickExport">导出</wt-button>
          </div>
        </div>
      </template>
      <template slot="table">
        <el-table-column width="50" fixed type="index" label="序号"></el-table-column>
        <el-table-column width="150" :label="tableHeader.projectCode">
          <template slot-scope="scope">
          <wt-text-button @click="onClickView(scope.row)">{{scope.row.projectCode}}</wt-text-button>
          </template>
        </el-table-column>
        <el-table-column width="150" :label="tableHeader.projectName">
          <template slot-scope="scope">
          <wt-text-button @click="onClickView(scope.row)">{{scope.row.projectName}}</wt-text-button>
          </template>
        </el-table-column>
        <el-table-column width="120" prop="scenesName" :label="tableHeader.scenesName"></el-table-column>
        <el-table-column width="120" prop="companyName" :label="tableHeader.companyName"></el-table-column>
        <template>
          <el-table-column width="120" v-for="(level, idx) in tableHeader.levelCount" :key="`level_${idx}`" :label="level">
            <template slot-scope="scope">
              {{scope.row.levelCount[idx]}}
            </template>
          </el-table-column>
        </template>
        <template>
          <el-table-column width="120" v-for="(check, idx) in tableHeader.checkCount" :key="`check_${idx}`" :label="check">
            <template slot-scope="scope">
              {{scope.row.checkCount[idx]}}
            </template>
          </el-table-column>
        </template>
        <el-table-column width="120" prop="createTime" label="创建时间" :sortable='true'></el-table-column>
        <!-- <el-table-column label="操作" fixed="right">
          <template slot-scope="scope">
            <wt-text-button v-has-any-premission="['XMGLINFO:VIEW']" @click="onClickView(scope.row)">查看</wt-text-button>
            <wt-text-button v-has-any-premission="['XMGLINFO:UPDATE']" @click="onClickViewProblem(scope.row)">问题查看</wt-text-button>
            <wt-text-button v-has-any-premission="['XMGLINFO:UPDATE']" @click="onClickEdit(scope.row)">编辑</wt-text-button>
            <wt-text-button v-has-any-premission="['XMGLINFO:UPDATE']" @click="onClickReport(scope.row)">报告管理</wt-text-button>
          </template>
        </el-table-column> -->
      </template>
    </wt-data-form>
    </div>
    <!--弹层 开始-->
    <wt-page-dialog ref="detailDialog" title="项目详情">
       <xmgl-detail :view-type="form.detailViewType" :detail-data="form.detailData"
                  @on-refresh="$refs.dataForm.requestDataList()" @on-close="$refs.detailDialog.close()"></xmgl-detail>
    </wt-page-dialog>
    <wt-page-dialog ref="editDialog" :title="editTitle">
       <xmgl-edit :view-type="form.detailViewType" :detail-data="form.detailData"
                  @on-refresh="$refs.dataForm.onClickSearch()" @on-close="$refs.editDialog.close()"></xmgl-edit>
    </wt-page-dialog>
    <wt-page-dialog ref="reportDialog" title="报告管理">
       <xmgl-report :view-type="form.detailViewType" :detail-data="form.detailData"
                  @on-refresh="$refs.dataForm.requestDataList()" @on-close="$refs.reportDialog.close()"></xmgl-report>
    </wt-page-dialog>
    <wt-page-dialog ref="problemDialog" title="问题查看">
       <xmgl-problem :view-type="form.detailViewType" :detail-data="form.detailData"
                  @on-refresh="$refs.dataForm.requestDataList()" @on-close="$refs.problemDialog.close()"></xmgl-problem>
    </wt-page-dialog>
    <!--弹层 结束-->
  </div>
</template>

<script>
import { mapState } from 'vuex'

export default {
  name: 'bbglInfo',
  data() {
    return {
      form: {
        isFromLoading: false,
        detailViewType: 'edit',
        detailData: {},
        extFormData: {},
        formData: {
          companyId: '',
          createTimeRange: [],
          name: '',
          scenesCode: ''
        },
        pageData: { currentPage: 1, pageSize: 20, sortName: '', sortOrder: '' },
        formResult: {}
      },
      filterText: '',
      defaultCheckedKeys: [],
      checkedModuleCodes: [],
      moduleTreeData: [],
      firstTime: true,
      statusOptions: [{
        value: 0,
        label: '初始化'
      }, {
        value: 1,
        label: '开启'
      }, {
        value: 2,
        label: '暂停'
      }, {
        value: 3,
        label: '完成'
      }],
      companyOptions: [],
      scenesOptions: [],
      total: 0,
      editTitle: '新增项目',
      tableHeader: {}
    }
  },
  watch: {
  },
  async mounted() {
    await this.getTableHeader()
    this.getCompanyList()
    this.getAllScenes()
    this.$refs.dataForm.onClickSearch()
  },
  created() {
  },
  methods: {
    resetRequestData(data) {
      console.log('qqq', data)
      this.total = data.total
    },
    getStatus(type) {
      let status = ''
      switch (type) {
        case 0:
          status = '初始化'
          break
        case 1:
          status = '开启'
          break
        case 2:
          status = '暂停'
          break
        case 3:
          status = '报告管理'
          break
        default:
          status = '--'
      }
      return status
    },
    getCompanyName(id) {
      return this.companyOptions.length > 0 && this.companyOptions.filter(x => x.id === id)[0].name || ''
    },
    getTableHeader() {
      this.$wtRequest({
        url: '/report/header',
        method: 'post'
      }).then(res => {
        if (res.isSuccess) {
          this.tableHeader = res.data
          console.log(res)
        }
      })
    },
    getAllScenes() {
      this.$wtRequest({
        url: '/tProjectScenes/queryAll',
        method: 'post'
      }).then(res => {
        if (res.isSuccess) {
          console.log(res)
          this.scenesOptions = res.data.list.map((x) => {
            x.value = x.code
            x.label = x.name
            return x
          })
        }
      })
    },
    getCompanyList() {
      this.$wtRequest({
        url: '/sysOrg/list',
        method: 'post',
        data: {
          formData: {
            createTimeRange: [],
            name: ''
          },
          pageData: {
            currentPage: 1,
            pageSize: 100,
            sortName: '',
            sortOrder: ''
          }
        }
      }).then(res => {
        if (res.isSuccess) {
          this.companyOptions = res.data.list.map((x) => {
            x.label = x.name
            x.value = x.id
            return x
          })
        }
      })
    },
    onClickView(rowData) {
      /* this.editTitle = '项目详情'
      this.form.detailViewType = 'view'
      this.form.detailData = rowData
      this.$refs.editDialog.open() */
    },
    onClickEdit(rowData) {
      this.editTitle = '修改项目'
      this.form.detailViewType = 'edit'
      this.form.detailData = rowData
      this.$refs.editDialog.open()
    },
    onClickViewProblem(rowData) {
      this.form.detailViewType = 'problem'
      this.form.detailData = rowData
      this.$refs.problemDialog.open()
    },
    onClickReport(rowData) {
      this.form.detailViewType = 'view'
      this.form.detailData = rowData
      this.$refs.reportDialog.open()
    },
    onClickExport() {
      const that = this
      this.$wtUtil.downloadFile({
        url: '/report/exportExcel',
        fileName: '报表.xls',
        data: {
          companyId: 0,
          createTimeRange: [],
          name: '',
          scenesCode: ''
        }
      }).then((resp) => {
      }).catch((e) => {
        that.$message({ type: 'error', message: '导出失败，请稍后再试' })
      })
    },
    onClickImport() {},
    onClickDelete(rowData) {
      this.$refs.dataForm.onClickDelete(rowData.id)
    },
    // 删除角色
    requestRoleDelete(rowData) {
      this.$wtRequest({
        url: '/auth/roleDelete',
        method: 'post',
        data: {
          list: [rowData.id]
        }
      }).then(res => {
        if (res.isSuccess) {
          this.$message({ type: 'success', message: '删除成功' })
          this.$refs.dataForm.requestDataList()
        }
      })
    }
  },
  computed: {
    ...mapState({})
  }
}
</script>
<style scoped lang="scss">
  .page-container {
    display: flex;
    .sidebar {
      width: 200px;
      margin: -20px 20px -20px 0;
      padding: 20px 20px 20px 0;
      border-right: #f6f8f9 10px solid;
      .search {
        margin-bottom: 15px;
      }
    }
    .main {
      flex: 1;
      overflow: auto;
    }
  }
  .total {
    color: #666;
    font-size: 12px;
  }
  .toolbar {
    display: flex;
    justify-content: space-between;
    .total {
      line-height: 32px;
    }
  }
</style>
