<template>
  <div>
  <wt-page>
    <div class="wt-page-container" v-loading="isFromLoading">
      <div class="page-detail">
        <wt-page-section title="基本概况">
          <div class="module-permission-content">
            <div class="form-container">
            <el-form ref="form" :model="detail" class="wt-page-form" label-width="120px" size="small">
              <el-form-item label="项目ID：" prop="id"
                :rules="[ { required: true, message: '输入内容不能为空', validator: $wtValidator.ValidatorNotBlank, trigger: 'blur', }, ]">
                <el-input v-model="detail.code" maxlength="20" placeholder="请输入" :disabled="viewType === 'view'"></el-input>
              </el-form-item>
              <el-form-item label="项目名称：" prop="name"
                :rules="[ { required: true, message: '输入内容不能为空', validator: $wtValidator.ValidatorNotBlank, trigger: 'blur', }, ]">
                <el-input v-model="detail.name" maxlength="20" placeholder="请输入" :disabled="viewType === 'view'"></el-input>
              </el-form-item>
              <el-form-item label="项目业态：" prop="scenes.code"
                            :rules="[ { required: true, message: '请选择菜单方案', validator: $wtValidator.ValidatorNotBlank, trigger: 'blur', }, ]">
                <el-select v-model="detail.scenes.code" @change="onMenuGroupChanged" :disabled="viewType === 'view'">
                  <el-option v-for="item in scenesOptions" :key="item.code" :label="item.name" :value="item.code"></el-option>
                </el-select>
              </el-form-item>
              <el-form-item class="long" label="项目地址：" prop="addr"
                :rules="[ { required: true, message: '输入内容不能为空', validator: $wtValidator.ValidatorNotBlank, trigger: 'blur', }, ]">
                <el-input v-model="detail.addr" maxlength="20" placeholder="请输入" :disabled="viewType === 'view'"></el-input>
              </el-form-item>
              <template v-if="curScenesPropertys.length > 0">
                <el-form-item :class="pro.code==='item19'?'long':''" v-for="pro in curScenesPropertys" :key="pro.id" :label="pro.name" :prop="pro.id">
                  <template v-if="pro.code==='item19'">
                    <el-input type="textarea" autosize v-model="scenesValues[pro.id]" placeholder="请输入" :disabled="viewType === 'view'"></el-input>
                  </template>
                  <template v-else>
                    <el-input v-model="scenesValues[pro.id]" maxlength="20" placeholder="请输入" :disabled="viewType === 'view'"></el-input>
                  </template>
                  <span class="unit" v-if="pro.unit">{{pro.unit}}</span>
                </el-form-item>
              </template>
            </el-form>
            </div>
          </div>
        </wt-page-section>
        <wt-page-section title="查验协调小组">
          <div class="title-right" slot="title-right" style="height:32px;">
            <wt-button type="" v-if="viewType !== 'view'" @click="assistGroupsAdd">+新增人员</wt-button>
          </div>
          <div class="module-permission-content">
            <el-table
              :data="assistGroups"
              style="width: 100%">
              <el-table-column type="index" label="序号"></el-table-column>
              <el-table-column
                label="相关责任单位">
                <template slot-scope="scope">
                  <span v-if="!scope.row.isAdd && !scope.row.isEdit">{{scope.row.responsibleCompany}}</span>
                  <el-select v-if="scope.row.isAdd || scope.row.isEdit" v-model="scope.row.companyKey" @change="onRcChanged(scope.row)">
                    <el-option v-for="item in responsibleCompanyOption" :key="item.companyKey" :label="item.responsibleCompany" :value="item.companyKey"></el-option>
                  </el-select>
                </template>
              </el-table-column>
              <el-table-column
                label="姓名">
                <template slot-scope="scope">
                  <span v-if="!scope.row.isAdd && !scope.row.isEdit">{{scope.row.responsiblePerson}}</span>
                  <el-input v-if="scope.row.isAdd || scope.row.isEdit" v-model="scope.row.responsiblePerson" placeholder="请输入内容"></el-input>
                </template>
              </el-table-column>
              <el-table-column
                label="联系方式">
                <template slot-scope="scope">
                  <span v-if="!scope.row.isAdd && !scope.row.isEdit">{{scope.row.phone}}</span>
                  <el-input v-if="scope.row.isAdd || scope.row.isEdit" v-model="scope.row.phone" placeholder="请输入内容"></el-input>
                </template>
              </el-table-column>
              <el-table-column label="操作" fixed="right" v-if="viewType !== 'view'">
                <template slot-scope="scope">
                  <wt-text-button v-if="scope.row.isAdd || scope.row.isEdit" v-has-any-premission="['XMGLINFO:VIEW']" @click="assistGroupsSave(scope.row)">保存</wt-text-button>
                  <wt-text-button v-if="!scope.row.isAdd && !scope.row.isEdit" v-has-any-premission="['XMGLINFO:UPDATE']" @click="assistGroupsEdit(scope.row)">编辑</wt-text-button>
                  <wt-text-button v-has-any-premission="['XMGLINFO:UPDATE']" @click="assistGroupsDelete(scope.row)">删除</wt-text-button>
                </template>
              </el-table-column>
            </el-table>
          </div>
        </wt-page-section>
        <wt-page-section title="查验时间">
          <div class="module-permission-content">
            <div class="text-list list-two">
              <!--<wt-search-form-item style="width: 500px; flex-basis: 500px; max-width: 500px" title="开始时间" v-model="checkStartTime" @input="timeChange" type="date"/>
              <wt-search-form-item :key="kk" style="width: 500px; flex-basis: 500px; max-width: 500px" title="结束时间" v-model="checkEndTime" type="date"/>-->
              <div class="text-item">
                <div class="title">开始时间：</div>
                <div class="content">
                  <el-date-picker
                    v-model="checkStartTime"
                    type="date"
                    :disabled="viewType === 'view'"
                    @change="checkTime('start')"
                    value-format="yyyy-MM-dd"
                    placeholder="选择日期">
                  </el-date-picker>
                </div>
              </div>
              <div class="text-item">
                <div class="title">结束时间：</div>
                <div class="content">
                  <el-date-picker
                    v-model="checkEndTime"
                    type="date"
                    :disabled="viewType === 'view'"
                    @change="checkTime('end')"
                    value-format="yyyy-MM-dd"
                    placeholder="选择日期">
                  </el-date-picker>
                </div>
              </div>
            </div>
          </div>
        </wt-page-section>
        <wt-page-section title="法律法规">
          <div class="module-permission-content">
            <el-table
              :data="detail.documents"
              style="width: 100%">
              <el-table-column width="250" type="index" label="序号"></el-table-column>
              <el-table-column label="文件名称">
                <template slot-scope="scope">
                  <wt-text-button @click="documentsDownload(scope.row)">{{scope.row.name}}</wt-text-button>
                </template>
              </el-table-column>
            </el-table>
          </div>
        </wt-page-section>
        <wt-page-section title="查验范围">
          <div class="title-right" slot="title-right" style="height:32px;" v-if="viewType !== 'view'">
            <wt-button type="" v-has-any-premission="['XMGLINFO:ADD']" @click="addBasicsInfo">+项目基础信息</wt-button>
            <wt-button type="" v-has-any-premission="['XMGLINFO:ADD']" @click="selectProject">+选择专业</wt-button>
            <wt-button type="" v-has-any-premission="['XMGLINFO:ADD']" @click="perpleReport">+人员导出</wt-button>
          </div>
          <div class="title-right" slot="title-right" style="height:32px;" v-if="viewType === 'view'">
            <wt-button type="" v-has-any-premission="['XMGLINFO:ADD']" @click="perpleReport">+人员导出</wt-button>
          </div>
          <div class="module-permission-content">
            <el-table
              :data="profestionalInfo"
              style="width: 100%">
              <el-table-column type="expand">
                <template slot-scope="props">
                  <div style="margin: -8px 20px">
                  <el-table
                    :data="props.row.workTeams"
                    style="width: 100%">
                    <el-table-column
                      prop="name"
                      label="组名">
                    </el-table-column>
                    <el-table-column
                      prop="firstAccount"
                      label="初验人">
                    </el-table-column>
                    <el-table-column
                      prop="firstAuditAccount"
                      label="初验核定人">
                    </el-table-column>
                    <el-table-column
                      prop="secondAccount"
                      label="复验人">
                    </el-table-column>
                    <el-table-column
                      prop="secondAuditAccount"
                      label="复验核定人">
                    </el-table-column>
                    <el-table-column label="核定标准">
                      <template slot-scope="scope">
                      <wt-text-button @click="onClickViewStandard(scope.row)">查看</wt-text-button>
                      </template>
                    </el-table-column>
                    <el-table-column label="楼宇">
                      <template slot-scope="scope">
                      <wt-text-button @click="onClickViewFloor(scope.row)">查看</wt-text-button>
                      </template>
                    </el-table-column>
                    <el-table-column label="园区">
                      <template slot-scope="scope">
                      <wt-text-button @click="onClickViewPark(scope.row)">查看</wt-text-button>
                      </template>
                    </el-table-column>
                    <el-table-column label="地下室">
                      <template slot-scope="scope">
                      <wt-text-button @click="onClickViewBasement(scope.row)">查看</wt-text-button>
                      </template>
                    </el-table-column>
                    <el-table-column label="操作" fixed="right" width="100" v-if="viewType !== 'view'">
                      <template slot-scope="scope">
                        <wt-text-button v-has-any-premission="['XMGLINFO:UPDATE']" @click="profestionalTeamEdit(scope.row)">编辑</wt-text-button>
                        <wt-text-button v-has-any-premission="['XMGLINFO:UPDATE']" @click="profestionalTeamDelete(scope.row)">删除</wt-text-button>
                      </template>
                    </el-table-column>
                  </el-table>
                  </div>
                </template>
              </el-table-column>
              <el-table-column type="index" width="250" label="序号"></el-table-column>
              <el-table-column
                label="专业名称"
                prop="checkTypeName">
              </el-table-column>
              <el-table-column label="操作" fixed="right" width="150" >
                <template slot-scope="scope" v-if="viewType !== 'view'">
                  <wt-text-button v-has-any-premission="['XMGLINFO:UPDATE']" @click="profestionalAddTeam(scope.row)">添加小组</wt-text-button>
                  <wt-text-button v-has-any-premission="['XMGLINFO:UPDATE']" @click="profestionalDelete(scope.row)">删除</wt-text-button>
                </template>
              </el-table-column>
            </el-table>
          </div>
        </wt-page-section>
      </div>
    </div>
    <div slot="footer">
      <wt-button type="default" @click="onClickClose">关闭</wt-button>
      <wt-button type="primary" v-if="viewType == 'add'" v-has-any-premission="['XMGLINFO:ADD']" :loading="isFromLoading" @click="onClickAdd">保存</wt-button>
      <wt-button type="primary" v-if="viewType == 'edit'" v-has-any-premission="['XMGLINFO:UPDATE']" :loading="isFromLoading" @click="onClickEdit">保存</wt-button>
    </div>
  </wt-page>
  <wt-page-dialog ref="addBasicsDialog" title="基础信息">
      <add-basics-info :view-type="detailViewType" :detail-data="detail"
                @on-refresh="requestBasicsInfo" @on-close="$refs.addBasicsDialog.close()"></add-basics-info>
  </wt-page-dialog>
  <wt-page-dialog ref="addTeamDialog" title="实施小组">
      <add-team :view-type="teamViewType" :detail-data="detail" :profestional="curProfestional"
                @on-refresh="requestBasicsInfo" @on-close="$refs.addTeamDialog.close()"></add-team>
  </wt-page-dialog>
  <wt-page-dialog ref="viewCybzDialog" title="查验标准">
      <xm-view-cybz :view-type="cybzViewType" :detail-data="detail"
                @on-refresh="requestBasicsInfo" @on-close="$refs.viewCybzDialog.close()"></xm-view-cybz>
  </wt-page-dialog>
  <!--弹层 开始-->
  <wt-dialog ref="addZhuanyeDialog" title="选择专业">
    <div class="wt-org-role-wrapper">
      <el-form :model="zhuanyeForm" ref="zhuanyeForm" class="wt-page-form" label-width="120px" size="small">
        <el-form-item label="专业列表" prop="ids"
          :rules="[ { required: true, message: '请选择专业', validator: $wtValidator.ValidatorNotBlank, trigger: 'blur', }, ]">
          <el-checkbox-group v-model="zhuanyeForm.ids">
            <el-checkbox v-for="zy in zhuanyeList" :key="zy.id" :label="zy.id">{{zy.name}}</el-checkbox>
          </el-checkbox-group>
        </el-form-item>
      </el-form>
    </div>
    <div class="wt-toolbar wt-margin-t wt-flex-end">
      <wt-button type="default" @click="$refs.addZhuanyeDialog.close()">取消</wt-button>
      <wt-button type="primary" @click="zhuanyeConfirm('zhuanyeForm')">保存</wt-button>
    </div>
  </wt-dialog>
  <!--弹层 结束-->
  <!--弹层 开始-->
  <wt-dialog ref="viewRangsDialog" :title="rangeTitle">
    <div class="wt-org-role-wrapper">
      <div class="range-list">
        <template v-if="rangeList.length > 0">
          <div class="range-item" v-for="(rang, idx) in rangeList" :key="idx">
          {{rang}}<span v-if="(idx + 1) < rangeList.length">、</span>
          </div>
        </template>
        <div v-else class="range-item no-data">暂无</div>
      </div>
    </div>
    <div class="wt-toolbar wt-margin-t wt-flex-end">
      <wt-button type="default" @click="$refs.viewRangsDialog.close()">确定</wt-button>
    </div>
  </wt-dialog>
  <!--弹层 结束-->
  </div>
</template>

<script>
import { mapState } from 'vuex'

export default {
  name: 'xmglEdit',
  isAutoComponent: true,
  props: ['viewType', 'detailData'],
  data() {
    return {
      isButtonLoading: false,
      isFromLoading: false,
      scenesOptions: [],
      scenesValues: {},
      curScenesPropertys: [],
      detail: {
        id: 0,
        code: '',
        name: '',
        addr: '',
        scenes: {
          code: 'ZhuZhai',
          name: '住宅',
          scenesPropertys: []
        },
        assistGroups: [],
        checkStartTime: '',
        checkEndTime: '',
        documents: [],
        profestionalInfo: []
      },
      profestionalInfo: [],
      responsibleCompanyOption: [
        {
          companyKey: 'rongtong',
          responsibleCompany: '融通物业'
        },
        {
          companyKey: 'original',
          responsibleCompany: '原物业方'
        },
        {
          companyKey: 'check',
          responsibleCompany: '查验方'
        }
      ],
      assistGroups: [],
      checkStartTime: '',
      checkEndTime: '',
      kk: 1,
      detailViewType: 'add',
      teamViewType: 'add',
      cybzViewType: 'add',
      curProfestional: null,
      rangeTitle: '您所负责的楼宇为：',
      rangeList: [],
      detailForm: {
        menuGroupId: '',
        orderNum: 0,
        isPublish: 1
      },
      zhuanyeForm: {
        ids: []
      },
      zhuanyeList: []
    }
  },
  async mounted() {
    if (this.viewType === 'add') {
      this.queryXmId()
    }
    await this.requestAllScenes()
    this.getDocumentList()
    this.getZhuanyeList()
    if (this.viewType !== 'add') {
      await this.requestDetail()
      // this.getScenesProperty(this.detail.scenes.code)
    } else {
      this.initAddData()
      this.getScenesProperty(this.detail.scenes.code)
    }
  },
  created() {},
  methods: {
    profestionalTeamEdit(row) {
      console.log(row)
      this.teamViewType = 'edit'
      this.curProfestional = row
      this.$refs.addTeamDialog.open()
    },
    profestionalTeamDelete(row) {
      console.log(row)
      const that = this
      this.$confirm('确定删除该小组吗?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        that.$wtRequest({
          url: '/tProjectProfestionalWorkTeam/delete',
          method: 'post',
          data: {
            list: [row.id]
          }
        }).then((resp) => {
          if (resp.code === '0') {
            that.$message.success('删除成功')
            that.$nextTick(() => {
              that.getProfestionalInfo()
            })
          } else {
            that.$message.error('删除失败')
          }
        }).catch(() => {
          that.$message.error('删除失败')
        })
      }).catch(() => {
        this.$message({
          type: 'info',
          message: '已取消删除'
        })
      })
    },
    profestionalAddTeam(row) {
      console.log(row)
      this.teamViewType = 'add'
      this.curProfestional = row
      this.$refs.addTeamDialog.open()
    },
    profestionalDelete(row) {
      console.log(row)
      const that = this
      this.$confirm('确定删除该查验范围吗?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        that.$wtRequest({
          url: '/tProjectProfestional/delete',
          method: 'post',
          data: {
            id: row.id
          }
        }).then((resp) => {
          if (resp.code === '0') {
            that.$message.success('删除成功')
            that.$nextTick(() => {
              that.getProfestionalInfo()
            })
          } else {
            that.$message.error('删除失败')
          }
        }).catch(() => {
          that.$message.error('删除失败')
        })
      }).catch(() => {
        this.$message({
          type: 'info',
          message: '已取消删除'
        })
      })
    },
    zhuanyeConfirm(formName) {
      const that = this
      that.$refs[formName].validate((valid) => {
        if (valid) {
          console.log('ids', this.zhuanyeForm.ids)
          const sendData = this.zhuanyeForm.ids.map((x) => {
            const nx = {
              checkTypeId: x,
              projectId: that.detail.id
            }
            return nx
          })
          that.$wtRequest({
            url: '/tProjectProfestional/add',
            method: 'post',
            data: sendData
          }).then((resp) => {
            if (resp.code === '0') {
              that.$message.success('选择成功')
              that.$nextTick(() => {
                that.getProfestionalInfo()
              })
            } else {
              that.$message.success('选择失败')
            }
            that.$refs.addZhuanyeDialog.close()
          }).catch(() => {
            that.$message.success('选择失败')
            that.$refs.addZhuanyeDialog.close()
          })
        } else {
          console.log('error submit!!')
          return false
        }
      })
    },
    requestBasicsInfo() {
      this.getProfestionalInfo()
    },
    queryXmId() {
      const that = this
      that.$wtRequest({
        url: '/tProjectInfo/init',
        method: 'post',
        data: {
          id: this.orgId
        }
      }).then((resp) => {
        if (resp.code === '0') {
          that.detail.id = resp.data
        }
      }).catch((e) => {
        console.log('requestDetail e', e)
      })
    },
    addBasicsInfo() {
      this.$refs.addBasicsDialog.open()
    },
    selectProject() {
      const checkList = this.profestionalInfo.map(x => x.checkTypeId)
      this.zhuanyeForm.ids = checkList
      this.$refs.addZhuanyeDialog.open()
    },
    perpleReport() {
      const that = this
      const el = document.createElement('a')
      el.style.display = 'none'
      el.setAttribute('target', '_balank')
      el.setAttribute('download', '项目人员.xlsx')
      console.log(`/tProjectProfestionalWorkTeam/exportExcel/${that.detail.id}`)
      el.href = `/tProjectProfestionalWorkTeam/exportExcel/${that.detail.id}`
      document.body.appendChild(el)
      el.click()
      document.body.removeChild(el)
      // this.$wtUtil.downloadFile({
      //   url: '/report/exportExcel',
      //   fileName: '报表.xls',
      //   data: {
      //     companyId: 0,
      //     createTimeRange: [],
      //     name: '',
      //     scenesCode: ''
      //   }
      // }).then((resp) => {
      // }).catch((e) => {
      //   that.$message({ type: 'error', message: '导出失败，请稍后再试' })
      // })
    },
    checkTime(type) {
      if (type === 'start' && (new Date(this.checkStartTime).getTime() > new Date(this.checkEndTime).getTime())) {
        this.checkEndTime = this.checkStartTime
      } else if (type === 'end' && (new Date(this.checkStartTime).getTime() > new Date(this.checkEndTime).getTime())) {
        this.checkStartTime = this.checkEndTime
      }
    },
    timeChange() {
      console.log(this.checkStartTime, this.checkEndTime)
      this.checkEndTime = '2022-06-07'
      this.kk += 1
    },
    onRcChanged(row) {
      row.responsibleCompany = this.responsibleCompanyOption.filter(x => x.companyKey === row.companyKey)[0].responsibleCompany
    },
    assistGroupsAdd() {
      if (this.assistGroups.filter(x => x.isAdd || x.isEdit).length > 0) {
        this.$message.error('请先保存')
        return false
      }
      const newData = {
        id: 0,
        projectId: this.detail.id,
        responsibleCompany: '融通物业',
        companyKey: 'rongtong',
        responsiblePerson: '',
        phone: '',
        addNode: true,
        isAdd: true,
        isEdit: false
      }
      this.assistGroups.unshift(newData)
    },
    assistGroupsSave(row) {
      if (!row.responsiblePerson) {
        this.$message.error('请输入姓名')
        return false
      }
      row.isAdd = false
      row.isEdit = false
    },
    assistGroupsEdit(row) {
      console.log('row', row)
      if (this.assistGroups.filter(x => x.isAdd || x.isEdit).length > 0) {
        this.$message.error('请先保存')
        return false
      }
      row.isEdit = true
      this.assistGroups = JSON.parse(JSON.stringify(this.assistGroups))
      // this.$set(row, 'isEdit', true)
      // row.isEdit = true
      console.log('row2', row)
    },
    assistGroupsDelete(row) {
      this.$confirm('确定删除该人员?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        this.$message({
          type: 'success',
          message: '删除成功!'
        })
        this.assistGroups = this.assistGroups.filter(x => x.id !== row.id)
      }).catch(() => {
        this.$message({
          type: 'info',
          message: '已取消删除'
        })
      })
    },
    initAddData() {},
    onClickViewStandard(row) {
      console.log(row)
      this.$refs.viewCybzDialog.open()
    },
    onClickViewFloor(row) {
      console.log(row)
      this.rangeTitle = '您所负责的楼宇为：'
      let rangeList = []
      console.log('666', row.louYu)
      console.log('777', row.louYu.length)
      if (row.louYu && row.louYu.length > 0) {
        console.log('888', row.louYu)
        rangeList = row.louYu.map(x => x.baseTypeName)
      }
      console.log('999', rangeList)
      this.rangeList = rangeList
      this.$refs.viewRangsDialog.open()
    },
    onClickViewPark(row) {
      console.log(row)
      this.rangeTitle = '您所负责的园区为：'
      let rangeList = []
      if (row.yuanQu && row.yuanQu.length > 0) {
        rangeList = row.yuanQu.map(x => x.baseTypeName)
      }
      this.rangeList = rangeList
      this.$refs.viewRangsDialog.open()
    },
    onClickViewBasement(row) {
      console.log(row)
      this.rangeTitle = '您所负责的地下室为：'
      let rangeList = []
      if (row.diXiaShi && row.diXiaShi.length > 0) {
        rangeList = row.diXiaShi.map(x => x.baseTypeName)
      }
      this.rangeList = rangeList
      this.$refs.viewRangsDialog.open()
    },
    documentsDownload(row) {
      window.open(row.url, '_blank')
    },
    onClickClose() {
      this.$emit('on-close')
    },
    onClickAdd() {
      const that = this
      this.$wtUtil.validateConfirm(this, '是否保存?').then(() => {
        // that.requestAdd()
        const sendData = {
          addr: that.detail.addr,
          checkEndTime: that.checkEndTime,
          checkStartTime: that.checkStartTime,
          code: that.detail.code,
          companyId: that.orgId,
          id: that.detail.id,
          name: that.detail.name,
          assistGroups: [],
          props: [],
          scenesCode: that.detail.scenes.code
        }
        const props = []
        that.curScenesPropertys.forEach((x) => {
          const np = {
            propertyId: x.id,
            scenesCode: x.scenesCode,
            propertyValue: that.scenesValues[x.id]
          }
          props.push(np)
        })
        sendData.props = props
        const assistGroups = []
        that.assistGroups.forEach((x) => {
          const nItem = {
            phone: x.phone,
            projectId: x.projectId,
            responsibleCompany: x.responsibleCompany,
            responsiblePerson: x.responsiblePerson
          }
          assistGroups.push(nItem)
        })
        sendData.assistGroups = assistGroups
        that.$wtRequest({
          url: '/tProjectInfo/add',
          method: 'post',
          data: sendData
        }).then((resp) => {
          if (resp.code === '0') {
            that.$message.success('项目新增成功')
            that.$emit('on-refresh')
            that.$emit('on-close')
          } else {
            that.$message.error('项目新增失败')
          }
        }).catch((e) => {
          console.log('requestDetail e', e)
          that.$message.error('项目新增失败')
        })
      })
    },
    onClickEdit() {
      const that = this
      this.$wtUtil.validateConfirm(this, '是否保存?').then(() => {
        const sendData = {
          addr: that.detail.addr,
          checkEndTime: that.checkEndTime,
          checkStartTime: that.checkStartTime,
          code: that.detail.code,
          companyId: that.orgId,
          id: that.detail.id,
          name: that.detail.name,
          assistGroups: [],
          props: []
        }
        const props = []
        if (that.detail.scenes.code === that.detail.scenesCode) {
          console.log('%%%-1', that.detail.scenes.scenesPropertys, that.scenesValues)
          that.detail.scenes.scenesPropertys.forEach((x) => {
            const np = {
              id: x.id,
              propertyId: x.propertyId,
              scenesCode: x.scenesCode,
              propertyValue: that.scenesValues[x.id]
            }
            props.push(np)
          })
        } else {
          console.log('%%%-2', that.detail.scenes.scenesPropertys)
          that.curScenesPropertys.forEach((x) => {
            const np = {
              propertyId: x.id,
              scenesCode: x.scenesCode,
              propertyValue: that.scenesValues[x.id]
            }
            props.push(np)
          })
        }
        sendData.props = props
        const assistGroups = []
        that.assistGroups.forEach((x) => {
          console.log('ag', x)
          const nItem = {
            phone: x.phone,
            projectId: x.projectId,
            responsibleCompany: x.responsibleCompany,
            responsiblePerson: x.responsiblePerson
          }
          if (x.id !== 0) {
            nItem.id = x.id
          }
          assistGroups.push(nItem)
        })
        sendData.assistGroups = assistGroups
        sendData.scenesCode = that.detail.scenes.code
        that.$wtRequest({
          url: '/tProjectInfo/update',
          method: 'post',
          data: sendData
        }).then((resp) => {
          if (resp.code === '0') {
            that.$message.success('项目修改成功')
            that.$emit('on-refresh')
            that.$emit('on-close')
          } else {
            that.$message.error('项目修改失败')
          }
        }).catch((e) => {
          console.log('requestDetail e', e)
          that.$message.error('项目修改失败')
        })
      })
    },
    initDetailData(data) {
      this.detail = data
      this.curScenesPropertys = this.detail.scenes.scenesPropertys
      const scenesValues = {}
      this.curScenesPropertys.forEach((x) => {
        scenesValues[x.propertyId] = x.value
        scenesValues[x.id] = x.value
      })
      this.scenesValues = scenesValues
      this.assistGroups = this.detail.assistGroups.map((x) => {
        x.companyKey = x.responsibleCompany === '查验方' ? 'check' : x.responsibleCompany === '原物业方' ? 'original' : 'rongtong'
        x.isAdd = false
        x.isEdit = false
        return x
      })
      this.checkStartTime = this.detail.checkStartTime
      this.checkEndTime = this.detail.checkEndTime
      this.getProfestionalInfo()
    },
    onMenuGroupChanged(val) {
      console.log('onMenuGroupChanged', val)
      this.getScenesProperty(val)
    },
    getProfestionalInfo() {
      const that = this
      that.$wtRequest({
        url: '/tProjectProfestional/queryByProjectId',
        method: 'post',
        data: {
          id: this.detail.id
        }
      }).then((resp) => {
        if (resp.code === '0') {
          this.profestionalInfo = JSON.parse(JSON.stringify(resp.data.list))
        }
      }).catch((e) => {
        console.log('requestDetail e', e)
      })
    },
    getScenesProperty(val) {
      const that = this
      that.$wtRequest({
        url: '/tProjectScenesProperty/queryListByCode',
        method: 'post',
        data: {
          code: val
        }
      }).then((resp) => {
        if (resp.data.list.length > 0) {
          this.curScenesPropertys = resp.data.list
          const scenesValues = {}
          this.curScenesPropertys.forEach((x) => {
            scenesValues[x.id] = ''
          })
          console.log(scenesValues, this.scenesValues)
          this.scenesValues = Object.assign({}, scenesValues, this.scenesValues)
        }
      }).catch((e) => {
        console.log('requestDetail e', e)
      })
    },
    requestAllScenes() {
      const that = this
      that.isFromLoading = true
      that.$wtRequest({
        url: '/tProjectScenes/queryAll',
        method: 'post',
        data: {}
      }).then((resp) => {
        if (resp.data.list.length > 0) {
          this.scenesOptions = resp.data.list
          /* this.scenesOptions.forEach((x) => {
            x.scenesPropertys.forEach((y) => {
              this.scenesValues[y.id] = y.value || ''
            })
          }) */
        }
        if (this.viewType === 'add') {
          that.isFromLoading = false
        }
      }).catch((e) => {
        console.log('requestDetail e', e)
        if (this.viewType === 'add') {
          that.isFromLoading = false
        }
      })
    },
    requestDetail() {
      const that = this
      that.$wtRequest({
        url: '/tProjectInfo/detail',
        method: 'post',
        data: { id: that.detailData.id }
      }).then((resp) => {
        console.log('requestDetail resp', resp)
        // that.detail = resp.data || {}
        this.initDetailData(resp.data)
        that.isFromLoading = false
      }).catch((e) => {
        console.log('requestDetail e', e)
        that.isFromLoading = false
      })
    },
    getZhuanyeList() {
      const that = this
      this.$wtRequest({
        url: '/tCheckTypeInfo/queryFristLevelList',
        method: 'post'
      }).then((resp) => {
        that.zhuanyeList = resp.data.list || []
      }).catch((e) => {
        console.log('requestDetail e', e)
      })
    },
    getDocumentList() {
      const that = this
      this.$wtRequest({
        url: '/tDocumentInfo/queryByCompany',
        method: 'post',
        data: { id: this.orgId }
      }).then((resp) => {
        console.log('getDocumentList resp', resp)
        that.detail.documents = resp.data.list || []
      }).catch((e) => {
        console.log('requestDetail e', e)
      })
    }
  },
  computed: {
    isReadMode() {
      return this.viewType === 'view'
    },
    /* curScenesPropertys() {
      return this.scenesOptions.filter(x => x.code === this.detail.scenes.code).length > 0 ? this.scenesOptions.filter(x => x.code === this.detail.scenes.code)[0].scenesPropertys : []
    }, */
    ...mapState({
      orgId: state => state.auth.orgId
    })
  }
}
</script>
<style lang="scss" scoped>
  .module-permission-content {display: flex;width: 100%;margin-top: 16px; color: #666; flex-wrap: wrap;}
  .module-permission-content .left-content {width: 250px;padding: 0 24px;}
  .module-permission-content .right-content {flex: 1;}
  .module-menu-content {display: flex;width: 100%;}
  .module-menu-content .left-content {width: 300px;padding: 0 24px;}
  .module-menu-content .right-content {flex: 1;}
  .form-container {
    width: 100%;
    .wt-page-form {
      max-width: 2000px;
      display: flex;
      flex-wrap: wrap;
      .el-form-item {
        max-width: 400px;
        min-width: 350px;
        position: relative;
        &.long {
          min-width: 1050px;
          max-width: 1200px;
        }
        .unit {
          position: absolute;
          right: 0;
          color: #999;
        }
      }
    }
  }
  .wt-page-container {
    padding: 0;
  }
  .text-list {
    display: flex;
    flex-wrap: wrap;
    width: 100%;
    .text-item {
      flex-basis: 350px;
      width: 350px;
      display: flex;
      line-height: 32px;
      padding: 5px 0;
      .title {
        width: 100px;
        text-align: right;
      }
      .content {
        flex: 1;
      }
      &.three-item {
        flex-basis: 1050px;
        width: 1050px;
      }
    }
    &.list-two {
      .text-item {
        flex-basis: auto;
        width: auto;
        flex: 1;
      }
    }
  }
  .range-list {
    display: flex;
    flex-wrap: wrap;
    padding: 0 10px;
    .range-item {
      margin: 0 5px;
      line-height: 36px;
      font-size: 16px;
    }
    .no-data {
      color: #999;
    }
  }
  .el-checkbox {margin-right: 0; min-width: 170px;}
</style>
