// import IconTest from '@/assets/images/icon_test.png'
const wtConst = {
  ISPUBLISH_ENUM_CONTENT: {
    '0': { text: '禁用', color: '#333333', icon: 'el-icon-warning', iconColor: '#DE504E' },
    '1': { text: '启用', color: '#333333', icon: 'el-icon-success', iconColor: '#50D4AB' }
  },
  ISPUBLISH_ENUM_OPTIONS: [{ value: '', label: '全部' },
    { value: 1, label: '启用' },
    { value: 0, label: '禁用' }
  ],
  MENU_ISHIDDEN_ENUM_CONTENT: {
    '0': { text: '显示', color: '#50D4AB', weight: 'bold' },
    '1': { text: '隐藏', color: '#999999' }
  }
}
export default wtConst
