<template>
  <div class="page-container">
    <wt-data-form class="wt-table-full" ref="dataForm" :form.sync="form"
                  request-list-url="/auth/roleList"
                  request-delete-url="/auth/roleDelete"
                  request-detail-url="/auth/roleDetail">
      <template slot="search">
        <wt-search-form-item title="角色名称" v-model="form.formData.roleName" type="input"/>
        <wt-search-form-item title="账号状态" v-model="form.formData.isPublish" type="select" :dataList="$wtConst.ISPUBLISH_ENUM_OPTIONS"/>
      </template>
      <template slot="toolbar">
        <wt-button type="primary" v-has-any-premission="['SYSROLE:ADD']" @click="onClickAdd">新建角色</wt-button>
      </template>
      <template slot="table">
        <el-table-column type="index" label="序号"></el-table-column>
        <el-table-column prop="roleName" label="角色名称"></el-table-column>
        <el-table-column prop="summary" label="描述"></el-table-column>
        <el-table-column prop="isDefault" label="默认角色">
          <template slot-scope="scope"><span>{{scope.row.isDefault|FILTER_YesOrNo}}</span></template>
        </el-table-column>
        <el-table-column prop="isPublish" label="状态">
          <template slot-scope="scope"><wt-enum-content v-bind="$wtConst.ISPUBLISH_ENUM_CONTENT[scope.row.isPublish]"></wt-enum-content></template>
        </el-table-column>
        <el-table-column prop="username" label="创建人"></el-table-column>
        <el-table-column prop="updateTime" label="创建时间" :sortable='true'></el-table-column>
        <el-table-column label="操作" fixed="right">
          <template slot-scope="scope">
            <wt-text-button v-has-any-premission="['SYSROLE:VIEW']" @click="onClickView(scope.row)">查看</wt-text-button>
            <wt-text-button v-has-any-premission="['SYSROLE:UPDATE']" @click="onClickEdit(scope.row)">编辑</wt-text-button>
            <wt-text-button v-if="scope.row.isDefault == 0" v-has-any-premission="['SYSROLE:DELETE']" @click="onClickDelete(scope.row)" class="danger">删除</wt-text-button>
          </template>
        </el-table-column>
      </template>
    </wt-data-form>
    <!--弹层 开始-->
     <wt-page-dialog ref="detailDialog" title="角色详情">
       <role-detail :view-type="form.detailViewType" :detail-data="form.detailData"
                  @on-refresh="$refs.dataForm.requestDataList()" @on-close="$refs.detailDialog.close()"></role-detail>
    </wt-page-dialog>
    <!--弹层 结束-->
  </div>
</template>

<script>
import { mapState } from 'vuex'

export default {
  name: 'roleList',
  data() {
    return {
      form: {
        isFromLoading: false,
        detailViewType: 'edit',
        detailData: {},
        extFormData: {},
        formData: {
          name: '',
          updateTimeRange: []
        },
        pageData: { currentPage: 1, pageSize: 20, sortName: '', sortOrder: '' },
        formResult: {}
      }
    }
  },
  mounted() {
    this.$refs.dataForm.requestDataList()
  },
  created() {
  },
  methods: {
    onClickView(rowData) {
      this.form.detailViewType = 'view'
      this.form.detailData = rowData
      this.$refs.detailDialog.open()
    },
    onClickEdit(rowData) {
      this.form.detailViewType = 'edit'
      this.form.detailData = rowData
      this.$refs.detailDialog.open()
    },
    onClickAdd() {
      this.form.detailViewType = 'add'
      this.$refs.detailDialog.open()
    },
    onClickDelete(rowData) {
      this.$refs.dataForm.onClickDelete(rowData.id)
    },
    // 删除角色
    requestRoleDelete(rowData) {
      this.$wtRequest({
        url: '/auth/roleDelete',
        method: 'post',
        data: {
          list: [rowData.id]
        }
      }).then(res => {
        if (res.isSuccess) {
          this.$message({ type: 'success', message: '删除成功' })
          this.$refs.dataForm.requestDataList()
        }
      })
    }
  },
  computed: {
    ...mapState({})
  }
}
</script>
