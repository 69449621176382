<template>
  <div class="page-container">
    <div class="main" v-loading="loading">
    <wt-data-form class="wt-table-full" ref="dataForm" :form.sync="form" @on-request-data="resetRequestData"
                  request-list-url="/tProjectInfo/list"
                  request-delete-url="/tProjectInfo/delete"
                  request-detail-url="/tProjectInfo/detail">
      <template slot="search">
        <wt-search-form-item title="项目ID" v-model="form.formData.code" type="input"/>
        <wt-search-form-item title="项目名称" v-model="form.formData.name" type="input"/>
        <wt-search-form-item v-if="orgId === '1'" title="所属公司" v-model="form.formData.companyIds" type="multi-select" :dataList="companyOptions"/>
        <wt-search-form-item title="项目状态" v-model="form.formData.status" type="select" :dataList="statusOptions"/>
        <wt-search-form-item title="项目业态" v-model="form.formData.scenesCode" type="select" :dataList="scenesOptions"/>
        <wt-search-form-item title="创建日期" v-model="form.formData.createTimeRange" type="daterange"/>
      </template>
      <template slot="toolbar">
        <div class="toolbar">
          <div class="total">共{{total}}个项目</div>
          <div class="right">
            <wt-button type="primary" v-has-any-premission="['XMGLINFO:ADD']" @click="onClickAdd">新增项目</wt-button>
            <!-- <wt-button type="primary" v-has-any-premission="['XMGLINFO:ADD']" @click="onClickImport">项目导入</wt-button>-->
            <div class="upload-button">
              <wt-button type="primary" v-has-any-premission="['XMGLINFO:ADD']" @click="onBaseImport">项目导入</wt-button>
              <el-upload
                style="display: "
                ref="importFile2"
                action="/tProjectInfo/importExcel"
                :auto-upload="false"
                :on-change="fileReady"
                :file-list="fileList">
                <el-button size="small" type="primary" ref="importFile">项目导入</el-button>
              </el-upload>
              </div>
          </div>
        </div>
      </template>
      <template slot="table">
        <el-table-column type="index" label="序号"></el-table-column>
        <el-table-column label="项目ID">
          <template slot-scope="scope">
          <wt-text-button @click="onClickView(scope.row)">{{scope.row.code}}</wt-text-button>
          </template>
        </el-table-column>
        <el-table-column label="项目名称">
          <template slot-scope="scope">
          <wt-text-button @click="onClickView(scope.row)">{{scope.row.name}}</wt-text-button>
          </template>
        </el-table-column>
        <el-table-column prop="scenes.name" label="项目业态"></el-table-column>
        <el-table-column prop="addr" label="项目地址"></el-table-column>
        <el-table-column prop="statusCn" label="项目状态"></el-table-column>
        <!-- <el-table-column label="项目状态">
          <template slot-scope="scope">
            <span>{{getStatus(scope.row.status)}}</span>
          </template>
        </el-table-column> -->
        <el-table-column prop="createUserName" label="操作人"></el-table-column>
        <el-table-column prop="createTime" label="创建时间" :sortable='true'></el-table-column>
        <el-table-column label="操作" fixed="right">
          <template slot-scope="scope">
            <wt-text-button v-has-any-premission="['XMGLINFO:VIEW']" @click="onClickView(scope.row)">查看</wt-text-button>
            <wt-text-button v-has-any-premission="['XMGLINFO:UPDATE']" @click="onClickViewProblem(scope.row)">问题查看</wt-text-button>
            <wt-text-button v-if="!scope.row.canDownFinishWordReport" v-has-any-premission="['XMGLINFO:UPDATE']" @click="onClickEdit(scope.row)">编辑</wt-text-button>
            <wt-text-button v-if="scope.row.canDownPreWordReport" @click="onClickReport(scope.row, 1)">查验报告</wt-text-button>
            <wt-text-button v-if="scope.row.canDownFinishWordReport" @click="onClickReport(scope.row, 2)">整改销项报告</wt-text-button>
          </template>
        </el-table-column>
      </template>
    </wt-data-form>
    </div>
    <!--弹层 开始-->
    <wt-page-dialog ref="detailDialog" title="项目详情">
       <xmgl-detail :view-type="form.detailViewType" :detail-data="form.detailData"
                  @on-refresh="$refs.dataForm.requestDataList()" @on-close="$refs.detailDialog.close()"></xmgl-detail>
    </wt-page-dialog>
    <wt-page-dialog ref="editDialog" :title="editTitle">
       <xmgl-edit :view-type="form.detailViewType" :detail-data="form.detailData"
                  @on-refresh="$refs.dataForm.onClickSearch()" @on-close="$refs.editDialog.close()"></xmgl-edit>
    </wt-page-dialog>
    <wt-page-dialog ref="reportDialog" title="报告管理">
       <xmgl-report :view-type="form.detailViewType" :detail-data="form.detailData"
                  @on-refresh="$refs.dataForm.requestDataList()" @on-close="$refs.reportDialog.close()"></xmgl-report>
    </wt-page-dialog>
    <wt-page-dialog ref="problemDialog" title="问题查看">
       <xmgl-problem :view-type="form.detailViewType" :detail-data="form.detailData"
                  @on-refresh="$refs.dataForm.requestDataList()" @on-close="$refs.problemDialog.close()"></xmgl-problem>
    </wt-page-dialog>
    <!--弹层 结束-->
  </div>
</template>

<script>
import { mapState } from 'vuex'

export default {
  name: 'xmglInfo',
  data() {
    return {
      loading: false,
      form: {
        isFromLoading: false,
        detailViewType: 'edit',
        detailData: {},
        extFormData: {},
        formData: {
          name: '',
          createTimeRange: [],
          companyIds: [],
          code: '',
          scenesCode: '',
          status: ''
        },
        pageData: { currentPage: 1, pageSize: 20, sortName: '', sortOrder: '' },
        formResult: {}
      },
      filterText: '',
      defaultCheckedKeys: [],
      checkedModuleCodes: [],
      moduleTreeData: [],
      firstTime: true,
      statusOptions: [{
        value: 0,
        label: '草稿'
      }, {
        value: 1,
        label: '初验实施'
      }, {
        value: 2,
        label: '初验结束'
      }, {
        value: 3,
        label: '复验结束'
      }, {
        value: 9,
        label: '暂停'
      }],
      companyOptions: [],
      scenesOptions: [],
      total: 0,
      editTitle: '新增项目',
      file: null,
      fileList: []
    }
  },
  watch: {
  },
  async mounted() {
    await this.getCompanyList()
    this.getAllScenes()
    this.$refs.dataForm.requestDataList()
  },
  created() {
  },
  methods: {
    fileReady(file) {
      console.log('change', file)
      const that = this
      this.file = file
      const params = new FormData()
      params.append('excelFile', this.file.raw)
      params.append('companyId', this.orgId)
      that.$wtRequest({
        url: '/tProjectInfo/importExcel',
        method: 'post',
        data: params
      }).then((resp) => {
        if (resp.code === '0') {
          that.$message({
            message: '新建成功',
            type: 'success'
          })
          that.$nextTick(() => {
            that.$refs.dataForm.onClickSearch()
          })
        } else {
          that.$message({
            message: '新建失败',
            type: 'error'
          })
        }
        that.file = null
      }).catch((err) => {
        console.log(err)
        that.$message({
          message: '新建失败',
          type: 'error'
        })
        that.file = null
      })
    },
    resetRequestData(data) {
      console.log('qqq', data)
      this.total = data.total
    },
    getStatus(type) {
      let status = ''
      switch (type) {
        case 0:
          status = '初始化'
          break
        case 1:
          status = '开启'
          break
        case 2:
          status = '暂停'
          break
        case 3:
          status = '报告管理'
          break
        default:
          status = '--'
      }
      return status
    },
    getCompanyName(id) {
      return this.companyOptions.length > 0 && this.companyOptions.filter(x => x.id === id)[0].name || ''
    },
    getAllScenes() {
      this.$wtRequest({
        url: '/tProjectScenes/queryAll',
        method: 'post'
      }).then(res => {
        if (res.isSuccess) {
          console.log(res)
          this.scenesOptions = res.data.list.map((x) => {
            x.value = x.code
            x.label = x.name
            return x
          })
        }
      })
    },
    getCompanyList() {
      this.$wtRequest({
        url: '/sysOrg/list',
        method: 'post',
        data: {
          formData: {
            createTimeRange: [],
            name: ''
          },
          pageData: {
            currentPage: 1,
            pageSize: 100,
            sortName: '',
            sortOrder: ''
          }
        }
      }).then(res => {
        if (res.isSuccess) {
          this.companyOptions = res.data.list.map((x) => {
            x.label = x.name
            x.value = x.id
            return x
          })
        }
      })
    },
    onClickView(rowData) {
      this.editTitle = '项目详情'
      this.form.detailViewType = 'view'
      this.form.detailData = rowData
      this.$refs.editDialog.open()
    },
    onClickEdit(rowData) {
      this.editTitle = '修改项目'
      this.form.detailViewType = 'edit'
      this.form.detailData = rowData
      this.$refs.editDialog.open()
    },
    onClickViewProblem(rowData) {
      this.form.detailViewType = 'problem'
      this.form.detailData = rowData
      this.$refs.problemDialog.open()
    },
    onClickReport(rowData, type) {
      // this.form.detailViewType = 'view'
      // this.form.detailData = rowData
      // this.$refs.reportDialog.open()
      const url = type === 1 ? `/wordReport/exportPreWord/${rowData.id}` : `/wordReport/exportAuditWord/${rowData.id}`
      // window.open(url, '_blank')
      const name = type === 1 ? '查验报告.zip' : '整改销项报告.zip'
      const that = this
      that.loading = true
      this.$wtUtil.downloadFile({
        url,
        fileName: name
      }).then((resp) => {
        that.loading = false
      }).catch((e) => {
        that.loading = false
        that.$message({ type: 'error', message: '下载失败，请稍后再试' })
      })
    },
    onClickAdd() {
      this.editTitle = '新增项目'
      this.form.detailViewType = 'add'
      this.$refs.editDialog.open()
    },
    onClickImport() {},
    onClickDelete(rowData) {
      this.$refs.dataForm.onClickDelete(rowData.id)
    },
    // 删除角色
    requestRoleDelete(rowData) {
      this.$wtRequest({
        url: '/auth/roleDelete',
        method: 'post',
        data: {
          list: [rowData.id]
        }
      }).then(res => {
        if (res.isSuccess) {
          this.$message({ type: 'success', message: '删除成功' })
          this.$refs.dataForm.requestDataList()
        }
      })
    },
    onBaseImport() {
      this.file = null
      this.fileList = []
      this.$refs.importFile.click()
    }
  },
  computed: {
    ...mapState({
      orgId: state => state.auth.orgId
    })
  }
}
</script>
<style scoped lang="scss">
  .page-container {
    display: flex;
    .sidebar {
      width: 200px;
      margin: -20px 20px -20px 0;
      padding: 20px 20px 20px 0;
      border-right: #f6f8f9 10px solid;
      .search {
        margin-bottom: 15px;
      }
    }
    .main {
      flex: 1;
    }
  }
  .total {
    color: #666;
    font-size: 12px;
  }
  .toolbar {
    display: flex;
    justify-content: space-between;
    .total {
      line-height: 32px;
    }
  }
  .right {
    display: flex;
  }
  .upload-button {
    display: inline-block;
    position: relative;
    margin: 0 10px;
    overflow: hidden;
    height: 32px;
    > div {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      .el-button--small {
        width: 80px;
      }
    }
  }
</style>
