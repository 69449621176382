import { render, staticRenderFns } from "./xmglDetail.vue?vue&type=template&id=dd8c331c&scoped=true&"
import script from "./xmglDetail.vue?vue&type=script&lang=js&"
export * from "./xmglDetail.vue?vue&type=script&lang=js&"
import style0 from "./xmglDetail.vue?vue&type=style&index=0&id=dd8c331c&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "dd8c331c",
  null
  
)

export default component.exports