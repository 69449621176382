import Vue from 'vue'
import Router from 'vue-router'

Vue.use(Router)

const staticRoutes = [{
  path: '/',
  name: 'index',
  component: () =>
    import('@/components/WtLayout/WtMainLayout.vue'),
  redirect: '/welcome',
  meta: { title: '首页', icon: 'el-icon-s-platform' },
  children: [{
    path: 'welcome',
    name: 'WELCOME',
    component: () =>
      import('@/modules/home/dashboard.vue'),
    meta: { title: '首页', icon: 'el-icon-s-platform', hiddenInTab: true }
  }]
},
{
  path: '/test',
  name: 'test',
  component: () =>
    import('@/components/WtLayout/WtMainLayout.vue'),
  meta: { title: '测试', icon: 'el-icon-s-operation' },
  children: [{
    path: 'test1',
    name: 'TEST1',
    component: () =>
        import('@/modules/layout/test.vue'),
    meta: { title: '测试1', icon: 'el-icon-menu' },
    children: [{
      path: 'test1_1',
      name: 'TEST1_1',
      component: () =>
        import('@/modules/layout/blank.vue'),
      meta: { title: '测试1_1', icon: 'el-icon-menu' }
    }, {
      path: 'test1_2',
      name: 'TEST1_2',
      component: () =>
        import('@/modules/layout/test.vue'),
      meta: { title: '测试1_2', icon: 'el-icon-menu' }
    }]
  }, {
    path: 'test2',
    name: 'TEST2',
    component: () =>
      import('@/modules/layout/blank.vue'),
    meta: { title: '测试2', icon: 'el-icon-warning-outline' }
  }, {
    path: 'test3',
    name: 'TEST3',
    component: () =>
      import('@/modules/layout/blank.vue'),
    meta: { title: '测试3', icon: 'el-icon-warning-outline' },
    children: [{
      path: 'test3_1',
      name: 'TEST3_1',
      component: () =>
        import('@/modules/layout/blank.vue'),
      meta: { title: '测试3_1', icon: 'el-icon-menu' },
      children: [{
        path: 'test3_1_1',
        name: 'TEST3_1_1',
        component: () =>
          import('@/modules/layout/blank.vue'),
        meta: { title: '测试3_1_1', icon: 'el-icon-menu' }
      }, {
        path: 'test3_1_2',
        name: 'TEST3_1_2',
        component: () =>
          import('@/modules/layout/blank.vue'),
        meta: { title: '测试3_1_2', icon: 'el-icon-menu' }
      }]
    }, {
      path: 'test3_2',
      name: 'TEST3_2',
      component: () =>
        import('@/modules/layout/blank.vue'),
      meta: { title: '测试3_2', icon: 'el-icon-menu' },
      children: [{
        path: 'test3_2_1',
        name: 'TEST3_2_1',
        component: () =>
          import('@/modules/layout/blank.vue'),
        meta: { title: '测试1_1', icon: 'el-icon-menu' }
      }, {
        path: 'test3_2_2',
        name: 'TEST3_2_2',
        component: () =>
          import('@/modules/layout/blank.vue'),
        meta: { title: '测试3_2_2', icon: 'el-icon-menu' }
      }]
    }, {
      path: 'test3_3',
      name: 'TEST3_3',
      component: () =>
        import('@/modules/layout/blank.vue'),
      meta: { title: '测试3_3', icon: 'el-icon-menu' }
    }]
  }]
},
{
  path: '/system',
  name: 'system',
  component: () =>
    import('@/components/WtLayout/WtMainLayout.vue'),
  meta: { title: '系统管理', icon: 'el-icon-s-operation' },
  children: [{
    path: 'menuList',
    name: 'SYSMENU',
    component: () =>
      import('@/modules/system/menuGroupList.vue'),
    meta: { title: '菜单管理', icon: 'el-icon-warning-outline' }
  }, {
    path: 'userList',
    name: 'SYSUSER',
    component: () =>
      import('@/modules/system/userList.vue'),
    meta: { title: '账号管理', icon: 'el-icon-warning-outline' }
  }, {
    path: 'roleList',
    name: 'SYSROLE',
    component: () =>
        import('@/modules/system/roleList.vue'),
    meta: { title: '角色管理', icon: 'el-icon-warning-outline' }
  }, {
    path: 'permissionList',
    name: 'SYSPERMISSION',
    component: () =>
      import('@/modules/system/permissionList.vue'),
    meta: { title: '权限管理' }
  }, {
    path: 'sysLogList',
    name: 'SYSGLOBALLOG',
    component: () =>
      import('@/modules/system/sysLogList.vue'),
    meta: { title: '日志管理', icon: '' }
  }]
}
// {
//   path: '*',
//   redirect: '/404',
//   meta: { title: '', icon: '', hidden: true }
// }
]

export default staticRoutes
