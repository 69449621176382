<template>
  <div class="wt-normal-layout">
    <div class="wt-silder" :class="{'is-silder-close': !sidebar.opened}">
      <div class="wt-silder-scroller wt-scroll">
        <div class="wt-logo-container wt-normal-logo-container">
          <a href="#" class="wt-logo-href">
            <span class="wt-logo"><img src="../../../../assets/images/logo.png" alt="logo"/></span>
            <span class="wt-logo-title">{{appName}}</span>
          </a>
        </div>
        <el-menu :default-active="activeIndex" class="el-menu-demo" background-color="#001529" text-color="#fffffff2">
          <!-- <el-menu-item index="1">处理中心</el-menu-item> -->
          <el-submenu v-for="(routeItem, routeIndex) in moduleList" :key="routeIndex" v-if="!(routeItem.meta && routeItem.meta.hidden)"
                   :class="{'is-active': mainMenuName == routeItem.name}" :index="routeIndex">
            <template slot="title">{{routeItem.meta.title}}</template>
            <el-menu-item @click="onClickMainMenu(item)" v-for="(item, idx) in routeItem.children" :key="idx" v-if="!(item.meta && item.meta.hidden)" :index="idx">{{item.meta.title}}</el-menu-item>
            <!-- <el-menu-item index="2-3">选项3</el-menu-item> -->
            <!-- <el-submenu index="2-4">
              <template slot="title">选项4</template>
              <el-menu-item index="2-4-1">选项1</el-menu-item>
              <el-menu-item index="2-4-2">选项2</el-menu-item>
              <el-menu-item index="2-4-3">选项3</el-menu-item>
            </el-submenu> -->
          </el-submenu>
        </el-menu>
        <!-- <el-menu :default-active="activeIndex" class="el-menu-demo" background-color="#001529" text-color="#fffffff2">
          <el-menu-item index="1">处理中心</el-menu-item>
          <el-submenu index="2">
            <template slot="title">我的工作台</template>
            <el-menu-item index="2-1">选项1</el-menu-item>
            <el-menu-item index="2-2">选项2</el-menu-item>
            <el-menu-item index="2-3">选项3</el-menu-item>
            <el-submenu index="2-4">
              <template slot="title">选项4</template>
              <el-menu-item index="2-4-1">选项1</el-menu-item>
              <el-menu-item index="2-4-2">选项2</el-menu-item>
              <el-menu-item index="2-4-3">选项3</el-menu-item>
            </el-submenu>
          </el-submenu>
          <el-menu-item index="3" disabled>消息中心</el-menu-item>
          <el-menu-item index="4">订单管理</el-menu-item>
        </el-menu> -->
      </div>
    </div>
    <div class="wt-main" :class="{'is-silder-close': !sidebar.opened}">
      <div class="wt-layout-header wt-fixed-header wt-normal-fixed-header">
        <div class="wt-nav">
          <el-row :gutter="10">
            <el-col :span="12">
              <div class="wt-nav-left-panel">
                <i class="wt-fold" :class="[sidebar.opened ? 'el-icon-s-fold' : 'el-icon-s-unfold']" @click="onClickFold"></i>
                <wt-breadcrumb />
              </div>
            </el-col>
            <el-col :span="12">
              <div class="wt-nav-right-panel">
                <i class="wt-nav-icon el-icon-full-screen"></i>
                <wt-user-panel />
              </div>
            </el-col>
          </el-row>
        </div>
        <wt-tabs v-if="theme.isTabEnable" />
      </div>
      <div id="wt-app-main" class="wt-app-main" :class="{'is-tabs-hidden': !theme.isTabEnable}">
        <section>
          <router-view></router-view>
        </section>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapState } from 'vuex'
import WtBreadcrumb from '../../breadcrumb/WtBreadcrumb'
import WtTabs from '../../tabs/WtTabs'
import WtUserPanel from '../../userPanel/WtUserPanel'
export default {
  name: 'WtNormalLayout',
  data() {
    return {
      activeIndex: '',
      appName: process.env.VUE_APP_NAME
    }
  },
  components: {
    WtTabs,
    WtBreadcrumb,
    WtUserPanel
  },
  mounted() {
    console.log(this.moduleList)
  },
  methods: {
    onClickFold() {
      this.$store.dispatch('toggleSideBar')
    },
    onClickMainMenu(item) {
      console.log(item)
      // this.$router.push({ path: item.path })
      const itemNotHiddenChildren = (item.children || []).filter(item => item.meta && !item.meta.hidden)
      const findFirtChildren = (temp) => {
        const notHiddenChildren = (temp.children || []).filter(item => item.meta && !item.meta.hidden)
        if (notHiddenChildren.length > 0) {
          return findFirtChildren(notHiddenChildren[0])
        }
        return temp
      }
      console.log(this.$router)
      console.log(findFirtChildren(item).name)
      if (itemNotHiddenChildren.length > 0) {
        this.$router.push({ name: findFirtChildren(item).name })
      } else {
        this.$router.push({ path: item.path })
      }
    }
  },
  computed: {
    ...mapState({
      theme: state => state.app.theme,
      mainMenuName: state => state.app.mainMenuName,
      subMenuName: state => state.app.subMenuName,
      moduleList: state => state.auth.moduleList
    }),
    ...mapGetters([
      'sidebar'
    ])
  }
}
</script>

<style lang="scss" scoped>
  @import "@/styles/variables.scss";
  .wt-main.is-silder-close {
    margin-left: 0px;
    .wt-normal-fixed-header {
      width: calc(100% - 0px);
    }
  }
  .wt-normal-layout .wt-silder{
    background-color: #001529;
  }
  .wt-normal-layout {
    padding-top: 110px;
    &.is-tabs-hidden {
      padding-top: 60px;
    }
  }
  .wt-normal-logo-container{
    .wt-logo-href{
      display: flex;
      align-items: center;
      justify-content: center;
      text-decoration: none;
    }
    .wt-logo {
      color: #FFFFFF;
      position: relative;
      overflow: hidden;
      background: transparent;
      height: 60px;
      line-height: 60px;
      text-align: center;
      display: flex;
      align-items: center;
      img {
        width: 40px;
      }
    }
    .wt-logo-title {
      display: inline-block;
      margin-left: 5px;
      overflow: hidden;
      font-size: 20px;
      line-height: 55px;
      color: #fff;
      text-overflow: ellipsis;
      white-space: nowrap;
      max-width: 206px;
    }
  }

  .wt-normal-fixed-header {
    transition: all .3s cubic-bezier(.645, .045, .355, 1), border 0s, color .1s, font-size 0s;
    width: calc(100% - 266px);
    .wt-nav {
      position: relative;
      height: $--wt-header-height;
      padding-right: 20px;
      padding-left: 20px;
      overflow: hidden;
      -webkit-user-select: none;
      -moz-user-select: none;
      -ms-user-select: none;
      user-select: none;
      background: #FFFFFF;
      box-shadow: 0 1px 4px rgb(0 21 41 / 8%);

      .wt-nav-left-panel {
        display: flex;
        align-items: center;
        justify-items: center;
        height: $--wt-header-height;
      }
      .wt-nav-right-panel {
        display: flex;
        align-items: center;
        justify-items: center;
        justify-content: flex-end;
        height: $--wt-header-height;
        .wt-nav-icon {
          display: none;
          margin-left: 20px;
          font-size: 16px;
          text-align: center;
        }
      }
    }

    .wt-tabs {
      position: relative;
      box-sizing: border-box;
      display: flex;
      align-content: center;
      align-items: center;
      justify-content: space-between;
      min-height: 50px;
      padding-right: 20px;
      padding-left: 20px;
      -webkit-user-select: none;
      -moz-user-select: none;
      -ms-user-select: none;
      user-select: none;
      background: #FFFFFF;
      border-top: 1px solid #f6f6f6;
    }
  }
</style>
