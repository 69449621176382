<template>
  <wt-page>
    <div class="wt-page-container" v-loading="isFromLoading">
      <div class="page-detail">
        <el-table
          :data="detail.documents"
          style="width: 100%">
          <el-table-column type="index" label="序号"></el-table-column>
          <el-table-column label="文件名称">
            <template slot-scope="scope">
              <span @click="documentsDownload(scope.row)">{{getCompanyName(scope.row.name)}}</span>
            </template>
          </el-table-column>
        </el-table>
      </div>
    </div>
    <div slot="footer">
      <wt-button type="default" @click="onClickClose">关闭</wt-button>
      <wt-button type="primary" v-if="viewType == 'add'" v-has-any-premission="['SYSROLE:ADD']" :loading="isFromLoading" @click="onClickAdd">保存</wt-button>
      <wt-button type="primary" v-if="viewType == 'edit'" v-has-any-premission="['SYSROLE:UPDATE']" :loading="isFromLoading" @click="onClickEdit">保存</wt-button>
    </div>
  </wt-page>
</template>

<script>
import { mapState } from 'vuex'

export default {
  name: 'companyReport',
  isAutoComponent: true,
  props: ['viewType', 'detailData'],
  data() {
    return {
      isButtonLoading: false,
      isFromLoading: false,
      detail: {},
      detailForm: {
        menuGroupId: '',
        orderNum: 0,
        isPublish: 1
      }
    }
  },
  async mounted() {
    if (this.viewType !== 'add') {
      await this.requestDetail()
    }
  },
  created() {},
  methods: {
    onClickViewStandard(row) {
      console.log(row)
    },
    onClickViewFloor(row) {
      console.log(row)
    },
    onClickViewPark(row) {
      console.log(row)
    },
    onClickViewBasement(row) {
      console.log(row)
    },
    documentsDownload(row) {
      window.open('row.url', '_blank')
    },
    onClickClose() {
      this.$emit('on-close')
    },
    onClickAdd() {
      const that = this
      this.$wtUtil.validateConfirm(this, '是否保存?').then(() => {
        that.requestAdd()
      })
    },
    onClickEdit() {
      const that = this
      this.$wtUtil.validateConfirm(this, '是否保存?').then(() => {
        that.requestUpdate()
      })
    },
    initDetailData(data) {
      this.detail = data
    },
    requestDetail() {
      const that = this
      that.isFromLoading = true
      that.$wtRequest({
        url: '/tProjectInfo/detail',
        method: 'post',
        data: { id: that.detailData.id }
      }).then((resp) => {
        console.log('requestDetail resp', resp)
        // that.detail = resp.data || {}
        this.initDetailData(resp.data)
        that.isFromLoading = false
      }).catch((e) => {
        console.log('requestDetail e', e)
        that.isFromLoading = false
      })
    }
  },
  computed: {
    isReadMode() {
      return this.viewType === 'view'
    },
    ...mapState({
    })
  }
}
</script>
<style lang="scss" scoped>
  .module-permission-content {display: flex;width: 100%;margin-top: 16px; color: #666;}
  .module-permission-content .left-content {width: 250px;padding: 0 24px;}
  .module-permission-content .right-content {flex: 1;}
  .module-menu-content {display: flex;width: 100%;}
  .module-menu-content .left-content {width: 300px;padding: 0 24px;}
  .module-menu-content .right-content {flex: 1;}
  .page-detail {
    padding: 20px;
  }
</style>
