<template>
  <div :class="{fullscreen:fullscreen}" class="tinymce-container editor-container" v-loading="isLoading">
    <textarea :id="tinymceId" class="tinymce-textarea" />
    <div class="editor-custom-btn-container">
      <div class="upload-container editor-upload-btn">
        <input :ref="'file-' + tinymceId" type="file" style="display: none;" accept="image/png,image/jpeg,image/gif,image/jpg" @change="onWtImageFilePicker" multiple/>
      </div>
    </div>
  </div>
</template>

<script>
import plugins from './plugins'
import toolbar from './toolbar'
import asyncPool from './asyncPool'
import OSS from 'ali-oss'
// eslint-disable-next-line no-unused-vars
const FILE_STATE = {
  PROCESS: 'process',
  FAIL: 'fail',
  SUCCESS: 'success'
}
const printLog = (...args) => {
  // console.log(...args)
}
export default {
  name: 'WtTinymce',
  components: { },
  props: {
    id: {
      type: String,
      default: function() {
        return 'vue-tinymce-' + +new Date() + ((Math.random() * 1000).toFixed(0) + '')
      }
    },
    value: {
      type: String,
      default: ''
    },
    readonly: {
      type: Boolean,
      default: false
    },
    toolbar: {
      type: Array,
      required: false,
      default() {
        return []
      }
    },
    menubar: {
      type: String,
      default: 'file edit insert view format table'
    },
    height: {
      type: Number,
      required: false,
      default: 360
    }
  },
  data() {
    return {
      wtBaseApi: '',
      wtFileServerUrl: process.env.VUE_APP_FILE_SERVER_URL,
      dataList: [],
      isLoading: false,
      hasChange: false,
      hasInit: false,
      tinymceId: this.id,
      fullscreen: false,
      languageTypeList: {
        'en': 'en',
        'zh': 'zh_CN'
      }
    }
  },
  computed: {
    language() {
      return this.languageTypeList[this.$store.getters.language]
    }
  },
  watch: {
    value(val, oldVal) {
      if (val !== oldVal && (!oldVal || oldVal === '')) {
        this.$nextTick(() =>
          window.tinymce.get(this.tinymceId).setContent(val || ''))
        return
      }
      if (!this.hasChange && this.hasInit) {
        this.$nextTick(() =>
          window.tinymce.get(this.tinymceId).setContent(val || ''))
      }
      if (this.hasChange && this.hasInit) {
        this.$nextTick(() =>
          window.tinymce.get(this.tinymceId).setContent(val || ''))
      }
    },
    readonly(val, oldVal) {
      if (val !== oldVal) {
        this.$nextTick(() => {
          this.destroyTinymce()
          this.$nextTick(() => this.initTinymce())
        })
      }
    },
    language() {
      this.destroyTinymce()
      this.$nextTick(() => this.initTinymce())
    }
  },
  mounted() {
    this.initTinymce()
  },
  activated() {
    this.initTinymce()
  },
  deactivated() {
    this.destroyTinymce()
  },
  destroyed() {
    this.destroyTinymce()
  },
  methods: {
    initTinymce() {
      const _this = this
      window.tinymce.init({
        readonly: this.readonly,
        language: this.language,
        selector: `#${this.tinymceId}`,
        height: this.height,
        body_class: 'panel-body ',
        object_resizing: false,
        toolbar: this.toolbar.length > 0 ? this.toolbar : toolbar,
        menubar: this.menubar,
        fontsize_formats: '8pt 10pt 12pt 14pt 18pt 24pt 36pt',
        font_formats: '宋体=宋体;黑体=黑体;仿宋=仿宋;楷体=楷体;隶书=隶书;幼圆=幼圆;Andale Mono=andale mono,times;Arial=arial,helvetica,sans-serif;Arial Black=arial black,avant garde;Book Antiqua=book antiqua,palatino;Comic Sans MS=comic sans ms,sans-serif;Courier New=courier new,courier;Georgia=georgia,palatino;Helvetica=helvetica;Impact=impact,chicago;Symbol=symbol;Tahoma=tahoma,arial,helvetica,sans-serif;Terminal=terminal,monaco;Times New Roman=times new roman,times;Trebuchet MS=trebuchet ms,geneva;Verdana=verdana,geneva;Webdings=webdings;Wingdings=wingdings,zapf dingbats',
        plugins: plugins,
        end_container_on_empty_block: true,
        code_dialog_height: 450,
        code_dialog_width: 1000,
        advlist_bullet_styles: 'square',
        advlist_number_styles: 'default',
        default_link_target: '_blank',
        link_title: false,
        powerpaste_word_import: 'propmt', //  clean,propmt,merge
        powerpaste_html_import: 'propmt', //  clean,propmt,merge
        powerpaste_allow_local_images: true,
        paste_data_images: true,
        // images_upload_handler: (blobInfo, success, failure) => {
        //   this.$refs['wtUpload'].uploadFile(blobInfo, success, failure)
        // },
        wtImagePicker: (wtImageCallback) => {
          _this.wtImageCallback = wtImageCallback
          _this.$refs['file-' + this.tinymceId].click()
        },
        nonbreaking_force_tab: true,
        paste_preprocess: (plugin, args) => {
          console.log('paste_preprocess', plugin, args)
        },
        // init_instance_callback: editor => {
        //   if (_this.value) {
        //     editor.setContent(_this.value)
        //   }
        //   _this.hasInit = true
        //   editor.on('NodeChange Change KeyUp SetContent', () => {
        //     this.hasChange = true
        //     this.$emit('input', editor.getContent())
        //   })
        // },
        setup(editor) {
          editor.on('FullscreenStateChanged', (e) => {
            _this.fullscreen = e.state
          })
          editor.on('change', function(e) {
            const val = editor.getContent()
            _this.$emit('input', val)
          })
        }
        // content_style: `
        //   *                         { padding:0; margin:0; }
        //   html, body                { height:100%; }
        //   img                       { max-width:100%; display:block;height:auto; }
        //   a                         { text-decoration: none; }
        //   iframe                    { width: 100%; }
        //   p                         { line-height:1.6; margin: 0px; }
        //   table                     { word-wrap:break-word; word-break:break-all; max-width:100%; border:none; border-color:#999; }
        //   .mce-object-iframe        { width:100%; box-sizing:border-box; margin:0; padding:0; }
        //   ul,ol                     { list-style-position:inside; }
        // `
      }).then(resolve => {
        if (_this.value) {
          _this.setContent(_this.value)
        }
      })
    },
    destroyTinymce() {
      const tinymce = window.tinymce.get(this.tinymceId)
      if (this.fullscreen) {
        tinymce.execCommand('mceFullScreen')
      }

      if (tinymce) {
        tinymce.destroy()
      }
    },
    setContent(value) {
      window.tinymce.get(this.tinymceId).setContent(value)
    },
    getContent() {
      return window.tinymce.get(this.tinymceId).getContent()
    },
    //  上传图片完成
    onWtImageFileUploadFinished(fileList) {
      printLog('onWtImageFileUploadFinished', fileList)
      const that = this
      if (fileList && fileList.length > 0) {
        fileList.forEach(file => {
          window.tinymce.get(that.tinymceId).insertContent(`<img src="${that.wtFileServerUrl}/${file.fileUrl}" >`)
        })
      }
    },
    onWtImageFilePicker(event) {
      const that = this
      try {
        const files = event.target.files
        if (!files || files.length <= 0) {
          that.$refs['file-' + this.tinymceId].value = ''
          return
        }
        const fileList = []
        for (let i = 0; i < files.length; i++) {
          const file = files[i]
          file.fileId = this.$wtUtil.uuid()
          file.fileName = file.name
          file.state = FILE_STATE.PROCESS
          file.percent = 0
          fileList.push(file)
        }
        that.dataList = that.dataList.concat(fileList)
        that.isLoading = true
        that.$wtUtil.getFileListMd5(fileList).then((md5FileList) => {
          return that.startCheckFileList(md5FileList)
        }).then((md5FileList) => {
          return that.startCheckStsAssumeRole(md5FileList)
        }).then((md5FileList) => {
          return that.startUploadFileList(md5FileList)
        }).then((md5FileList) => {
          that.isLoading = false
          return that.requestFileCompleteNotify(md5FileList)
        }).then((md5FileList) => {
          that.isLoading = false
          that.onWtImageFileUploadFinished(md5FileList)
        }).catch((errItem) => {
          that.isLoading = false
          that.$message.error(`文件 ${errItem.name} 上传出错`)
        })
      } catch (ex) {
        that.isLoading = false
        console.log('上传失败', ex)
      }
      that.$refs['file-' + this.tinymceId].value = ''
    },
    startCheckFileList(md5FileList) {
      printLog('>>>> startCheckFileList md5FileList', md5FileList)
      const that = this
      const md5List = []
      md5FileList.forEach((md5File) => {
        md5List.push(md5File.md5)
      })
      return new Promise((resolve, reject) => {
        that.$wtRequest({
          url: '/file/checkFiles',
          method: 'post',
          data: { md5List: md5List }
        }).then(resp => {
          if (resp.code !== '0') {
            reject(new Error('检查文件失败'))
          }
          if (resp.data && resp.data.length > 0) {
            const itemMd5Map = {}
            resp.data.forEach(item => {
              itemMd5Map[item.fileMd5] = item.fileUrl
              that.updateFileItemByMD5({ md5: item.fileMd5, state: FILE_STATE.SUCCESS, percent: 100, fileUrl: item.fileUrl })
            })
            md5FileList.forEach((md5File) => {
              md5File.fileUrl = itemMd5Map[md5File.md5]
            })
          }
          const resultMd5FileList = that.getProcessMd5FileList(md5FileList)
          resolve(resultMd5FileList)
        }).catch((err) => {
          reject(err)
        })
      })
    },
    //  获取sts角色
    startCheckStsAssumeRole(md5FileList) {
      const that = this
      if (!md5FileList || md5FileList.length <= 0) {
        return new Promise((resolve) => {
          resolve([])
        })
      }
      printLog('>>>> startCheckStsAssumeRole', md5FileList)
      let isNeedRefreshStsAssumeRole = false
      const assumeRoleCredentialsStr = sessionStorage.getItem('STS:CRED') || '{}'
      that.assumeRoleCredentials = JSON.parse(assumeRoleCredentialsStr)
      if (!that.assumeRoleCredentials.expiration) {
        isNeedRefreshStsAssumeRole = true
      } else {
        const expiration = new Date(that.assumeRoleCredentials.expiration)
        const now = new Date()
        isNeedRefreshStsAssumeRole = (expiration.getTime() - now.getTime() < 1000 * 30)
      }
      if (isNeedRefreshStsAssumeRole) {
        return that.requestStsAssumeRole(md5FileList)
      }
      return new Promise((resolve, reject) => {
        resolve(md5FileList)
      })
    },
    requestStsAssumeRole(md5FileList) {
      const that = this
      return new Promise((resolve, reject) => {
        that.$wtRequest({
          url: '/file/stsGrantAuthorization',
          method: 'post',
          data: {}
        }).then(resp => {
          if (resp.code === '0') {
            that.assumeRoleCredentials = resp.data
            sessionStorage.setItem('STS:CRED', JSON.stringify(resp.data))
            resolve(md5FileList)
          } else {
            reject(new Error('获取上传凭据失败'))
          }
        }).catch((e) => {
          reject(e)
        })
      })
    },
    startUploadFileList(md5FileList) {
      const that = this
      if (!md5FileList || md5FileList.length <= 0) {
        return new Promise((resolve) => {
          resolve([])
        })
      }
      printLog('>>>> startUploadFileList md5FileList', md5FileList)
      return asyncPool(that.maxFileUploadNum, md5FileList, that.uploadOneFile)
    },
    uploadOneFile(md5File) {
      const that = this
      // eslint-disable-next-line no-unused-vars
      const client = new OSS({
        bucket: that.assumeRoleCredentials.bucket,
        region: that.assumeRoleCredentials.region,
        accessKeyId: that.assumeRoleCredentials.accessKeyId,
        accessKeySecret: that.assumeRoleCredentials.accessKeySecret,
        stsToken: that.assumeRoleCredentials.securityToken,
        refreshSTSToken: async() => {
          await that.requestStsAssumeRole()
          return that.assumeRoleCredentials
        },
        refreshSTSTokenInterval: 1000 * 3000
      })
      return new Promise((resolve, reject) => {
        const fileMd5 = md5File.md5
        const fileName = md5File.fileName
        const basePath = that.$wtUtil.isNotBlank(that.assumeRoleCredentials.ossPath) ? that.assumeRoleCredentials.ossPath + '/' : ''
        const ossFileEnd = fileName.lastIndexOf('.') !== -1 ? fileName.substring(fileName.lastIndexOf('.')) : ''
        const ossFilePath = fileMd5.substring(0, 2) + '/' + fileMd5.substring(2, 4) + '/'
        const ossFileName = basePath + ossFilePath + fileMd5 + ossFileEnd
        client.multipartUpload(ossFileName, md5File, {
          progress: (p, checkpoint) => {
            const percent = Math.min(Math.floor(p * 100, 100))
            that.updateFileItemByMD5({ md5: md5File.md5, state: FILE_STATE.PROCESS, percent: percent })
          }
        }).then((resp) => {
          that.updateFileItemByMD5({ md5: md5File.md5, state: FILE_STATE.SUCCESS, percent: 100, fileUrl: resp.name })
          resolve(md5File)
        })
      })
      // 测试用
      // return new Promise((resolve, reject) => {
      //   setTimeout(() => {
      //     that.updateFileItemByMD5({ md5: md5File.md5, state: FILE_STATE.SUCCESS, percent: 100, fileUrl: 'example.jpeg' })
      //     resolve(md5File)
      //   }, 1000 + Math.random() * 2000)
      // })
    },
    requestFileCompleteNotify(md5FileList) {
      if (!md5FileList || md5FileList.length <= 0) {
        return new Promise((resolve) => {
          resolve([])
        })
      }
      printLog('>>>> requestFileCompleteNotify md5FileList', md5FileList)
      const that = this
      const notifyFormData = {}
      md5FileList.forEach(item => {
        notifyFormData[item.md5] = item.fileUrl
      })
      return new Promise((resolve, reject) => {
        that.$wtRequest({
          url: '/file/uploadCompleteNotify',
          method: 'post',
          data: notifyFormData
        }).then(resp => {
          resolve(md5FileList)
        }).catch((e) => {
          reject(e)
        })
      })
    },
    getProcessMd5FileList(md5FileList) {
      const resultList = []
      const that = this
      md5FileList.forEach(md5File => {
        if (that.$wtUtil.isBlank(md5File.fileUrl)) {
          resultList.push(md5File)
        }
      })
      return resultList
    },
    updateFileItemByMD5({ md5, state, percent, fileUrl }) {
      printLog('\tupdateFileItemByMD5', md5, state, percent)
      const that = this
      const tempDataList = []
      const tempModelValue = []
      that.dataList.forEach((item, itemIndex) => {
        if (item.md5 === md5) {
          item.state = state
          item.percent = percent
          if (fileUrl) {
            item.fileUrl = fileUrl
          }
        }
        if (item.state === FILE_STATE.SUCCESS) {
          tempModelValue.push({ fileName: item.fileName, fileUrl: item.fileUrl })
        }
        tempDataList.push(item)
      })
      that.dataList = tempDataList
      if (state === FILE_STATE.SUCCESS) {
        that.dataValue = tempModelValue
        if (that.$parent && that.$parent.clearValidate) {
          that.$parent.clearValidate()
        }
      }
    }
  }
}
</script>

<style scoped>
  .tinymce-container {
    position: relative;
  }
  .tinymce-container>>>.mce-fullscreen {
    z-index: 10000;
  }
  .tinymce-textarea {
    visibility: hidden;
    z-index: -1;
  }
  .editor-custom-btn-container {
    position: absolute;
    right: 4px;
    top: 4px;
    /*z-index: 2005;*/
  }
  .fullscreen .editor-custom-btn-container {
    z-index: 10000;
    position: fixed;
  }
  .editor-upload-btn {
    display: inline-block;
  }
</style>
