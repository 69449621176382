<template>
  <wt-page>
    <div class="wt-page-container" v-loading="isFromLoading">
      <div class="page-detail">
        <wt-page-section title="基本概况">
          <div class="module-permission-content">
            <div class="text-list">
              <div class="text-item">
                <div class="title">项目ID：</div>
                <div class="content">{{detail.id}}</div>
              </div>
              <div class="text-item">
                <div class="title">项目名称：</div>
                <div class="content">{{detail.name}}</div>
              </div>
              <div class="text-item">
                <div class="title">项目业态：</div>
                <div class="content">{{detail.scenes.name}}</div>
              </div>
              <div class="text-item three-item">
                <div class="title">项目地址：</div>
                <div class="content">{{detail.addr}}</div>
              </div>
              <template v-if="detail.scenes.scenesPropertys">
              <div class="text-item" v-for="scene in detail.scenes.scenesPropertys">
                <div class="title">{{scene.name}}</div>
                <div class="content">{{scene.value}}{{scene.unit}}</div>
              </div>
              </template>
            </div>
          </div>
        </wt-page-section>
        <wt-page-section title="查验协调小组">
          <div class="module-permission-content">
            <el-table
              :data="detail.assistGroups"
              style="width: 100%">
              <el-table-column type="index" label="序号"></el-table-column>
              <el-table-column
                prop="responsibleCompany"
                label="相关责任单位">
              </el-table-column>
              <el-table-column
                prop="responsiblePerson"
                label="姓名">
              </el-table-column>
              <el-table-column
                prop="phone"
                label="联系方式">
              </el-table-column>
            </el-table>
          </div>
        </wt-page-section>
        <wt-page-section title="查验时间">
          <div class="module-permission-content">
            <div class="text-list list-two">
              <div class="text-item">
                <div class="title">开始时间：</div>
                <div class="content">{{detail.checkStartTime}}</div>
              </div>
              <div class="text-item">
                <div class="title">结束时间：</div>
                <div class="content">{{detail.checkEndTime}}</div>
              </div>
            </div>
          </div>
        </wt-page-section>
        <wt-page-section title="法律法规">
          <div class="module-permission-content">
            <el-table
              :data="detail.documents"
              style="width: 100%">
              <el-table-column type="index" label="序号"></el-table-column>
              <el-table-column label="文件名称">
                <template slot-scope="scope">
                  <span @click="documentsDownload(scope.row)">{{getCompanyName(scope.row.name)}}</span>
                </template>
              </el-table-column>
            </el-table>
          </div>
        </wt-page-section>
        <wt-page-section title="查验范围">
          <div class="module-permission-content">
            <el-table
              :data="detail.profestionalWorkTeams"
              style="width: 100%">
              <el-table-column type="expand">
                <template slot-scope="props">
                  <div style="margin: -8px 20px">
                  <el-table
                    :data="[props.row]"
                    style="width: 100%">
                    <el-table-column
                      prop="name"
                      label="组名">
                    </el-table-column>
                    <el-table-column
                      prop="firstAccount"
                      label="初验人">
                    </el-table-column>
                    <el-table-column
                      prop="firstAuditAccount"
                      label="初验核定人">
                    </el-table-column>
                    <el-table-column
                      prop="secondAccount"
                      label="复验人">
                    </el-table-column>
                    <el-table-column
                      prop="secondAuditAccount"
                      label="复验核定人">
                    </el-table-column>
                    <el-table-column label="核定标准">
                      <template slot-scope="scope">
                      <wt-text-button @click="onClickViewStandard(scope.row)">查看</wt-text-button>
                      </template>
                    </el-table-column>
                    <el-table-column label="楼宇">
                      <template slot-scope="scope">
                      <wt-text-button @click="onClickViewFloor(scope.row)">查看</wt-text-button>
                      </template>
                    </el-table-column>
                    <el-table-column label="园区">
                      <template slot-scope="scope">
                      <wt-text-button @click="onClickViewPark(scope.row)">查看</wt-text-button>
                      </template>
                    </el-table-column>
                    <el-table-column label="地下室">
                      <template slot-scope="scope">
                      <wt-text-button @click="onClickViewBasement(scope.row)">查看</wt-text-button>
                      </template>
                    </el-table-column>
                  </el-table>
                  </div>
                </template>
              </el-table-column>
              <el-table-column type="index" label="序号"></el-table-column>
              <el-table-column
                label="专业名称"
                prop="name">
              </el-table-column>
              <el-table-column label="操作" fixed="right">
                <template>
                </template>
              </el-table-column>
            </el-table>
          </div>
        </wt-page-section>
      </div>
    </div>
    <div slot="footer">
      <wt-button type="default" @click="onClickClose">关闭</wt-button>
      <wt-button type="primary" v-if="viewType == 'add'" v-has-any-premission="['SYSROLE:ADD']" :loading="isFromLoading" @click="onClickAdd">保存</wt-button>
      <wt-button type="primary" v-if="viewType == 'edit'" v-has-any-premission="['SYSROLE:UPDATE']" :loading="isFromLoading" @click="onClickEdit">保存</wt-button>
    </div>
  </wt-page>
</template>

<script>
import { mapState } from 'vuex'

export default {
  name: 'companyDetail',
  isAutoComponent: true,
  props: ['viewType', 'detailData'],
  data() {
    return {
      isButtonLoading: false,
      isFromLoading: false,
      detail: {},
      detailForm: {
        menuGroupId: '',
        orderNum: 0,
        isPublish: 1
      }
    }
  },
  async mounted() {
    if (this.viewType !== 'add') {
      await this.requestDetail()
    }
  },
  created() {},
  methods: {
    onClickViewStandard(row) {
      console.log(row)
    },
    onClickViewFloor(row) {
      console.log(row)
    },
    onClickViewPark(row) {
      console.log(row)
    },
    onClickViewBasement(row) {
      console.log(row)
    },
    documentsDownload(row) {
      window.open('row.url', '_blank')
    },
    onClickClose() {
      this.$emit('on-close')
    },
    onClickAdd() {
      const that = this
      this.$wtUtil.validateConfirm(this, '是否保存?').then(() => {
        that.requestAdd()
      })
    },
    onClickEdit() {
      const that = this
      this.$wtUtil.validateConfirm(this, '是否保存?').then(() => {
        that.requestUpdate()
      })
    },
    initDetailData(data) {
      this.detail = data
    },
    requestDetail() {
      const that = this
      that.isFromLoading = true
      that.$wtRequest({
        url: '/tProjectInfo/detail',
        method: 'post',
        data: { id: that.detailData.id }
      }).then((resp) => {
        console.log('requestDetail resp', resp)
        // that.detail = resp.data || {}
        this.initDetailData(resp.data)
        that.isFromLoading = false
      }).catch((e) => {
        console.log('requestDetail e', e)
        that.isFromLoading = false
      })
    }
  },
  computed: {
    isReadMode() {
      return this.viewType === 'view'
    },
    ...mapState({
    })
  }
}
</script>
<style lang="scss" scoped>
  .module-permission-content {display: flex;width: 100%;margin-top: 16px; color: #666;}
  .module-permission-content .left-content {width: 250px;padding: 0 24px;}
  .module-permission-content .right-content {flex: 1;}
  .module-menu-content {display: flex;width: 100%;}
  .module-menu-content .left-content {width: 300px;padding: 0 24px;}
  .module-menu-content .right-content {flex: 1;}
  .wt-page-container {
    padding: 0;
  }
  .text-list {
    display: flex;
    flex-wrap: wrap;
    width: 100%;
    .text-item {
      flex-basis: 350px;
      width: 350px;
      display: flex;
      line-height: 32px;
      padding: 5px 0;
      .title {
        width: 100px;
        text-align: right;
      }
      .content {
        flex: 1;
      }
      &.three-item {
        flex-basis: 1050px;
        width: 1050px;
      }
    }
    &.list-two {
      .text-item {
        flex-basis: auto;
        width: auto;
        flex: 1;
      }
    }
  }
</style>
