<template>
  <el-breadcrumb class="wt-breadcrumb">
    <transition-group name="breadcrumb">
      <el-breadcrumb-item v-for="(item, itemIndex)  in levelList" :key="item.name + itemIndex">
        <i v-show="theme.isBreadcrumbIconVisible" class="wt-breadcrumb-icon" :class="[item.meta.icon]"></i>
        <span :class="{'is-link': item.isLink}" @click="onClickBreadcrumb(item)">{{item.meta.title}}</span>
      </el-breadcrumb-item>
    </transition-group>
  </el-breadcrumb>
</template>

<script>
import { mapState } from 'vuex'
export default {
  name: 'WtBreadcrumb',
  data() {
    return {
      firstChildMap: {},
      levelList: null
    }
  },
  created() {
    const that = this
    this.firstChildMap = {}
    const flatMapList = (item) => {
      if (item.children && item.children.length > 0) {
        that.firstChildMap[item.name] = item.children[0].name
        item.children.forEach(itemChildren => {
          flatMapList(itemChildren)
        })
      }
    }
    this.moduleList.forEach(item => {
      flatMapList(item)
    })
    this.getBreadcrumb()
  },
  watch: {
    $route() {
      this.getBreadcrumb()
    }
  },
  methods: {
    getBreadcrumb() {
      let matched = this.$route.matched.filter(item => item.name)
      const first = matched[0]
      //  当一级节点是首页时，隐藏一级节点
      if (first && first.name === 'index') {
        matched = matched.filter((item, itemIndex) => itemIndex > 0)
      }
      matched.forEach((item, itemIndex) => {
        item.isLink = itemIndex < (matched.length - 1)
      })
      this.levelList = matched
    },
    onClickBreadcrumb(item) {
      if (!item.isLink) {
        return
      }
      const findLastChildItem = (itemName) => {
        const firstChildName = this.firstChildMap[itemName]
        if (firstChildName) {
          return findLastChildItem(firstChildName)
        } else {
          return itemName
        }
      }
      const firstChildName = findLastChildItem(item.name)
      this.$router.push({ name: firstChildName })
    }
  },
  computed: {
    ...mapState({
      theme: state => state.app.theme,
      moduleList: state => state.auth.moduleList
    })
  }
}
</script>

<style lang="scss" scoped>
  @import "@/styles/variables.scss";
  .wt-breadcrumb{
    height: $--wt-header-height;
    line-height: $--wt-header-height;
    font-size: 14px;
    .is-link {
      cursor: pointer;
    }
    .is-link:hover {
    }
    .wt-breadcrumb-icon{
      margin-right: 4px;
    }
  }
</style>
