import SparkMD5 from 'spark-md5'
// import dayjs from 'dayjs'
import html2canvas from 'html2canvas'
import wtDownloadRequest from '@/networks/wtDownloadRequest'

function S4() {
  return (((1 + Math.random()) * 0x10000) | 0).toString(16).substring(1)
}
const wtUtil = {
  isBlank: (str) => {
    return str == null || /^\s*$/.test(str)
  },
  isNotBlank: (str) => {
    return !wtUtil.isBlank(str)
  },
  debounce: (func, delay) => {
    let timer
    return function(...args) {
      if (timer) {
        clearTimeout(timer)
      }
      timer = setTimeout(() => {
        func.apply(this, args)
      }, delay)
    }
  },
  uuid: () => {
    return (S4() + S4() + '-' + S4() + '-' + S4() + '-' + S4() + '-' + S4() + S4() + S4())
  },
  covertByteToString: (limit) => {
    let size = ''
    if (limit < 1 * 1024) {
      size = limit.toFixed(2) + 'B'
    } else if (limit < 1 * 1024 * 1024) {
      //  如果小于0.1MB转化成KB
      size = (limit / 1024).toFixed(2) + 'KB'
    } else if (limit < 1 * 1024 * 1024 * 1024) {
      // 如果小于0.1GB转化成MB
      size = (limit / (1024 * 1024)).toFixed(2) + 'MB'
    } else {
      // 其他转化成GB
      size = (limit / (1024 * 1024 * 1024)).toFixed(2) + 'GB'
    }
    var sizestr = size + ''
    var len = sizestr.indexOf('.')
    var dec = sizestr.substr(len + 1, 2)
    if (dec === '00') {
      //  当小数点后为00时 去掉小数部分
      return sizestr.substring(0, len) + sizestr.substr(len + 3, 2)
    }
    return sizestr
  },
  getFileMd5(file, callback) {
    const blobSlice = File.prototype.slice || File.prototype.mozSlice || File.prototype.webkitSlice
    const chunkSize = 1024 * 1024 * 512
    const chunks = Math.ceil(file.size / chunkSize)
    let currentChunk = 0
    const spark = new SparkMD5.ArrayBuffer()
    const fileReader = new FileReader()

    fileReader.onload = function(e) {
      spark.append(e.target.result)
      currentChunk++
      if (currentChunk < chunks) {
        loadNext()
      } else {
        const fileMd5 = spark.end()
        file.md5Code = 'SUCCESS'
        file.md5 = fileMd5
        callback(file)
      }
    }

    fileReader.onerror = function(err) {
      file.md5Code = 'FAIL'
      file.md5Error = err
      callback(file)
    }

    function loadNext() {
      const start = currentChunk * chunkSize
      const end = ((start + chunkSize) >= file.size) ? file.size : start + chunkSize
      fileReader.readAsArrayBuffer(blobSlice.call(file, start, end))
    }
    loadNext()
  },
  getFileListMd5(files) {
    const _this = this
    const md5PromiseArray = []
    for (let i = 0; i < files.length; i++) {
      md5PromiseArray.push(new Promise((resolve, reject) => {
        _this.getFileMd5(files[i], (resp) => {
          resolve(resp)
        })
      }))
    }
    return Promise.all(md5PromiseArray)
  },
  toFormData(form) {
    form.formData = {}
    form.formInputsData.forEach((item) => {
      form.formData[item.key] = item.value
    })
    return {
      formData: form.formData,
      pageData: form.pageData
    }
  },
  validateTargetForm(targetForm) {
    console.log('validateTargetForm', targetForm)
    return new Promise((resolve, reject) => {
      if (targetForm && targetForm.validate) {
        targetForm.validate((valid) => {
          resolve(valid)
        })
      } else {
        resolve(true)
      }
    })
  },
  validateDetailForms(detailForm, ...names) {
    const detailRefs = detailForm.$refs
    if (detailRefs) {
      const allValidator = []
      Object.keys(detailRefs).forEach((key, index) => {
        if (detailRefs[key].$refs) {
          const validateForm = detailRefs[key]
          if (validateForm && validateForm.validate && (validateForm.$attrs.validate === null || validateForm.$attrs.validate === undefined || validateForm.$attrs.validate === '' || validateForm.$attrs.validate)) {
            const promise = new Promise((resolve, reject) => {
              validateForm.validate((valid) => {
                resolve(valid)
              })
            }).then(result => result)
            allValidator.push(promise)
          }
        }
      })
      return new Promise((resolve, reject) => {
        let result = true
        Promise.all(allValidator).then((data) => {
          if (data && data.length > 0) {
            data.forEach((item) => {
              if (!item) {
                result = false
                return 0
              }
            })
          }
          resolve(result)
        }).catch(() => {
          result = false
          resolve(result)
        })
      })
    }
  },
  validateDetailFormsAndConfirm(detailForm, text, ...names) {
    const that = this
    return new Promise((resolve, reject) => {
      that.validateDetailForms(detailForm, names).then((valid) => {
        if (valid) {
          detailForm.$confirm(text, '提示', { confirmButtonText: '确定', cancelButtonText: '取消', type: 'info' }).then(() => {
            resolve()
          }).catch((e) => {
            reject(e)
          })
        }
      })
    })
  },
  validateConfirm(context, text, ...names) {
    const that = this
    return new Promise((resolve, reject) => {
      that.validateDetailForms(context, names).then((valid) => {
        if (valid) {
          context.$confirm(text, '提示', { confirmButtonText: '确定', cancelButtonText: '取消', type: 'info' }).then(() => {
            resolve()
          }).catch((e) => {
          })
        }
      })
    })
  },
  buildImageUrl(url) {
    if (this.isBlank(url)) {
      return ''
    }
    if (url.indexOf('http') === 0) {
      return url
    }
    return window.wtConst.FILE_SERVER_URL + url
  },
  calculationFileMd5(file) {
    const that = this
    return new Promise((resolve, reject) => {
      try {
        that.getFileMd5(file, (md5File) => {
          if (md5File.md5Code === 'SUCCESS') {
            resolve(file)
          } else {
            reject(new Error('文件检查失败'))
          }
        })
      } catch (e) {
        reject(new Error(e))
      }
    })
  },
  getTimeDurationTip(seconds, dateFormat = 'H:i:s') {
    const obj = {}
    obj.D = Number.parseInt(seconds / 3600 / 24)
    obj.H = Number.parseInt(seconds / 3600)
    obj.i = Number.parseInt((seconds - obj.H * 3600) / 60)
    obj.s = Number.parseInt(seconds - obj.H * 3600 - obj.i * 60)
    if (obj.H < 10) {
      obj.H = '0' + obj.H
    }
    if (obj.i < 10) {
      obj.i = '0' + obj.i
    }
    if (obj.s < 10) {
      obj.s = '0' + obj.s
    }
    const rs = dateFormat.replace('D', obj.D).replace('H', obj.H).replace('i', obj.i).replace('s', obj.s)
    return rs
  },
  millisecond2Duration(milliseconds, dateFormat = 'D天H时i分s秒') {
    const seconds = parseInt(milliseconds / 1000)
    const obj = {}
    obj.D = Number.parseInt(seconds / 3600 / 24)
    obj.H = Number.parseInt(seconds / 3600)
    obj.i = Number.parseInt((seconds - obj.H * 3600) / 60)
    obj.s = Number.parseInt(seconds - obj.H * 3600 - obj.i * 60)
    if (obj.H < 10) {
      obj.H = '0' + obj.H
    }
    if (obj.i < 10) {
      obj.i = '0' + obj.i
    }
    if (obj.s < 10) {
      obj.s = '0' + obj.s
    }
    const rs = dateFormat.replace('D', obj.D).replace('H', obj.H).replace('i', obj.i).replace('s', obj.s)
    return rs
  },
  html2canvas(ref) {
    return html2canvas(ref)
  },
  base64ToBlob(imgData) {
    var params = imgData.split(';base64,')
    var contentType = params[0].split(':')[1]
    var raw = window.atob(params[1])
    // var raw = window.atob(imgData);
    var rawLength = raw.length
    var uInt8Array = new Uint8Array(rawLength)
    for (var i = 0; i < rawLength; ++i) {
      uInt8Array[i] = raw.charCodeAt(i)
    }
    return new Blob([uInt8Array], {
      type: contentType
    })
  },
  toggleFullscreen() {
    if (this.isFullScreen()) {
      this.exitFullscreen()
    } else {
      this.fullscreen()
    }
  },
  isFullScreen() {
    return (
      document.fullscreen ||
      document.mozFullScreen ||
      document.webkitIsFullScreen ||
      document.webkitFullScreen ||
      document.msFullScreen
    )
  },
  fullscreen() {
    const element = document.body
    const requestMethod = element.requestFullScreen ||
      element.webkitRequestFullScreen || // 谷歌
      element.mozRequestFullScreen || // 火狐
      element.msRequestFullScreen // IE11
    if (requestMethod) {
      requestMethod.call(element) // 执行这个请求的方法
    } else if (typeof window.ActiveXObject !== 'undefined') { // window.ActiveXObject判断是否支持ActiveX控件
      // 这里其实就是模拟了按下键盘的F11，使浏览器全屏
      // eslint-disable-next-line no-undef
      const wscript = new ActiveXObject('WScript.Shell') // 创建ActiveX
      if (wscript !== null) { // 创建成功
        wscript.SendKeys('{F11}')// 触发f11
      }
    }
  },
  exitFullscreen() {
    if (document.exitFullScreen) {
      document.exitFullScreen()
    } else if (document.mozCancelFullScreen) {
      document.mozCancelFullScreen()
    } else if (document.webkitExitFullscreen) {
      document.webkitExitFullscreen()
    } else if (document.msExitFullscreen) {
      document.msExitFullscreen()
    }
  },
  downloadHtmlDomCanvas(ref, fileName) {
    const _this = this
    return new Promise((resolve, reject) => {
      html2canvas(ref).then(resp => {
        const imageData = resp.toDataURL('image/png')
        const aLink = document.createElement('a')
        const blob = _this.base64ToBlob(imageData)
        const evt = document.createEvent('HTMLEvents')
        evt.initEvent('click', true, true)
        aLink.download = fileName
        aLink.href = URL.createObjectURL(blob)
        aLink.click()
        resolve()
      }).catch((err) => {
        reject(new Error(err.message))
      })
    })
  },
  downloadFile({ url, fileName, data, method = 'post' }) {
    return new Promise((resolve, reject) => {
      wtDownloadRequest({
        url: url,
        method: method,
        data: data,
        responseType: 'blob',
        contentType: 'application/json'
      }).then(response => {
        if (response.data.type === 'application/json') {
          reject(new Error('下载失败'))
          return
        }
        const blob = new Blob([response.data])
        const contentDisposition = response.headers['content-disposition']
        const filenameFlag = 'filename='
        let tempFileName = ''
        if (fileName) {
          //  优先使用本地指定的文件名
          tempFileName = fileName
        } else if (contentDisposition.indexOf(filenameFlag) !== -1) {
          //  服务器传来的文件名
          const sourceFileName = contentDisposition.substring(contentDisposition.lastIndexOf(filenameFlag) + filenameFlag.length)
          tempFileName = decodeURI(sourceFileName)
        } else if (!fileName) {
          //  生成日期文件名
          tempFileName = Date.parse(new Date()) + ''
        }
        if (window.navigator.msSaveOrOpenBlob) {
          navigator.msSaveBlob(blob, tempFileName)
        } else {
          var link = document.createElement('a')
          link.href = window.URL.createObjectURL(blob)
          link.download = tempFileName
          link.click()
          window.URL.revokeObjectURL(link.href)
        }
        resolve()
      }).catch(error => {
        reject(error)
      })
    })
  }
}

export default wtUtil

