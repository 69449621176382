<template>
  <div class="page-container">
    <div class="sidebar">
      <div class="search">
        <el-input
          placeholder="输入关键字"
          v-model.trim="searchKey"
          suffix-icon="el-icon-search">
        </el-input>
      </div>
      <div class="menu">
        <el-tree
          ref="materialTree"
          class="material-tree"
          node-key="id"
          :empty-text="emptyText"
          :expand-on-click-node="false"
          :data="treeList"
          default-expand-all
          :default-expanded-keys="defaultExpanedKeys"
          :current-node-key="currentNodeKey"
          :filter-node-method="filterNode"
          @node-click="handleNodeClick"
          @node-expand="handleNodeExpand"
        >
          <span class="custom-tree-node" slot-scope="{node, data}">
            <span class="txt" v-if="!data.isAddNode && !data.isEditing">{{ node.label }}</span>
            <template>
              <template v-if="!data.isAddNode && !data.isEditing">
              <span v-if="data.layer > 1" @click.stop="editChildNode(node, data)" class="icon el-icon-edit"></span>
              <span v-if="data.layer > 1" @click.stop="handleDeleteNode(node, data)"  class="icon el-icon-remove-outline"></span>
              <span v-if="data.layer < 3" @click.stop="addAllNode(node, data)" class="icon el-icon-circle-plus-outline"></span>
              </template>
              <!-- 新增 -->
              <el-input
                size="mini"
                :ref="`add${data.id}`"
                class="add-new-child-node"
                v-show="data.isAddNode"
                v-model="newChildNode"
                maxlength="80"
                @keyup.enter.stop.native="handleEnter"
                @blur="handleAddNode(node, data)"
                placeholder="请输入"
              ></el-input>
              <!-- 编辑 -->
              <el-input
                size="mini"
                :ref="`edit${data.id}`"
                class="edit-child-node"
                v-show="data.isEditing"
                v-model="data.label"
                maxlength="80"
                @keyup.enter.stop.native="handleEnter"
                @blur="handleEditNode(node, data)"
                placeholder="请输入"
              ></el-input>
            </template>
          </span>
        </el-tree>
      </div>
    </div>
    <div class="main">
    <div class="toolbar">
      <span class="tit">查验标准</span>
      <wt-button @click="onClickAdd" v-has-any-premission="['BZGLINFO:ADD']"><i class="el-icon-plus"></i>新建标准</wt-button>
    </div>
    <wt-data-form :key="formKey" class="wt-table-full" ref="dataForm" :form.sync="form" @on-request-list="selfQuestList"
                  request-list-url="/tCheckInfo/list"
                  request-delete-url="/tCheckInfo/delete"
                  request-detail-url="/auth/bzglDetail">
      <template slot="table">
        <el-table-column type="index" label="序号"></el-table-column>
        <el-table-column prop="content" label="角色名称">
          <template slot-scope="scope">
            <span v-show="!scope.row.isEdit && !scope.row.isAdd">{{scope.row.content}}</span>
            <el-input
              size="mini"
              :ref="`bzgl${scope.row.id}`"
              class="edit-child-node"
              v-show="scope.row.isEdit || scope.row.isAdd"
              v-model="scope.row.content"
              maxlength="80"
              placeholder="请输入"
            ></el-input>
          </template>
        </el-table-column>
        <el-table-column label="操作" fixed="right">
          <template slot-scope="scope">
            <wt-text-button v-if="scope.row.isEdit || scope.row.isAdd" v-has-any-premission="['BZGLINFO:VIEW']" @click="onClickSave(scope.row)">保存</wt-text-button>
            <wt-text-button v-if="!scope.row.isEdit && !scope.row.isAdd" v-has-any-premission="['BZGLINFO:UPDATE']" @click="onClickEdit(scope.row)">编辑</wt-text-button>
            <wt-text-button v-if="!scope.row.isEdit && !scope.row.isAdd" v-has-any-premission="['BZGLINFO:DELETE']" @click="onClickDelete(scope.row)" class="danger">删除</wt-text-button>
          </template>
        </el-table-column>
      </template>
    </wt-data-form>
    </div>
    <!--弹层 开始-->
     <!-- <wt-page-dialog ref="detailDialog" title="角色详情">
       <bzgl-detail :view-type="form.detailViewType" :detail-data="form.detailData"
                  @on-refresh="$refs.dataForm.requestDataList()" @on-close="$refs.detailDialog.close()"></bzgl-detail>
    </wt-page-dialog> -->
    <!--弹层 结束-->
  </div>
</template>

<script>
import { mapState } from 'vuex'

export default {
  name: 'bzglInfo',
  data() {
    return {
      form: {
        isFromLoading: false,
        detailViewType: 'edit',
        detailData: {},
        extFormData: {},
        formData: {
          name: '',
          updateTimeRange: []
        },
        pageData: { currentPage: 1, pageSize: 20, sortName: '', sortOrder: '' },
        formResult: {},
        noPagination: true
      },
      searchKey: '',
      defaultCheckedKeys: [],
      checkedModuleCodes: [],
      treeList: [],
      emptyText: '请输入',
      defaultExpanedKeys: [],
      currentNodeKey: '',
      showAdd: true,
      showEdit: true,
      showDelete: true,
      newChildNode: '',
      placeNode: {}, // 新增节点占位节点
      currentEditLabel: '',
      focusStatus: true,
      parentData: {},
      curNode: {},
      formKey: 1,
      selectNode: ''
    }
  },
  watch: {
    searchKey(val) {
      this.searchBzglTree(val)
      //this.$refs.materialTree.filter(val)
    }
  },
  mounted() {
    this.$refs.dataForm.requestDataList()
    this.getBzglTree()
  },
  created() {
  },
  methods: {
    clearSearch() {
      this.searchKey = ''
    },
    // 节点过滤
    filterNode(value, data) {
      if (!value) return true
      if (this.showChildren) {
        if (this.filteredNode.indexOf(data.parent_id) !== -1 || data.name.indexOf(value) !== -1) {
          this.filteredNode.push(data.id)
        }
        return this.filteredNode.indexOf(data.parent_id) !== -1 || data.name.indexOf(value) !== -1
      }
      return data.name.indexOf(value) !== -1
    },
    NumRange(index, pageIndex) {
      return (index >= (pageIndex - 1) * this.form.formResult.pageSize) && (index < pageIndex * this.form.formResult.pageSize)
    },
    selfQuestList() {
      this.form.formResult.pageSize = 20
      this.form.formResult.currentPage = this.form.formResult.currentPage ? this.form.formResult.currentPage : 1
      if (this.selectNode && this.selectNode.id) {
        // this.form.formResult.list = [...this.selectNode.checks].filter((item, index) => {
        //   return this.NumRange(index, this.form.formResult.currentPage)
        // })
        this.form.formResult.list = [...this.selectNode.checks]
        this.form.formResult.total = this.form.formResult.list.length
      } else {
        this.form.formResult.list = []
      }
      this.formKey += 1
    },
    getBzglTree() {
      const that = this
      that.$wtRequest({
        url: '/tCheckTypeInfo/queryTree',
        method: 'post',
        data: {}
      }).then((resp) => {
        const list = resp.data.list
        if (resp.code === '0') {
          that.initTreeList(list, 1)
          that.treeList = list
        } else {
        }
      }).catch((err) => {
        console.log(err)
      })
    },
    searchBzglTree(name) {
      const that = this
      that.$wtRequest({
        url: '/tCheckTypeInfo/searchTree',
        method: 'post',
        data: {
          name: name
        }
      }).then((resp) => {
        const list = resp.data.list
        if (resp.code === '0') {
          that.initTreeList(list, 1)
          that.treeList = list
        } else {
        }
      }).catch((err) => {
        console.log(err)
      })
    },
    initTreeList(list, i) {
      list.map((x) => {
        x.label = x.name
        x.children = x.sons
        x.layer = i
        if (x.sons && x.sons.length > 0) {
          this.initTreeList(x.sons, i + 1)
        }
        return x
      })
      console.log('rr', list)
    },
    // 新增节点
    addAllNode(node, data) {
      if (data) {
        console.log(11)
        this.placeNode = {
          id: 0,
          label: '',
          isAddNode: true
        }
        if (!data.children) {
          this.$set(data, 'children', [])
        }
        data.children.push(this.placeNode)
        console.log(this.$refs.materialTree.store)
        this.$refs.materialTree.store.nodesMap[data.id].expanded = true
        // this.$set(data, 'isAddNode', true)
        const refs = `add${0}`
        // console.log('refs', refs, this.$refs[refs])
        setTimeout(() => {
          this.curNode = data
          this.$refs[refs].focus()
        }, 10)
      } else {
        console.log(22)
        this.placeNode = {
          id: 0,
          label: '',
          layer: 0,
          isAddNode: true
        }
        this.treeList.push(this.placeNode)
        const refs = `add${this.placeNode.id}`
        setTimeout(() => {
          this.$refs[refs].focus()
        }, 10)
      }
    },
    editChildNode(node, data) {
      if (this.customEdit && typeof this.customEdit === 'function') {
        this.customEdit(node, data)
      } else {
        this.$set(data, 'isEditing', true)
        this.currentEditLabel = data.label
        const refs = `edit${data.id}`
        setTimeout(() => {
          this.$refs[refs].focus()
        }, 10)
      }
    },
    getData(node) {
      if (node == null) {
        return false
      }
      let data
      let index = 0
      if (index < node.level - 1) {
        index += 1
        data = this.getData(node.parent)
        // return
      } else {
        data = node.data
        // return node.data;
      }
      return data
    },
    handleDeleteNode(node, data) {
      const that = this
      this.parentData = this.getData(node)
      this.$confirm('是否删除?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        that.$wtRequest({
          url: '/tCheckTypeInfo/delete',
          method: 'post',
          data: {
            list: [data.id]
          }
        }).then((resp) => {
          if (resp.code === '0') {
            this.$refs.materialTree.remove(data)
            this.$message.success('删除成功')
          } else {
            this.$message.error('删除失败')
          }
        }).catch(() => {
          this.$message.error('删除失败')
        })
      }).catch(() => {
        this.$message({
          type: 'info',
          message: '已取消删除'
        })
      })
    },
    handleEnter(event) {
      event.target.blur()
    },
    handleNodeClick(data) {
      console.log('selectNode', data)
      this.selectNode = data
      this.selfQuestList()
    },
    handleNodeExpand(data) {
      console.log('expandNode', data)
    },
    handleAddNode(node, data) {
      const that = this
      this.parentData = this.getData(node)
      console.log('add=', node, this.parentData)
      if (this.newChildNode === '') {
        this.$set(data, 'isAddNode', false)
        // 失去焦点时，删除占位节点
        this.$refs.materialTree.remove(this.placeNode)
        return
      }
      that.$wtRequest({
        url: '/tCheckTypeInfo/add',
        method: 'post',
        data: {
          id: 0,
          name: this.newChildNode,
          pid: this.curNode.id
        }
      }).then((resp) => {
        console.log('add', resp)
        if (resp.code === '0') {
          const layer = this.curNode.layer + 1
          const newChildNode = {
            id: resp.data,
            parent_id: this.curNode.id,
            label: this.newChildNode,
            layer
          }
          if (this.curNode.children) {
            this.curNode.children.push(newChildNode)
          } else {
            this.treeList.push(newChildNode)
          }
          this.$message.success('新增成功')
        } else {
          this.$message.error('新增失败')
        }
        this.$refs.materialTree.remove(this.placeNode)
        this.$set(data, 'isAddNode', false)
        this.newChildNode = ''
      }).catch(() => {
        this.newChildNode = ''
        this.$message.error('新增失败')
      })
    },
    handleEditNode(node, data) {
      const that = this
      if (data.label === this.currentEditLabel || data.label === '') {
        this.$set(
          data,
          'label',
          JSON.parse(JSON.stringify(this.currentEditLabel)),
        )
        this.$set(data, 'isEditing', false)
        return false
      }
      that.$wtRequest({
        url: '/tCheckTypeInfo/update',
        method: 'post',
        data: {
          id: data.id,
          name: data.label,
          pid: this.curNode.id
        }
      }).then((resp) => {
        if (resp.code === '0') {
          this.$message.success('修改成功')
        } else {
          this.$message.error('修改失败')
          this.$set(data, 'label', this.currentEditLabel)
        }
      }).catch(() => {
        this.$message.error('修改失败')
        this.$set(data, 'label', this.currentEditLabel)
      })
      this.$set(data, 'isEditing', false)
    },
    onModuleCurrentChanged(nodeData, node) {
      console.log(nodeData, node)
      this.form.formData.checkedModuleCodes = node.checkedKeys
      this.$nextTick(() => {
        this.$refs.dataForm.onClickSearch()
      })
    },
    // filterNode(value, data) {
    //  if (!value) return true
    //  return data.label.indexOf(value) !== -1
    // },
    checkListStatus() {
      return this.form.formResult.list.filter(x => x.isAdd || x.isEdit).length
    },
    onClickSave(rowData) {
      // this.$set(rowData, 'isEdit', false)
      if (rowData.content === '') {
        this.$message.error('标准内容不能为空')
        return false
      }
      if (rowData.isAdd) {
        this.$wtRequest({
          url: '/tCheckInfo/add',
          method: 'post',
          data: {
            id: 0,
            content: rowData.content,
            checkTypeId: this.selectNode.id
          }
        }).then((resp) => {
          if (resp.code === '0') {
            this.$message.success('新增成功')
            rowData.id = resp.data
            this.$set(rowData, 'isAdd', false)
          } else {
            this.$message.error('新增失败')
            this.form.formResult.list = this.form.formResult.list.filter(x => x.id !== rowData.id)
          }
          this.formKey += 1
          this.getBzglTree()
        }).catch(() => {
          this.$message.error('新增失败')
          this.form.formResult.list = this.form.formResult.list.filter(x => x.id !== rowData.id)
          this.formKey += 1
        })
      } else if (rowData.isEdit) {
        this.$wtRequest({
          url: '/tCheckInfo/update',
          method: 'post',
          data: {
            id: rowData.id,
            content: rowData.content,
            checkTypeId: this.selectNode.id
          }
        }).then((resp) => {
          if (resp.code === '0') {
            this.$message.success('修改成功')
          } else {
            rowData.content = rowData.dContent
            this.$message.error('修改失败')
          }
          this.$set(rowData, 'isEdit', false)
          this.formKey += 1
          this.getBzglTree()
        }).catch(() => {
          this.$message.error('修改失败')
          rowData.content = rowData.dContent
          this.$set(rowData, 'isEdit', false)
          this.formKey += 1
        })
      }
    },
    onClickEdit(rowData) {
      if (this.checkListStatus() > 0) {
        this.$message.error('请先保存')
        return false
      }
      this.$set(rowData, 'isEdit', true)
      this.$set(rowData, 'dContent', rowData.content)
      setTimeout(() => {
        this.$refs[`bzgl${rowData.id}`].focus()
      }, 10)
      // rowData.isEdit = true
      // this.form.detailViewType = 'edit'
      // this.form.detailData = rowData
      // this.$refs.detailDialog.open()
    },
    onClickAdd() {
      if (this.checkListStatus() > 0) {
        this.$message.error('请先选择保存')
        return false
      }
      if (!this.selectNode || !this.selectNode.id) {
        this.$message.error('请先选择目录')
        return
      }
      const id = new Date().getTime()
      this.form.formResult.list.unshift({
        id,
        content: '',
        isAdd: true
      })
      this.formKey += 1
      setTimeout(() => {
        this.$refs[`bzgl${id}`].focus()
      }, 10)
    },
    onClickDelete(rowData) {
      this.$confirm('是否删除?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        this.$wtRequest({
          url: '/tCheckInfo/delete',
          method: 'post',
          data: {
            list: [rowData.id]
          }
        }).then((resp) => {
          if (resp.code === '0') {
            this.form.formResult.list = this.form.formResult.list.filter(x => x.id !== rowData.id)
            this.formKey += 1
            this.getBzglTree()
            this.$message.success('删除成功')
          } else {
            this.$message.error('删除失败')
          }
        }).catch(() => {
          this.$message.error('删除失败')
        })
      }).catch(() => {
        this.$message({
          type: 'info',
          message: '已取消删除'
        })
      })
    },
    // 删除角色
    requestRoleDelete(rowData) {
      this.$wtRequest({
        url: '/auth/roleDelete',
        method: 'post',
        data: {
          list: [rowData.id]
        }
      }).then(res => {
        if (res.isSuccess) {
          this.$message({ type: 'success', message: '删除成功' })
          this.$refs.dataForm.requestDataList()
        }
      })
    }
  },
  computed: {
    ...mapState({})
  }
}
</script>
<style scoped lang="scss">
  .page-container {
    display: flex;
    height: calc(100vh - 150px);
    .sidebar {
      width: 360px;
      flex-basis: 360px;
      margin: -20px 20px -20px 0;
      padding: 20px 20px 20px 0;
      border-right: #f6f8f9 10px solid;
      overflow-y: auto;
      .search {
        margin-bottom: 15px;
      }
    }
    .main {
      flex: 1;
      flex-direction: column;
      .toolbar {
        display: flex;
        justify-content: space-between;
        margin-bottom: 10px;
        flex-basis: 32px;
        .tit {
          line-height: 32px;
        }
      }
      .wt-table-full {
        flex: 1;
        height: calc(100% - 42px);
        overflow-y: auto;
      }
    }
  }
  .custom-tree-node {
  line-height: 32px;
  height:32px;
  flex: 1;
  padding-right: 28px;
  box-sizing: border-box;
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  text-overflow: ellipsis;
  white-space: nowrap;
  position: relative;
  .txt {
    margin-right: 10px;
  }
  .icon {
    margin-left: 5px;
  }
}
.add-new-child-node {
  position: absolute;
  width: 100%;
  left: 0;
  top: 0px;
  display: flex;
  align-items: top;
}
/*.material-tree /deep/ .el-tree-node {
   position
}*/
</style>
