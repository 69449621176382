<template>
  <wt-page>
    <div class="wt-page-container" v-loading="isFromLoading">
      <el-form :model="detailForm" ref="form" class="wt-page-form" label-width="120px" size="small">
        <!-- <el-form-item class="wt-detail-form-item" label="用户名" prop="username"
                      :rules="[ { required: true, message: '输入内容不能为空', validator: $wtValidator.ValidatorNotBlank, trigger: 'blur' },
                       { pattern: /^[a-zA-Z][\.a-zA-Z0-9_-]{1,30}$/, message: '请填写以英文开头，2-30位的英文或数字或下划线', trigger: 'blur' }]">
          <el-input placeholder="请输入" v-model="detailForm.username" :disabled="viewType != 'add'" maxlength="30"></el-input>
        </el-form-item> -->
        <el-form-item class="wt-detail-form-item" label="公司ID" prop="code"
                      :rules="[ { required: true, message: '输入内容不能为空', validator: $wtValidator.ValidatorNotBlank, trigger: 'blur' } ]">
          <el-input placeholder="请输入" v-model="detailForm.code" :disabled="viewType == 'view'" maxlength="100"></el-input>
        </el-form-item>
        <el-form-item class="wt-detail-form-item" label="公司名称" prop="name"
                      :rules="[ { required: true, message: '输入内容不能为空', validator: $wtValidator.ValidatorNotBlank, trigger: 'blur' } ]">
          <el-input placeholder="请输入" v-model="detailForm.name" :disabled="viewType == 'view'" maxlength="100"></el-input>
        </el-form-item>
        <el-form-item class="wt-detail-form-item" label="所属区域" prop="areaList">
          <el-select placeholder="请选择区域" v-model="detailForm.area">
            <el-option v-for="item in areaList" :label="item.label" :value="item.value" :key="item.value"></el-option>
          </el-select>
        </el-form-item>
      </el-form>
    </div>
    <div slot="footer">
      <wt-button type="default" @click="onClickClose">关闭</wt-button>
      <wt-button type="primary" v-if="viewType == 'add'" v-has-any-premission="['SYSUSER:ADD']" :loading="isFromLoading" @click="onClickAdd">保存</wt-button>
      <wt-button type="primary" v-if="viewType == 'edit'" v-has-any-premission="['SYSUSER:UPDATE']" :loading="isFromLoading" @click="onClickEdit">保存</wt-button>
    </div>
  </wt-page>
</template>

<script>
import { mapState } from 'vuex'
// import orgRolePicker from './orgRolePicker'
export default {
  name: 'orgDetail',
  isAutoComponent: true,
  components: {
    // orgRolePicker
  },
  props: ['detailData', 'viewType'],
  data() {
    const onValidatorPassword = (rule, value, callback) => {
      if (this.$wtUtil.isNotBlank(this.detailForm.newPassword) && this.detailForm.newPassword !== this.detailForm.confirmPassword) {
        callback(new Error())
      } else {
        callback()
      }
    }
    return {
      onValidatorPassword: onValidatorPassword,
      roleList: [],
      areaList: [
        {
          'value': '北京区域', 'label': '北京区域'
        },
        {
          'value': '新疆区域', 'label': '新疆区域'
        },
        {
          'value': '广东区域', 'label': '广东区域'
        },
        {
          'value': '辽宁区域', 'label': '辽宁区域'
        },
        {
          'value': '江苏区域', 'label': '江苏区域'
        },
        {
          'value': '山东区域', 'label': '山东区域'
        },
        {
          'value': '上海区域', 'label': '上海区域'
        },
        {
          'value': '湖北区域', 'label': '湖北区域'
        },
        {
          'value': '四川区域', 'label': '四川区域'
        },
        {
          'value': '广西区域', 'label': '广西区域'
        },
        {
          'value': '河北区域', 'label': '河北区域'
        },
        {
          'value': '解放军总医院', 'label': '解放军总医院'
        }
      ],
      isFromLoading: false,
      detailForm: {
        name: '',
        code: '',
        area: ''
      }
    }
  },
  mounted() {
    if (this.viewType === 'add') {
      this.detailForm.name = ''
      this.detailForm.code = ''
      this.detailForm.area = ''
    } else {
      this.detailForm.name = this.detailData.name
      this.detailForm.code = this.detailData.code
      this.detailForm.area = this.detailData.area
    }
  },
  methods: {
    onClickAdd() {
      const that = this
      this.$wtUtil.validateDetailFormsAndConfirm(this, '是否保存?').then(() => {
        that.requestAdd()
      }).catch(() => {
      })
    },
    onClickEdit() {
      const that = this
      this.$wtUtil.validateDetailFormsAndConfirm(this, '是否保存?').then(() => {
        that.requestSave()
      }).catch(() => {
      })
    },
    onClickClose() {
      this.$emit('on-close')
    },
    onOrgRoleConfirm({ orgId, roleId, orgRoleName }) {
      this.detailForm.orgRoleList.push({ orgId, roleId, orgRoleName })
    },
    // 获取角色
    requestRoleList() {
      this.$wtRequest({
        url: '/auth/roleListAll',
        method: 'post',
        data: { search: '' }
      }).then(res => {
        this.roleList = res.data.list
      })
    },
    // 查看详情
    requestDetail() {
      const that = this
      this.$wtRequest({
        url: '/auth/userDetail',
        method: 'post',
        data: {
          id: that.detailData.id
        }
      }).then(res => {
        this.detailForm = res.data
      })
    },
    requestSave() {
      const that = this
      that.isFromLoading = true
      const formData = { ...that.detailForm }
      formData.id = that.detailData.id
      that.$wtRequest({
        url: '/auth/orgUpdate',
        method: 'post',
        data: formData
      }).then((resp) => {
        that.detailForm = resp.data || {}
        that.isFromLoading = false
        that.$message({ type: 'success', message: '保存成功' })
        that.$emit('on-close')
        that.$emit('on-refresh')
      }).catch(() => {
        that.isFromLoading = false
      })
    },
    requestAdd() {
      const that = this
      that.isFromLoading = true
      const formData = { ...that.detailForm }
      that.$wtRequest({
        url: '/auth/orgAdd',
        method: 'post',
        data: formData
      }).then((resp) => {
        that.detailForm = resp.data || {}
        that.isFromLoading = false
        that.$emit('on-close')
        that.$emit('on-refresh')
      }).catch(() => {
        that.isFromLoading = false
      })
    }
  },
  computed: {
    ...mapState({})
  }
}
</script>
<style scoped lang="scss">
.wt-drawer-container {
  .wt-drawer-body {
    .wt-drawer-body-header-title {
      color: #97d0ff;
      font-size: 16px;
      margin: 32px 33px;
    }
  }
}
</style>
