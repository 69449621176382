<template>
  <wt-page>
    <div class="wt-page-container" v-loading="isFromLoading">
      <div class="page-container">
        <div class="sidebar">
          <div class="search">
            <el-input
              placeholder="输入关键字"
              v-model.trim="searchKey"
              suffix-icon="el-icon-search">
            </el-input>
          </div>
          <div class="menu">
            <el-tree
              ref="materialTree"
              class="material-tree"
              node-key="id"
              :empty-text="emptyText"
              :expand-on-click-node="false"
              :data="treeList"
              default-expand-all
              :default-expanded-keys="defaultExpanedKeys"
              :current-node-key="currentNodeKey"
              :filter-node-method="filterNode"
              @node-click="handleNodeClick"
              @node-expand="handleNodeExpand"
            >
              <span class="custom-tree-node" slot-scope="{node, data}">
                <span class="txt" v-if="!data.isAddNode && !data.isEditing">{{ node.label }}</span>
              </span>
            </el-tree>
          </div>
        </div>
        <div class="main">
        <div class="toolbar">
          <span class="tit">查验标准</span>
          <wt-button @click="onClickAdd" v-has-any-premission="['BZGLINFO:ADD']"><i class="el-icon-plus"></i>新建标准</wt-button>
        </div>
        <wt-data-form :key="formKey" class="wt-table-full" ref="dataForm" :form.sync="form" @on-request-list="selfQuestList"
                      request-list-url="/tCheckInfo/list"
                      request-delete-url="/tCheckInfo/delete"
                      request-detail-url="/auth/bzglDetail">
          <template slot="table">
            <el-table-column type="index" label="序号"></el-table-column>
            <el-table-column prop="content" label="角色名称">
              <template slot-scope="scope">
                <span v-show="!scope.row.isEdit && !scope.row.isAdd">{{scope.row.content}}</span>
                <el-input
                  size="mini"
                  :ref="`bzgl${scope.row.id}`"
                  class="edit-child-node"
                  v-show="scope.row.isEdit || scope.row.isAdd"
                  v-model="scope.row.content"
                  maxlength="80"
                  placeholder="请输入"
                ></el-input>
              </template>
            </el-table-column>
          </template>
        </wt-data-form>
        </div>
      </div>
    </div>
    <div slot="footer">
      <wt-button type="default" @click="onClickClose">关闭</wt-button>
      <wt-button type="primary" v-if="viewType == 'add'" v-has-any-premission="['SYSROLE:ADD']" :loading="isFromLoading" @click="onClickAdd">保存</wt-button>
      <wt-button type="primary" v-if="viewType == 'edit'" v-has-any-premission="['SYSROLE:UPDATE']" :loading="isFromLoading" @click="onClickEdit">保存</wt-button>
    </div>
  </wt-page>
</template>

<script>
import { mapState } from 'vuex'

export default {
  name: 'xmViewCybz',
  isAutoComponent: true,
  props: ['viewType', 'detailData'],
  data() {
    return {
      isFromLoading: false,
      isButtonLoading: false,
      form: {
        isFromLoading: false,
        detailViewType: 'edit',
        detailData: {},
        extFormData: {},
        formData: {
          name: '',
          updateTimeRange: []
        },
        pageData: { currentPage: 1, pageSize: 20, sortName: '', sortOrder: '' },
        formResult: {},
        noPagination: true
      },
      searchKey: '',
      defaultCheckedKeys: [],
      checkedModuleCodes: [],
      treeList: [],
      emptyText: '请输入',
      defaultExpanedKeys: [],
      currentNodeKey: '',
      showAdd: true,
      showEdit: true,
      showDelete: true,
      newChildNode: '',
      placeNode: {}, // 新增节点占位节点
      currentEditLabel: '',
      focusStatus: true,
      parentData: {},
      curNode: {},
      formKey: 1,
      selectNode: ''
    }
  },
  watch: {
    searchKey(val) {
      this.$refs.materialTree.filter(val)
    }
  },
  mounted() {
    this.$refs.dataForm.requestDataList()
    this.getBzglTree()
  },
  created() {
  },
  methods: {
    onClickClose() {
      this.$emit('on-close')
    },
    clearSearch() {
      this.searchKey = ''
    },
    // 节点过滤
    filterNode(value, data) {
      if (!value) return true
      if (this.showChildren) {
        if (this.filteredNode.indexOf(data.parent_id) !== -1 || data.name.indexOf(value) !== -1) {
          this.filteredNode.push(data.id)
        }
        return this.filteredNode.indexOf(data.parent_id) !== -1 || data.name.indexOf(value) !== -1
      }
      return data.name.indexOf(value) !== -1
    },
    NumRange(index, pageIndex) {
      return (index >= (pageIndex - 1) * this.form.formResult.pageSize) && (index < pageIndex * this.form.formResult.pageSize)
    },
    selfQuestList() {
      this.form.formResult.pageSize = 20
      this.form.formResult.currentPage = this.form.formResult.currentPage ? this.form.formResult.currentPage : 1
      if (this.selectNode && this.selectNode.id) {
        // this.form.formResult.list = [...this.selectNode.checks].filter((item, index) => {
        //   return this.NumRange(index, this.form.formResult.currentPage)
        // })
        this.form.formResult.list = [...this.selectNode.checks]
        this.form.formResult.total = this.form.formResult.list.length
      } else {
        this.form.formResult.list = []
      }
      this.formKey += 1
    },
    getBzglTree() {
      const that = this
      that.$wtRequest({
        url: '/tCheckTypeInfo/queryTree',
        method: 'post',
        data: {}
      }).then((resp) => {
        const list = resp.data.list
        if (resp.code === '0') {
          that.initTreeList(list, 1)
          that.treeList = list
        } else {
        }
      }).catch((err) => {
        console.log(err)
      })
    },
    initTreeList(list, i) {
      list.map((x) => {
        x.label = x.name
        x.children = x.sons
        x.layer = i
        if (x.sons && x.sons.length > 0) {
          this.initTreeList(x.sons, i + 1)
        }
        return x
      })
    },
    getData(node) {
      if (node == null) {
        return false
      }
      let data
      let index = 0
      if (index < node.level - 1) {
        index += 1
        data = this.getData(node.parent)
        // return
      } else {
        data = node.data
        // return node.data;
      }
      return data
    },
    handleNodeClick(data) {
      console.log('selectNode', data)
      this.selectNode = data
      this.selfQuestList()
    },
    handleNodeExpand(data) {
      console.log('expandNode', data)
    },
    onModuleCurrentChanged(nodeData, node) {
      console.log(nodeData, node)
      this.form.formData.checkedModuleCodes = node.checkedKeys
      this.$nextTick(() => {
        this.$refs.dataForm.onClickSearch()
      })
    },
    // filterNode(value, data) {
    //  if (!value) return true
    //  return data.label.indexOf(value) !== -1
    // },
    checkListStatus() {
      return this.form.formResult.list.filter(x => x.isAdd || x.isEdit).length
    },
    onClickSave(rowData) {
      // this.$set(rowData, 'isEdit', false)
      if (rowData.content === '') {
        this.$message.error('标准内容不能为空')
        return false
      }
      if (rowData.isAdd) {
        this.$wtRequest({
          url: '/tCheckInfo/add',
          method: 'post',
          data: {
            id: 0,
            content: rowData.content,
            checkTypeId: this.selectNode.id
          }
        }).then((resp) => {
          if (resp.code === '0') {
            this.$message.success('新增成功')
            rowData.id = resp.data
            this.$set(rowData, 'isAdd', false)
          } else {
            this.$message.error('新增失败')
            this.form.formResult.list = this.form.formResult.list.filter(x => x.id !== rowData.id)
          }
          this.formKey += 1
        }).catch(() => {
          this.$message.error('新增失败')
          this.form.formResult.list = this.form.formResult.list.filter(x => x.id !== rowData.id)
          this.formKey += 1
        })
      } else if (rowData.isEdit) {
        this.$wtRequest({
          url: '/tCheckInfo/update',
          method: 'post',
          data: {
            id: rowData.id,
            content: rowData.content,
            checkTypeId: this.selectNode.id
          }
        }).then((resp) => {
          if (resp.code === '0') {
            this.$message.success('修改成功')
          } else {
            rowData.content = rowData.dContent
            this.$message.error('修改失败')
          }
          this.$set(rowData, 'isEdit', false)
          this.formKey += 1
        }).catch(() => {
          this.$message.error('修改失败')
          rowData.content = rowData.dContent
          this.$set(rowData, 'isEdit', false)
          this.formKey += 1
        })
      }
    },
    onClickEdit(rowData) {
      if (this.checkListStatus() > 0) {
        this.$message.error('请先保存')
        return false
      }
      this.$set(rowData, 'isEdit', true)
      this.$set(rowData, 'dContent', rowData.content)
      setTimeout(() => {
        this.$refs[`bzgl${rowData.id}`].focus()
      }, 10)
      // rowData.isEdit = true
      // this.form.detailViewType = 'edit'
      // this.form.detailData = rowData
      // this.$refs.detailDialog.open()
    },
    onClickAdd() {
      if (this.checkListStatus() > 0) {
        this.$message.error('请先选择保存')
        return false
      }
      if (!this.selectNode || !this.selectNode.id) {
        this.$message.error('请先选择目录')
        return
      }
      const id = new Date().getTime()
      this.form.formResult.list.unshift({
        id,
        content: '',
        isAdd: true
      })
      this.formKey += 1
      setTimeout(() => {
        this.$refs[`bzgl${id}`].focus()
      }, 10)
    },
    onClickDelete(rowData) {
      this.$confirm('是否删除?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        this.$wtRequest({
          url: '/tCheckInfo/delete',
          method: 'post',
          data: {
            list: [rowData.id]
          }
        }).then((resp) => {
          if (resp.code === '0') {
            this.form.formResult.list = this.form.formResult.list.filter(x => x.id !== rowData.id)
            this.formKey += 1
            this.$message.success('删除成功')
          } else {
            this.$message.error('删除失败')
          }
        }).catch(() => {
          this.$message.error('删除失败')
        })
      }).catch(() => {
        this.$message({
          type: 'info',
          message: '已取消删除'
        })
      })
    },
    // 删除角色
    requestRoleDelete(rowData) {
      this.$wtRequest({
        url: '/auth/roleDelete',
        method: 'post',
        data: {
          list: [rowData.id]
        }
      }).then(res => {
        if (res.isSuccess) {
          this.$message({ type: 'success', message: '删除成功' })
          this.$refs.dataForm.requestDataList()
        }
      })
    }
  },
  computed: {
    ...mapState({})
  }
}
</script>
<style scoped lang="scss">
  .page-container {
    display: flex;
    .sidebar {
      width: 300px;
      margin: -20px 20px -20px 0;
      padding: 20px 20px 20px 0;
      border-right: #f6f8f9 10px solid;
      .search {
        margin-bottom: 15px;
      }
    }
    .main {
      flex: 1;
      .toolbar {
        display: flex;
        justify-content: space-between;
        margin-bottom: 10px;
        .tit {
          line-height: 32px;
        }
      }
    }
  }
  .custom-tree-node {
  line-height: 32px;
  height:32px;
  flex: 1;
  padding-right: 28px;
  box-sizing: border-box;
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  text-overflow: ellipsis;
  white-space: nowrap;
  position: relative;
  .txt {
    margin-right: 10px;
  }
  .icon {
    margin-left: 5px;
  }
}
.add-new-child-node {
  position: absolute;
  width: 100%;
  left: 0;
  top: 0px;
  display: flex;
  align-items: top;
}
</style>
