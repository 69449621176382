<template>
  <div>
  <wt-page>
    <div class="wt-page-container" v-loading="isFromLoading">
      <wt-data-form class="wt-table-full" ref="dataForm" :form.sync="form" @on-request-data="resetRequestData"
                    request-list-url="/tProjectRangeProblem/list"
                    request-detail-url="/tProjectRangeProblem/detail">
        <template slot="search">
          <wt-search-form-item title="问题编号" v-model="form.formData.problemCode" type="input"/>
          <wt-search-form-item title="查验范围" v-model="form.formData.baseTypeCode" type="input"/>
          <wt-search-form-item title="严重程度" v-model="form.formData.level" type="select" :dataList="chenduOptions"/>
          <wt-search-form-item title="专业" v-model="form.formData.checkId" type="select" :dataList="zhuyeOptions"/>
          <wt-search-form-item title="提交时间" v-model="form.formData.createTimeRange" type="daterange"/>
        </template>
        <template slot="toolbar">
          <div class="toolbar">
            <div class="total">共{{total}}个项目</div>
          </div>
        </template>
        <template slot="table">
          <el-table-column type="index" label="序号"></el-table-column>
          <el-table-column label="问题编号">
            <template slot-scope="scope">
            <wt-text-button @click="onClickView(scope.row)">{{scope.row.problemCode}}</wt-text-button>
            </template>
          </el-table-column>
          <el-table-column label="查验范围">
            <template slot-scope="scope">
            <wt-text-button @click="onClickView(scope.row)">{{scope.row.baseTypeName}}</wt-text-button>
            </template>
          </el-table-column>
          <el-table-column prop="checkName" label="专业"></el-table-column>
          <el-table-column prop="level" label="严重程度"></el-table-column>
          <el-table-column width="200" label="问题描述">
            <template slot-scope="scope">
              <div class="long" :title="scope.row.content">{{scope.row.content}}</div>
            </template>
          </el-table-column>
          <el-table-column label="问题状态">
            <template slot-scope="scope">
              <span>{{scope.row.statusCN}}</span>
            </template>
          </el-table-column>
          <el-table-column prop="createTime" label="新增时间" :sortable='true'></el-table-column>
          <el-table-column label="操作" fixed="right">
            <template slot-scope="scope">
              <wt-text-button v-has-any-premission="['XMGLINFO:VIEW']" @click="onClickView(scope.row)">查看</wt-text-button>
            </template>
          </el-table-column>
        </template>
      </wt-data-form>
    </div>
    <div slot="footer">
      <wt-button type="default" @click="onClickClose">关闭</wt-button>
      <wt-button type="primary" v-if="viewType == 'add'" v-has-any-premission="['SYSROLE:ADD']" :loading="isFromLoading" @click="onClickAdd">保存</wt-button>
      <wt-button type="primary" v-if="viewType == 'edit'" v-has-any-premission="['SYSROLE:UPDATE']" :loading="isFromLoading" @click="onClickEdit">保存</wt-button>
    </div>
  </wt-page>
  <wt-page-dialog ref="problemDetailDialog" title="新增案例">
      <xmgl-problem-detail :view-type="form.detailViewType" :detail-data="form.curData"
                @on-refresh="$refs.dataForm.requestDataList()" @on-close="$refs.problemDetailDialog.close()"></xmgl-problem-detail>
  </wt-page-dialog>
  </div>
</template>

<script>
import { mapState } from 'vuex'

export default {
  name: 'xmglProblem',
  isAutoComponent: true,
  props: ['viewType', 'detailData'],
  data() {
    return {
      isFromLoading: false,
      form: {
        isFromLoading: false,
        detailViewType: 'view',
        curData: {},
        extFormData: {
          projectId: this.detailData.id
        },
        formData: {
          baseTypeCode: '',
          checkId: 0,
          createTimeRange: [],
          level: '',
          problemCode: '',
          projectId: this.detailData.id
        },
        pageData: { currentPage: 1, pageSize: 20, sortName: '', sortOrder: '' },
        formResult: {}
      },
      filterText: '',
      defaultCheckedKeys: [],
      checkedModuleCodes: [],
      moduleTreeData: [],
      firstTime: true,
      zhuyeOptions: [],
      chenduOptions: [
        {
          value: 'A',
          label: 'A'
        },
        {
          value: 'B',
          label: 'B'
        },
        {
          value: 'C',
          label: 'C'
        }
      ],
      scenesOptions: [],
      total: 0,
      editTitle: '新增项目',
      curData: null
    }
  },
  watch: {
    'form.formData.projectId': {
      handler(newVal, oldVal) {
        if (!newVal) {
          this.form.formData.projectId = this.detailData.id
        }
      },
      deep: true,
      immediate: true
    }
  },
  async mounted() {
    // await this.getCompanyList()
    this.getFristLevel()
    this.$refs.dataForm.onClickSearch()
  },
  created() {
  },
  methods: {
    onClickClose() {
      this.$emit('on-close')
    },
    resetRequestData(data) {
      console.log('qqq', data)
      this.total = data.total
    },
    getStatus(type) {
      let status = ''
      switch (type) {
        case 0:
          status = '初始化'
          break
        case 1:
          status = '开启'
          break
        case 2:
          status = '暂停'
          break
        case 3:
          status = '报告管理'
          break
        default:
          status = '--'
      }
      return status
    },
    getCompanyName(id) {
      return this.companyOptions.length > 0 && this.companyOptions.filter(x => x.id === id)[0].name || ''
    },
    getFristLevel() {
      this.$wtRequest({
        url: '/tCheckTypeInfo/queryFristLevelList',
        method: 'post'
      }).then(res => {
        if (res.isSuccess) {
          console.log(res)
          this.zhuyeOptions = res.data.list.map((x) => {
            x.value = x.id
            x.label = x.name
            return x
          })
        }
      })
    },
    getCompanyList() {
      this.$wtRequest({
        url: '/tCompanyInfo/list',
        method: 'post',
        data: {
          formData: {
            createTimeRange: [],
            name: ''
          },
          pageData: {
            currentPage: 1,
            pageSize: 100,
            sortName: '',
            sortOrder: ''
          }
        }
      }).then(res => {
        if (res.isSuccess) {
          this.companyOptions = res.data.list.map((x) => {
            x.label = x.name
            x.value = x.id
            return x
          })
        }
      })
    },
    onClickView(rowData) {
      this.editTitle = '问题详情'
      this.form.detailViewType = 'view'
      this.form.curData = rowData
      this.$refs.problemDetailDialog.open()
    },
    onClickAdd() {
      this.editTitle = '新增项目'
      this.form.detailViewType = 'add'
      this.$refs.editDialog.open()
    },
    onClickImport() {},
    onClickDelete(rowData) {
      this.$refs.dataForm.onClickDelete(rowData.id)
    }
  },
  computed: {
    ...mapState({})
  }
}
</script>
<style lang="scss" scoped>
  .module-permission-content {display: flex;width: 100%;margin-top: 16px; color: #666;}
  .module-permission-content .left-content {width: 250px;padding: 0 24px;}
  .module-permission-content .right-content {flex: 1;}
  .module-menu-content {display: flex;width: 100%;}
  .module-menu-content .left-content {width: 300px;padding: 0 24px;}
  .module-menu-content .right-content {flex: 1;}
  .page-container {
    display: flex;
    .sidebar {
      width: 200px;
      margin: -20px 20px -20px 0;
      padding: 20px 20px 20px 0;
      border-right: #f6f8f9 10px solid;
      .search {
        margin-bottom: 15px;
      }
    }
    .main {
      flex: 1;
    }
  }
  .total {
    color: #666;
    font-size: 12px;
  }
  .toolbar {
    display: flex;
    justify-content: space-between;
    .total {
      line-height: 32px;
    }
  }
  .long {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
</style>
