<template>
<div>
  <wt-page>
    <div class="wt-page-container" v-loading="isFromLoading">
      <div class="page-detail">
        <wt-page-section title="基本概况">
          <div class="module-permission-content">
            <div class="text-list">
              <div class="text-item">
                <div class="title"><span class="red">*</span>案例名称：</div>
                <div class="content">
                  <el-input :disabled="viewType === 'view'" style="width: 500px" maxlength="20" placeholder="请输入" v-model="detailForm.name"></el-input>
                </div>
              </div>
              <div class="text-item">
                <div class="title">作者：</div>
                <div class="content">
                  <el-input :disabled="viewType === 'view'" style="width: 500px" maxlength="20" placeholder="请输入" v-model="detailForm.author"></el-input>
                </div>
              </div>
              <div class="text-item">
                <div class="title">案例简介：</div>
                <div class="content">
                  <wt-tinymce :value="detailForm.intro" :readonly="viewType === 'view'" @input="valueChange"></wt-tinymce>
                </div>
              </div>
              <div class="text-item">
                <div class="title"><span class="red">*</span>列表图片：</div>
                <div class="content">
                  <template v-if="viewType === 'view'">
                  <div class="image-view">
                    <div class="demo-image__preview" v-for="url in detailForm.urls">
                      <el-image
                        style="width: 146px; height: 146px"
                        :src="url"
                        :preview-src-list="detailForm.urls">
                      </el-image>
                    </div>
                  </div>
                  </template>
                  <template v-if="viewType !== 'view'">
                  {{detailForm}}
                  <wt-file-upload ref="wtOssUpload" v-model="detailForm.url"
                            list-type="image" data-type="url" :max="1" :file-limit="1024 * 200" accept="image/*" :tip-props="{fileType: 'jpg,jpeg,png,gif', fileLimit: '200kb', fileSize: ''}"></wt-file-upload>
                  <!-- <wt-file-upload v-model="detailForm.url"
                        list-type="image" data-type="url" :max="1" :file-limit="1024 * 5000" accept="*.doc,*.docx,*.pdf" :tip-props="{fileType: 'doc,docx,pdf', fileLimit: '5MB', fileSize: ''}"></wt-file-upload> -->
                  <!-- <el-upload
                    action="#"
                    list-type="picture-card"
                    :on-change="handleChange"
                    :file-list="fileList"
                    multiple
                    :limit="9"
                    :auto-upload="false">
                      <i slot="default" class="el-icon-plus"></i>
                      <div slot="file" slot-scope="{file}">
                        <img
                          class="el-upload-list__item-thumbnail"
                          :src="file.url" alt=""
                        >
                        <span class="el-upload-list__item-actions">
                          <span
                            v-if="!disabled"
                            class="el-upload-list__item-delete"
                            @click="handleRemove(file)"
                          >
                            <i class="el-icon-delete"></i>
                          </span>
                        </span>
                      </div>
                  </el-upload> -->
                  </template>
                </div>
              </div>
            </div>
          </div>
        </wt-page-section>
      </div>
    </div>
    <div slot="footer">
      <wt-button type="default" @click="onClickClose">关闭</wt-button>
      <wt-button type="primary" v-if="viewType == 'add'" v-has-any-premission="['ALGLINFO:ADD']" :loading="isFromLoading" @click="onClickAdd">保存</wt-button>
      <wt-button type="primary" v-if="viewType == 'edit'" v-has-any-premission="['ALGLINFO:UPDATE']" :loading="isFromLoading" @click="onClickEdit">保存</wt-button>
    </div>
  </wt-page>
</div>
</template>

<script>
import { mapState } from 'vuex'

export default {
  name: 'alglDetail',
  isAutoComponent: true,
  props: ['viewType', 'detailData'],
  data() {
    return {
      wtFileServerUrl: process.env.VUE_APP_FILE_SERVER_URL,
      menuGroupList: [],
      isPermissionItemVisibility: true,
      isMiniMode: true,
      allModuleList: [],
      defaultCheckedKeys: [],
      checkedModuleCodes: [],
      checkedPermissionCodes: [],
      modulePermissionMap: {},
      isButtonLoading: false,
      isFromLoading: false,
      detailForm: {
        id: '',
        name: '',
        author: '',
        file: null,
        intro: '',
        url: '',
        urls: []
      },
      dialogImageUrl: '',
      dialogVisible: false,
      disabled: false,
      fileList: []
    }
  },
  async mounted() {
    this.filelist = []
    if (this.viewType !== 'add') {
      await this.requestDetail()
    }
  },
  created() {},
  methods: {
    valueChange(val) {
      this.detailForm.intro = val
    },
    handleChange(file, fileList) {
      const that = this
      console.log(file, fileList)
      this.fileList = fileList.slice(-9)
      const formData = new FormData()
      /* Object.keys(this.fileList).forEach((key) => {
        formData.append('file', this.fileList[key].raw)
      })*/
      formData.append('file', file.raw)
      this.$wtRequest({
        url: '/file/upload',
        method: 'post',
        data: formData
      }).then(res => {
        console.log('res', file, that.fileList)
        if (res.isSuccess) {
          that.fileList.filter(x => x.uid === file.uid)[0].qUrl = res.data
        } else {
          that.fileList = that.fileList.filter(x => x.uid !== file.uid)
          that.$message({ type: 'error', message: `${file.name}上传失败` })
        }
      })
    },
    handleRemove(file) {
      this.fileList = this.fileList.filter(x => x.uid !== file.uid)
    },
    handlePictureCardPreview(file) {
      this.dialogImageUrl = file.url
      this.dialogVisible = true
    },
    handleDownload(file) {
      console.log(file)
    },
    onClickClose() {
      this.$emit('on-close')
    },
    onClickAdd() {
      const that = this
      this.$wtUtil.validateConfirm(this, '是否保存?').then(() => {
        that.requestAdd()
      })
    },
    onClickEdit() {
      const that = this
      this.$wtUtil.validateConfirm(this, '是否保存?').then(() => {
        that.requestUpdate()
      })
    },
    requestDetail() {
      const that = this
      that.isFromLoading = true
      that.$wtRequest({
        url: '/tCaseInfo/detail',
        method: 'post',
        data: { id: that.detailData.id }
      }).then((resp) => {
        console.log('requestDetail resp', resp)
        that.detailForm = resp.data || {}
        if (that.detailForm.url) {
          that.detailForm.urls = [that.detailForm.url]
        }
        const imgPic = that.detailForm.url.replace(that.wtFileServerUrl, '')
        this.$refs.wtOssUpload.updateFileList(imgPic)
        // let tid = 0
        // that.fileList = that.detailForm.urls.map((x) => {
        //   const nx = {
        //     uid: tid,
        //     qUrl: x,
        //     url: x
        //   }
        //   tid += 1
        //   return nx
        // })
        that.isFromLoading = false
      }).catch((e) => {
        console.log('requestDetail e', e)
        that.isFromLoading = false
      })
    },
    requestUpdate() {
      const that = this
      if (!that.detailForm.name) {
        that.$message({ type: 'error', message: '请输入案例名称' })
        return false
      }
      if (!that.detailForm.url) {
        that.$message({ type: 'error', message: '请上传列表图片' })
        return false
      }
      const picUrl = that.detailForm.url ? `${this.wtFileServerUrl}${that.detailForm.url}` : ''
      const params = {
        id: that.detailForm.id,
        name: that.detailForm.name,
        intro: that.detailForm.intro,
        url: picUrl,
        author: that.detailForm.author
      }
      console.log(params)
      that.isButtonLoading = true
      that.isFromLoading = true
      that
        .$wtRequest({
          url: '/tCaseInfo/update',
          method: 'post',
          data: params
        })
        .then((resp) => {
          that.isButtonLoading = false
          that.isFromLoading = false
          that.$message({ type: 'success', message: '保存成功' })
          that.$emit('on-refresh')
          that.$emit('on-close')
        })
        .catch(() => {
          that.isButtonLoading = false
          that.isFromLoading = false
        })
    },
    requestAdd() {
      const that = this
      if (!that.detailForm.name) {
        that.$message({ type: 'error', message: '请输入案例名称' })
        return false
      }
      if (!that.detailForm.url) {
        that.$message({ type: 'error', message: '请上传列表图片' })
        return false
      }
      const picUrl = that.detailForm.url ? `${this.wtFileServerUrl}${that.detailForm.url}` : ''
      const params = {
        id: 0,
        author: that.detailForm.author,
        name: that.detailForm.name,
        intro: that.detailForm.intro,
        url: picUrl
      }
      that.isButtonLoading = true
      that.isFromLoading = true
      that
        .$wtRequest({
          url: '/tCaseInfo/add',
          method: 'post',
          data: params
        })
        .then((resp) => {
          that.isButtonLoading = false
          that.isFromLoading = false
          that.$message({ type: 'success', message: '保存成功' })
          that.$emit('on-refresh')
          that.$emit('on-close')
        })
        .catch(() => {
          that.isButtonLoading = false
          that.isFromLoading = false
        })
    }
  },
  computed: {
    isReadMode() {
      return this.viewType === 'view'
    },
    ...mapState({
    })
  }
}
</script>
<style lang="scss" scoped>
  .module-permission-content {display: flex;width: 100%;margin-top: 16px; color: #666;}
  .module-permission-content .left-content {width: 250px;padding: 0 24px;}
  .module-permission-content .right-content {flex: 1;}
  .module-menu-content {display: flex;width: 100%;}
  .module-menu-content .left-content {width: 300px;padding: 0 24px;}
  .module-menu-content .right-content {flex: 1;}
  .wt-page-container {
    padding: 0;
    padding-bottom: 30px;
  }
  .text-list {
    .text-item {
      display: flex;
      line-height: 32px;
      padding: 5px 0;
      .title {
        width: 150px;
        text-align: right;
        .red {
          color: red;
        }
      }
      .content {
        flex: 1;
      }
    }
  }
  .image-view {
    display: flex;
    flex-wrap: wrap;
    .demo-image__preview {
      width: 148px;
      height: 148px;
      border: 1px solid #c0ccda;
      margin: 0 8px 8px 0;
      overflow: hidden;
      border-radius: 6px;
    }
  }
</style>
