<template>
  <div class="page-container">
    <div class="sidebar">
      <div class="search">
        <el-input
          placeholder="输入关键字进行过滤"
          v-model="filterText">
        </el-input>
      </div>
      <div class="menu">
        <el-tree
          class="left-content"
          ref="moduleTree"
          :expand-on-click-node="false"
          :data="moduleTreeData"
          node-key="id"
          :default-checked-keys="defaultCheckedKeys"
          :filter-node-method="filterNode"
          @check="onModuleCurrentChanged"
          accordion
          :props="{label: 'name'}"
          default-expand-all
          show-checkbox>
        </el-tree>
      </div>
    </div>
    <div class="main">
    <wt-data-form class="wt-table-full" ref="dataForm" :form.sync="form" @on-request-data="resetRequestData"
                  request-list-url="/tProjectInfo/list"
                  request-delete-url="/tProjectInfo/delete"
                  request-detail-url="/tProjectInfo/detail">
      <template slot="search">
        <wt-search-form-item title="项目ID" v-model="form.formData.code" type="input"/>
        <wt-search-form-item title="项目名称" v-model="form.formData.name" type="input"/>
        <wt-search-form-item title="所属公司" v-model="form.formData.companyIds" type="multi-select" collapse-tags :dataList="companyOptions"/>
        <wt-search-form-item title="项目状态" v-model="form.formData.status" type="select" :dataList="statusOptions"/>
        <wt-search-form-item title="项目业态" v-model="form.formData.scenesCode" type="select" :dataList="scenesOptions"/>
        <wt-search-form-item title="创建日期" v-model="form.formData.createTimeRange" type="daterange"/>
      </template>
      <template slot="toolbar">
        <div class="total">共{{total}}个项目</div>
      </template>
      <template slot="table">
        <el-table-column type="index" label="序号"></el-table-column>
        <el-table-column prop="companyId" label="所属公司">
          <template slot-scope="scope">
            <span>{{getCompanyName(scope.row.companyId)}}</span>
          </template>
        </el-table-column>
        <el-table-column label="项目ID">
          <template slot-scope="scope">
          <wt-text-button @click="onClickView(scope.row)">{{scope.row.code}}</wt-text-button>
          </template>
        </el-table-column>
        <el-table-column label="项目名称">
          <template slot-scope="scope">
          <wt-text-button @click="onClickView(scope.row)">{{scope.row.name}}</wt-text-button>
          </template>
        </el-table-column>
        <el-table-column prop="addr" label="项目地址"></el-table-column>
        <el-table-column prop="scenes.name" label="项目业态"></el-table-column>
        <el-table-column prop="statusCn" label="查验状态"></el-table-column>
        <!-- <el-table-column label="查验状态">
          <template slot-scope="scope">
            <span>{{getStatus(scope.row.status)}}</span>
          </template>
        </el-table-column> -->
        <el-table-column prop="assistGroups.responsiblePerson" label="查验责任人"></el-table-column>
        <el-table-column prop="createTime" label="创建时间" :sortable='true'></el-table-column>
        <el-table-column label="操作" fixed="right">
          <template slot-scope="scope">
            <wt-text-button v-has-any-premission="['GSGL:VIEW']" @click="onClickView(scope.row)">查看</wt-text-button>
            <wt-text-button v-if="scope.row.status !== 9" v-has-any-premission="['GSGL:UPDATE']" @click="onClickStop(scope.row)">暂停</wt-text-button>
            <wt-text-button v-if="scope.row.status === 9" v-has-any-premission="['GSGL:UPDATE']" @click="onClickRestart(scope.row)">重启</wt-text-button>
            <wt-text-button v-if="scope.row.canDownPreWordReport" @click="onClickReport(scope.row, 1)">查验报告</wt-text-button>
            <wt-text-button v-if="scope.row.canDownFinishWordReport" @click="onClickReport(scope.row, 2)">整改销项报告</wt-text-button>
          </template>
        </el-table-column>
      </template>
    </wt-data-form>
    </div>
    <!--弹层 开始-->
    <wt-page-dialog ref="detailDialog" title="项目详情">
      <xmgl-edit :view-type="form.detailViewType" :detail-data="form.detailData"
                  @on-refresh="$refs.dataForm.onClickSearch()" @on-close="$refs.detailDialog.close()"></xmgl-edit>
    </wt-page-dialog>
    <wt-page-dialog ref="reportDialog" title="报告管理">
       <company-report :view-type="form.detailViewType" :detail-data="form.detailData"
                  @on-refresh="$refs.dataForm.requestDataList()" @on-close="$refs.reportDialog.close()"></company-report>
    </wt-page-dialog>
    <!--弹层 结束-->
  </div>
</template>

<script>
import { mapState } from 'vuex'

export default {
  name: 'companyInfo',
  data() {
    return {
      form: {
        isFromLoading: false,
        detailViewType: 'edit',
        detailData: {},
        extFormData: {},
        formData: {
          name: '',
          createTimeRange: [],
          companyIds: [],
          code: '',
          scenesCode: '',
          status: ''
        },
        pageData: { currentPage: 1, pageSize: 20, sortName: '', sortOrder: '' },
        formResult: {}
      },
      filterText: '',
      defaultCheckedKeys: [],
      checkedModuleCodes: [],
      moduleTreeData: [],
      firstTime: true,
      statusOptions: [{
        value: 0,
        label: '草稿'
      }, {
        value: 1,
        label: '初验实施'
      }, {
        value: 2,
        label: '初验结束'
      }, {
        value: 3,
        label: '复验结束'
      }, {
        value: 9,
        label: '暂停'
      }],
      companyOptions: [],
      scenesOptions: [],
      total: 0
    }
  },
  watch: {
    filterText(val) {
      this.$refs.moduleTree.filter(val)
    },
    'form.formData.companyIds': {
      handler(newVal, oldVal) {
        this.setCompanySelect()
      },
      deep: true,
      immediate: true
    }
  },
  async mounted() {
    await this.getCompanyList()
    this.getAllScenes()
    this.$refs.dataForm.requestDataList()
  },
  created() {
  },
  methods: {
    resetRequestData(data) {
      console.log('qqq', data)
      this.total = data.total
    },
    setCompanySelect() {
      if (this.firstTime) {
        this.firstTime = false
        return false
      }
      this.$refs.moduleTree.setCheckedKeys(this.form.formData.companyIds)
    },
    getStatus(type) {
      let status = ''
      switch (type) {
        case 0:
          status = '初始化'
          break
        case 1:
          status = '开启'
          break
        case 2:
          status = '暂停'
          break
        case 3:
          status = '报告管理'
          break
        default:
          status = '--'
      }
      return status
    },
    getCompanyName(id) {
      return this.moduleTreeData.length > 0 && this.moduleTreeData[0].children.filter(x => x.id === id).length > 0 && this.moduleTreeData[0].children.filter(x => x.id === id)[0].name || ''
    },
    getAllScenes() {
      this.$wtRequest({
        url: '/tProjectScenes/queryAll',
        method: 'post'
      }).then(res => {
        if (res.isSuccess) {
          console.log(res)
          this.scenesOptions = res.data.list.map((x) => {
            x.value = x.code
            x.label = x.name
            return x
          })
        }
      })
    },
    getCompanyList() {
      this.$wtRequest({
        url: '/sysOrg/list',
        method: 'post',
        data: {
          formData: {
            createTimeRange: [],
            name: ''
          },
          pageData: {
            currentPage: 1,
            pageSize: 100,
            sortName: '',
            sortOrder: ''
          }
        }
      }).then(res => {
        if (res.isSuccess) {
          const tree = [{
            id: 0,
            name: '总公司',
            children: res.data.list
          }]
          this.moduleTreeData = tree
          this.companyOptions = res.data.list.map((x) => {
            x.label = x.name
            x.value = x.id
            return x
          })
        }
      })
    },
    onModuleCurrentChanged(nodeData, node) {
      console.log(nodeData, node)
      // this.form.formData.checkedModuleCodes = node.checkedKeys
      // this.$nextTick(() => {
      //   this.$refs.dataForm.onClickSearch()
      // })
      this.form.formData.companyIds = node.checkedKeys.filter(x => x !== 0)
    },
    filterNode(value, data) {
      console.log(value, data)
      if (!value) return true
      return data.name.indexOf(value) !== -1
    },
    onClickView(rowData) {
      this.form.detailViewType = 'view'
      this.form.detailData = rowData
      this.$refs.detailDialog.open()
    },
    onClickRestart(rowData) {
      const that = this
      this.$confirm('请确认是否重启当前项目?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        that.$wtRequest({
          url: '/tProjectInfo/start',
          method: 'post',
          data: {
            id: rowData.id
          }
        }).then((resp) => {
          if (resp.code === '0') {
            that.$refs.dataForm.onClickSearch()
            that.$message.success('重启成功')
          } else {
            that.$message.error('重启失败')
          }
        }).catch(() => {
          that.$message.error('重启失败')
        })
      }).catch(() => {
        this.$message({
          type: 'info',
          message: '已取消重启'
        })
      })
    },
    onClickStop(rowData) {
      const that = this
      this.$confirm('请确认是否暂停当前项目?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        that.$wtRequest({
          url: '/tProjectInfo/stop',
          method: 'post',
          data: {
            id: rowData.id
          }
        }).then((resp) => {
          if (resp.code === '0') {
            that.$refs.dataForm.onClickSearch()
            that.$message.success('暂停成功')
          } else {
            that.$message.error('暂停失败')
          }
        }).catch(() => {
          that.$message.error('暂停失败')
        })
      }).catch(() => {
        this.$message({
          type: 'info',
          message: '已取消暂停'
        })
      })
    },
    onClickReport(rowData, type) {
      // this.form.detailViewType = 'view'
      // this.form.detailData = rowData
      // this.$refs.reportDialog.open()
      const url = type === 1 ? `/wordReport/exportPreWord/${rowData.id}` : `/wordReport/exportAuditWord/${rowData.id}`
      // window.open(url, '_blank')
      const name = type === 1 ? '查验报告.zip' : '整改销项报告.zip'
      const that = this
      that.loading = true
      this.$wtUtil.downloadFile({
        url,
        fileName: name
      }).then((resp) => {
        that.loading = false
      }).catch((e) => {
        that.loading = false
        that.$message({ type: 'error', message: '下载失败，请稍后再试' })
      })
    },
    onClickAdd() {
      this.form.detailViewType = 'add'
      this.$refs.detailDialog.open()
    },
    onClickDelete(rowData) {
      this.$refs.dataForm.onClickDelete(rowData.id)
    },
    // 删除角色
    requestRoleDelete(rowData) {
      this.$wtRequest({
        url: '/auth/roleDelete',
        method: 'post',
        data: {
          list: [rowData.id]
        }
      }).then(res => {
        if (res.isSuccess) {
          this.$message({ type: 'success', message: '删除成功' })
          this.$refs.dataForm.requestDataList()
        }
      })
    }
  },
  computed: {
    ...mapState({
      orgId: state => state.auth.orgId
    })
  }
}
</script>
<style scoped lang="scss">
  .page-container {
    display: flex;
    .sidebar {
      width: 200px;
      margin: -20px 20px -20px 0;
      padding: 20px 20px 20px 0;
      border-right: #f6f8f9 10px solid;
      .search {
        margin-bottom: 15px;
      }
    }
    .main {
      flex: 1;
    }
  }
  .total {
    margin-bottom: -20px;
    color: #666;
    font-size: 12px;
  }
</style>
