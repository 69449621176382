<template>
  <el-dropdown class="wt-user-switch" placement="bottom" size="default" @command="onClickCommand">
    <i class="wt-nav-icon el-icon-sort" title="切换身份"></i>
    <el-dropdown-menu slot="dropdown">
      <el-dropdown-item :command="orgUserRoleItem.id" v-for="orgUserRoleItem in orgUserRoleList" :key="orgUserRoleItem.id">
        <i class="el-icon-check" v-if="orgUserRoleItem.roleId == roleId && orgUserRoleItem.orgId == orgId"></i>
        <span>{{orgUserRoleItem.orgName}} {{orgUserRoleItem.roleName}}</span>
      </el-dropdown-item>
    </el-dropdown-menu>
  </el-dropdown>
</template>

<script>
import { mapState } from 'vuex'
import { Loading } from 'element-ui'
export default {
  name: 'WtUserSwitch',
  methods: {
    async onClickCommand(command) {
      const that = this
      const loading = Loading.service({
        fullscreen: true,
        text: '切换身份中...'
      })
      try {
        const switchResp = await that.$wtRequest({
          url: '/auth/switchUserOrgRole',
          method: 'post',
          data: { orgUserRoleId: command }
        })
        if (!switchResp.isSuccess) {
          return that.$message({ type: 'error', message: '切换身份失败' })
        }
        await that.$store.dispatch('switchUser', switchResp.data)
        await that.$router.push({ path: '/' })
        location.reload()
        setTimeout(() => {
          loading.close()
        }, 500)
      } catch (e) {
        loading.close()
        that.$message({ type: 'error', message: '切换身份失败' })
      }
    }
  },
  computed: {
    ...mapState({
      orgId: state => state.auth.orgId,
      roleId: state => state.auth.roleId,
      orgUserRoleList: state => state.auth.orgUserRoleList
    })
  }
}
</script>

<style lang="scss" scoped>
.wt-user-switch{
  height: 36px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.wt-nav-icon{
  cursor: pointer;
}
</style>
