<template>
  <div>
  <wt-page>
    <div class="wt-page-container" v-loading="isFromLoading" element-loading-text="导入中">
      <div class="page-detail">
        <wt-data-form class="wt-table-full" ref="dataForm" :form.sync="form"
            request-list-url="/tProjectBaseInfo/queryPageByProjectId"
            request-delete-url="/tProjectBaseInfo/delete"
            request-detail-url="/tProjectBaseInfo/detail">
          <template slot="search">
            <wt-search-form-item title="类型" v-model="form.formData.code" type="select" @input="changeValue" :dataList="typeOptions"/>
          </template>
          <template slot="toolbar">
            <div class="toolbar">
              <div class="right">
                <wt-button type="primary" v-has-any-premission="['XMGLINFO:ADD']" @click="onBaseAdd">新增基础信息</wt-button>
                <div class="upload-button">
                <wt-button type="primary" v-has-any-premission="['XMGLINFO:ADD']" @click="onBaseImport">基础信息导入</wt-button>
                <el-upload
                  style="display: "
                  ref="importFile2"
                  action="/tProjectBaseInfo/importExcel"
                  :auto-upload="false"
                  :on-change="fileReady"
                  :file-list="fileList">
                  <el-button size="small" type="primary" ref="importFile">基础信息导入</el-button>
                </el-upload>
                </div>
                <wt-button type="primary" v-has-any-premission="['XMGLINFO:ADD']" @click="onBaseExport">基础信息导出</wt-button>
              </div>
            </div>
          </template>
          <template slot="table">
            <el-table-column type="index" label="序号"></el-table-column>
            <el-table-column label="类型">
              <template slot-scope="scope">
              <wt-text-button>{{scope.row.baseTypePname}}</wt-text-button>
              </template>
            </el-table-column>
            <el-table-column label="子类型">
              <template slot-scope="scope">
              <wt-text-button>{{scope.row.baseTypeName}}</wt-text-button>
              </template>
            </el-table-column>
            <el-table-column label="楼层/场地">
              <template slot-scope="scope">
                <wt-text-button>{{scope.row.loof}}</wt-text-button>
              </template>
            </el-table-column>
            <el-table-column label="操作" fixed="right">
              <template slot-scope="scope">
                <wt-text-button v-has-any-premission="['XMGLINFO:UPDATE']" @click="xmTypeEdit(scope.row)">编辑</wt-text-button>
                <wt-text-button v-has-any-premission="['XMGLINFO:UPDATE']" @click="xmTypeDelete(scope.row)">删除</wt-text-button>
              </template>
            </el-table-column>
          </template>
        </wt-data-form>
      </div>
    </div>
    <div slot="footer">
      <wt-button type="default" @click="onClickClose">关闭</wt-button>
    </div>
  </wt-page>
  <!--弹层 开始-->
  <wt-dialog ref="addBasicsDialog" :title="addBaseTitle">
  <div class="wt-org-role-wrapper">
    <el-form :model="detailForm" ref="addBasicsForm" class="wt-page-form" label-width="120px" size="small">
      <el-form-item label="类型：" prop="baseTypePcode"
                    :rules="[ { required: true, message: '请选择类型', validator: $wtValidator.ValidatorNotBlank, trigger: 'blur', }, ]">
        <el-select v-model="detailForm.baseTypePcode">
          <el-option v-for="item in typeOptions" :key="item.code" :label="item.name" :value="item.code"></el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="子类型：" prop="baseTypeName" :rules="[ { required: true, message: '子类型不能为空', validator: $wtValidator.ValidatorNotBlank, trigger: 'blur' }]">
        <el-autocomplete style="width: 100%"
          ref="subType"
          v-model="detailForm.baseTypeName"
          :fetch-suggestions="querySearchAsync"
          placeholder="请输入"
          @select="handleSelect"
        ></el-autocomplete>
      </el-form-item>
      <el-form-item label="楼层/场地：" prop="loof" :rules="[ { required: true, message: '楼层/场地不能为空', validator: $wtValidator.ValidatorNotBlank, trigger: 'blur' }]">
        <el-input v-model="detailForm.loof" placeholder="请输入"></el-input>
      </el-form-item>
    </el-form>
  </div>
  <div class="wt-toolbar wt-margin-t wt-flex-end">
    <wt-button type="default" @click="$refs.addBasicsDialog.close()">取消</wt-button>
    <wt-button type="primary" @click="onClickConfirm('addBasicsForm')">保存</wt-button>
  </div>
</wt-dialog>
<!--弹层 结束-->
</div>
</template>

<script>
import { mapState } from 'vuex'

export default {
  name: 'addBasicsInfo',
  isAutoComponent: true,
  props: ['viewType', 'detailData'],
  data() {
    return {
      isButtonLoading: false,
      isFromLoading: false,
      form: {
        isFromLoading: false,
        detailViewType: 'edit',
        detailData: {},
        extFormData: {
          projectId: this.detailData.id,
          code: ''
        },
        formData: {
          projectId: this.detailData.id,
          code: ''
        },
        pageData: { currentPage: 1, pageSize: 20, sortName: '', sortOrder: '' },
        formResult: {}
      },
      detailForm: {
        id: '',
        projectId: this.detailData.id,
        baseTypePcode: 'LouYu',
        baseTypeCode: '',
        baseTypeName: '',
        loof: ''
      },
      addBaseTitle: '新建基础信息',
      dialogType: 'add',
      typeOptions: [],
      file: null,
      fileList: []
    }
  },
  watch: {
    'form.formData.projectId': {
      handler(newVal, oldVal) {
        if (!newVal) {
          this.form.formData.projectId = this.detailData.id
        }
      },
      deep: true,
      immediate: true
    }
  },
  async mounted() {
    this.requestTypeOptions()
    this.$nextTick(() => {
      this.$refs.dataForm.onClickSearch()
    })
  },
  created() {},
  methods: {
    changeValue(val) {
      const that = this
      console.log('change', val)
      this.form.formData.code = val
      // this.form.extFormData.code = val
    //   setTimeout(() => {
    //     console.log('tt', that.form.formData.code)
    //   }, 2000)
    },
    fileReady(file) {
      console.log('change', file)
      const that = this
      this.file = file
      const params = new FormData()
      params.append('excelFile', this.file.raw)
      params.append('projectId', this.detailData.id)
      that.isFromLoading = true
      that.$wtRequest({
        url: '/tProjectBaseInfo/importExcel',
        method: 'post',
        data: params
      }).then((resp) => {
        if (resp.code === '0') {
          that.$message({
            message: '新建成功',
            type: 'success'
          })
          that.$nextTick(() => {
            that.$refs.dataForm.onClickSearch()
          })
        } else {
          that.$message({
            message: '新建失败',
            type: 'error'
          })
        }
        that.file = null
        that.isFromLoading = false
      }).catch((err) => {
        console.log(err)
        that.$message({
          message: '新建失败',
          type: 'error'
        })
        that.file = null
        that.isFromLoading = false
      })
    },
    querySearchAsync(queryString, cb) {
      console.log(queryString, cb)
      const that = this
      that.$wtRequest({
        url: '/tProjectBaseTypeInfo/queryForProject',
        method: 'post',
        data: {
          name: queryString,
          pcode: that.detailForm.baseTypePcode,
          projectId: that.detailData.id
        }
      }).then((resp) => {
        if (resp.code === '0') {
          const list = resp.data.list || []
          list.map((x) => {
            x.value = x.name
            return x
          })
          console.log('cb', list)
          cb(list)
        }
      }).catch((e) => {
        console.log('requestDetail e', e)
      })
      /* var restaurants = this.restaurants;
      var results = queryString ? restaurants.filter(this.createStateFilter(queryString)) : restaurants
      clearTimeout(this.timeout)
      this.timeout = setTimeout(() => {
        cb(results)
      }, 3000 * Math.random())*/
    },
    createStateFilter(queryString) {
      return (state) => {
        return (state.value.toLowerCase().indexOf(queryString.toLowerCase()) === 0)
      }
    },
    handleSelect(item) {
      this.detailForm.baseTypeCode = item.code
      this.$refs.addBasicsForm.validate()
    },
    onClickConfirm(formName) {
      const that = this
      this.$refs[formName].validate((valid) => {
        if (valid) {
          if (this.dialogType === 'add') {
            that.$wtRequest({
              url: '/tProjectBaseInfo/add',
              method: 'post',
              data: {
                projectId: that.detailData.id,
                baseTypePcode: that.detailForm.baseTypePcode,
                baseTypeCode: that.detailForm.baseTypeCode,
                baseTypeName: that.detailForm.baseTypeName,
                loof: that.detailForm.loof
              }
            }).then((resp) => {
              if (resp.code === '0') {
                that.$message.success('新增成功')
                that.$nextTick(() => {
                  that.$refs.dataForm.onClickSearch()
                })
              } else {
                that.$message.success('新增失败')
              }
              that.$refs.addBasicsDialog.close()
            }).catch(() => {
              that.$message.success('新增失败')
              that.$refs.addBasicsDialog.close()
            })
          } else {
            that.$wtRequest({
              url: '/tProjectBaseInfo/update',
              method: 'post',
              data: {
                projectId: that.detailData.id,
                id: that.detailForm.id,
                baseTypePcode: that.detailForm.baseTypePcode,
                baseTypeCode: that.detailForm.baseTypeCode,
                baseTypeName: that.detailForm.baseTypeName,
                loof: that.detailForm.loof
              }
            }).then((resp) => {
              if (resp.code === '0') {
                that.$message.success('修改成功')
                that.$nextTick(() => {
                  that.$refs.dataForm.onClickSearch()
                })
              } else {
                that.$message.success('修改失败')
              }
              that.$refs.addBasicsDialog.close()
            }).catch(() => {
              that.$message.success('修改失败')
              that.$refs.addBasicsDialog.close()
            })
          }
        } else {
          console.log('error submit!!')
          return false
        }
      })
    },
    onBaseAdd() {
      this.addBaseTitle = '新建基础信息'
      this.dialogType = 'add'
      this.detailForm = {
        id: '',
        projectId: this.detailData.id,
        baseTypePcode: 'LouYu',
        baseTypeCode: '',
        baseTypeName: '',
        loof: ''
      }
      this.$refs.addBasicsDialog.open()
    },
    onBaseImport() {
      this.file = null
      this.fileList = []
      this.$refs.importFile.click()
    },
    onBaseExport() {
      // const url = `/api/tProjectBaseInfo/exportExcel/${this.detailData.id}`
      // window.open(url, '_blank')
      const that = this
      this.$wtUtil.downloadFile({
        url: `/tProjectBaseInfo/exportExcel/${this.detailData.id}`,
        fileName: '基础信息.xls'
      }).then((resp) => {
      }).catch((e) => {
        that.$message({ type: 'error', message: '导出失败，请稍后再试' })
      })
    },
    xmTypeEdit(row) {
      console.log(row)
      this.addBaseTitle = '编辑基础信息'
      this.dialogType = 'edit'
      this.detailForm = {
        id: row.id,
        projectId: this.detailData.id,
        baseTypePcode: row.baseTypePcode,
        baseTypeCode: row.baseTypeCode,
        baseTypeName: row.baseTypeName,
        loof: row.loof
      }
      this.$refs.addBasicsDialog.open()
    },
    xmTypeDelete(row) {
      this.$refs.dataForm.onClickDelete(row.id)
    },
    onClickViewStandard(row) {
      console.log(row)
    },
    onClickViewFloor(row) {
      console.log(row)
    },
    onClickViewPark(row) {
      console.log(row)
    },
    onClickViewBasement(row) {
      console.log(row)
    },
    documentsDownload(row) {
      window.open('row.url', '_blank')
    },
    onClickClose() {
      this.$emit('on-close')
    },
    initDetailData(data) {
      this.detail = data
    },
    requestTypeOptions() {
      const that = this
      that.$wtRequest({
        url: '/tProjectBaseTypeInfo/queryFirstLevel',
        method: 'post',
        data: {}
      }).then((resp) => {
        if (resp.code === '0') {
          const list = resp.data.list.map((x) => {
            x.value = x.code
            x.label = x.name
            return x
          })
          this.typeOptions = list || []
          if (this.typeOptions.length <= 0) {
            this.detailForm.baseTypePcode = ''
          }
        }
      }).catch((e) => {
        console.log('requestDetail e', e)
      })
    },
    requestDetail() {
      const that = this
      that.isFromLoading = true
      that.$wtRequest({
        url: '/tProjectInfo/detail',
        method: 'post',
        data: { id: that.detailData.id }
      }).then((resp) => {
        console.log('requestDetail resp', resp)
        // that.detail = resp.data || {}
        this.initDetailData(resp.data)
        that.isFromLoading = false
      }).catch((e) => {
        console.log('requestDetail e', e)
        that.isFromLoading = false
      })
    }
  },
  computed: {
    isReadMode() {
      return this.viewType === 'view'
    },
    ...mapState({
    })
  }
}
</script>
<style lang="scss" scoped>
  .module-permission-content {display: flex;width: 100%;margin-top: 16px; color: #666;}
  .module-permission-content .left-content {width: 250px;padding: 0 24px;}
  .module-permission-content .right-content {flex: 1;}
  .module-menu-content {display: flex;width: 100%;}
  .module-menu-content .left-content {width: 300px;padding: 0 24px;}
  .module-menu-content .right-content {flex: 1;}
  .page-detail {
    padding: 0px;
  }
  .right {
    display: flex;
  }
  .upload-button {
    display: inline-block;
    position: relative;
    margin: 0 10px;
    overflow: hidden;
    height: 32px;
    > div {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      .el-button--small {
        width: 104px;
      }
    }
  }
</style>
