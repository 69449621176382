<template>
  <wt-page>
    <div class="wt-page-container" v-loading="isFromLoading">
      <el-form ref="form" :model="detailForm" class="wt-page-form" label-width="120px" size="small">
        <el-form-item label="角色名称：" prop="roleName"
                      :rules="[ { required: true, message: '输入内容不能为空', validator: $wtValidator.ValidatorNotBlank, trigger: 'blur', }, ]">
          <el-input v-model="detailForm.roleName" maxlength="20" placeholder="请输入" :disabled="viewType === 'view'"></el-input>
        </el-form-item>
        <el-form-item label="描述：" prop="summary"
                      :rules="[ { required: true, message: '输入内容不能为空', validator: $wtValidator.ValidatorNotBlank, trigger: 'blur', }, ]">
          <el-input v-model="detailForm.summary" placeholder="请输入" autocomplete="off" :disabled="viewType === 'view'" type="textarea"></el-input>
        </el-form-item>
        <el-form-item label="菜单方案：" prop="menuGroupId"
                      :rules="[ { required: true, message: '请选择菜单方案', validator: $wtValidator.ValidatorNotBlank, trigger: 'blur', }, ]">
          <el-select v-model="detailForm.menuGroupId" @change="onMenuGroupChanged">
            <el-option v-for="item in menuGroupList" :key="item.id" :label="item.menuGroupName" :value="item.id"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="是否启用" prop="isPublish"
                      :rules="[ { required: true, message: '请选择发布状态', validator: $wtValidator.ValidatorNotBlank, trigger: 'change', }, ]">
          <el-switch v-model="detailForm.isPublish" :active-value="1" :inactive-value="0"
                     :disabled="viewType === 'view' || detailForm.isDefault == 1"></el-switch>
          &nbsp;
          <el-tooltip v-if="detailForm.isDefault == 1" class="wt-tooltip" effect="dark" content="默认角色不可以修改发布状态。" placement="top"><i class="rule-icon-tip el-icon-question"></i></el-tooltip>
        </el-form-item>
      </el-form>
      <wt-page-section title="管理菜单" v-if="detailForm.menuGroupId > 0">
        <div slot="title-right" style="display: flex;flex: 1;justify-content: flex-end;align-items: center;">
          <span style="margin-left: 16px;font-size: 12px;">简单模式：</span><el-switch v-model="isMiniMode"></el-switch>
        </div>
        <div class="module-permission-content">
          <el-tree class="left-content" ref="moduleTree" :expand-on-click-node="false" :data="moduleTreeData"
                   node-key="id" :default-checked-keys="defaultCheckedKeys" @check="onModuleCurrentChanged" accordion
                   show-checkbox></el-tree>
          <div class="right-content" v-if="isPermissionItemVisibility">
            <permission-item :key="'permission_' + index" v-for="(item, index) in allModuleList"
                             :is-mini-mode="isMiniMode"
                             :is-read-mode="isReadMode" :item="item" :level="0" type="picker"
                             :checked-module-codes="checkedModuleCodes"
                             :checked-permission-codes="checkedPermissionCodes"
                             :module-permission-map="modulePermissionMap" @on-click-tag="onClickTag"
                             @on-click-item-action="onClickItemAction"></permission-item>
          </div>
        </div>
      </wt-page-section>
    </div>
    <div slot="footer">
      <wt-button type="default" @click="onClickClose">关闭</wt-button>
      <wt-button type="primary" v-if="viewType == 'add'" v-has-any-premission="['SYSROLE:ADD']" :loading="isFromLoading" @click="onClickAdd">保存</wt-button>
      <wt-button type="primary" v-if="viewType == 'edit'" v-has-any-premission="['SYSROLE:UPDATE']" :loading="isFromLoading" @click="onClickEdit">保存</wt-button>
    </div>
  </wt-page>
</template>

<script>
import { mapState } from 'vuex'

export default {
  name: 'roleDetail',
  isAutoComponent: true,
  props: ['viewType', 'detailData'],
  data() {
    return {
      menuGroupList: [],
      isPermissionItemVisibility: true,
      isMiniMode: true,
      allModuleList: [],
      defaultCheckedKeys: [],
      checkedModuleCodes: [],
      checkedPermissionCodes: [],
      modulePermissionMap: {},
      isButtonLoading: false,
      isFromLoading: false,
      detailForm: {
        menuGroupId: '',
        orderNum: 0,
        isPublish: 1
      }
    }
  },
  async mounted() {
    this.requestPermissionList()
    await this.requestRoleMenuGroupList()
    if (this.viewType !== 'add') {
      await this.requestDetail()
    }
  },
  created() {},
  methods: {
    onClickClose() {
      this.$emit('on-close')
    },
    onClickAdd() {
      const that = this
      this.$wtUtil.validateConfirm(this, '是否保存?').then(() => {
        that.requestAdd()
      })
    },
    onClickEdit() {
      const that = this
      this.$wtUtil.validateConfirm(this, '是否保存?').then(() => {
        that.requestUpdate()
      })
    },
    onMenuGroupChanged(val) {
      console.log('onMenuGroupChanged', val)
      if (this.$wtUtil.isBlank(val)) {
        this.allModuleList = []
      } else {
        this.requestRoleMenuGroup({ id: val })
      }
    },
    onClickTag(code) {
      const tempCheckedPermissionCodes = this.checkedPermissionCodes || []
      const index = tempCheckedPermissionCodes.indexOf(code)
      if (index !== -1) {
        this.checkedPermissionCodes.splice(index, 1)
      } else {
        this.checkedPermissionCodes.push(code)
      }
    },
    onClickItemAction({ action, data }) {
      const moduleName = data.menuName
      if (action === 'currentCheckAll') {
        this.checkAllModulePermission(moduleName)
      } else if (action === 'currentUnCheckAll') {
        this.unCheckAllModulePermission(moduleName)
      } else if (action === 'checkAll') {
        const that = this
        that.checkAllModulePermission(moduleName)
        if (data.children && data.children.length > 0) {
          data.children.forEach(item => {
            if (item.menuName) {
              that.checkAllModulePermission(item.menuName)
            }
          })
        }
      } else if (action === 'unCheckAll') {
        const that = this
        that.unCheckAllModulePermission(moduleName)
        if (data.children && data.children.length > 0) {
          data.children.forEach(item => {
            if (item.name) {
              that.unCheckAllModulePermission(item.menuName)
            }
          })
        }
      }
    },
    checkAllModulePermission(moduleName) {
      const codes = ['VIEW', 'ADD', 'UPDATE', 'DELETE']
      if (
        this.modulePermissionMap[moduleName] &&
        this.modulePermissionMap[moduleName].length > 0
      ) {
        for (let i = 0; i < this.modulePermissionMap[moduleName].length; i++) {
          codes.push(
            this.modulePermissionMap[moduleName][i].permissionCode.toUpperCase()
          )
        }
      }
      for (let i = 0; i < codes.length; i++) {
        const permissionCode = codes[i]
        if (
          this.checkedPermissionCodes.indexOf(
            moduleName + ':' + permissionCode
          ) === -1
        ) {
          this.checkedPermissionCodes.push(moduleName + ':' + permissionCode)
        }
      }
    },
    unCheckAllModulePermission(moduleName) {
      const codes = ['VIEW', 'ADD', 'UPDATE', 'DELETE']
      if (
        this.modulePermissionMap[moduleName] &&
        this.modulePermissionMap[moduleName].length > 0
      ) {
        for (let i = 0; i < this.modulePermissionMap[moduleName].length; i++) {
          codes.push(
            this.modulePermissionMap[moduleName][i].permissionCode.toUpperCase()
          )
        }
      }
      for (let i = 0; i < codes.length; i++) {
        const permissionCode = codes[i]
        const permissionIndex = this.checkedPermissionCodes.indexOf(
          moduleName + ':' + permissionCode
        )
        if (permissionIndex !== -1) {
          this.checkedPermissionCodes.splice(permissionIndex, 1)
        }
      }
    },
    onModuleCurrentChanged(nodeData, node) {
      this.checkedModuleCodes = node.checkedKeys.concat(node.halfCheckedKeys)
    },
    requestDetail() {
      const that = this
      that.isFromLoading = true
      that.$wtRequest({
        url: '/auth/roleDetail',
        method: 'post',
        data: { id: that.detailData.id }
      }).then((resp) => {
        console.log('requestDetail resp', resp)
        that.detailForm = resp.data || {}
        that.defaultCheckedKeys = that.detailForm.moduleList.concat([])
        that.isPermissionItemVisibility = false
        that.$nextTick(() => {
          that.checkedModuleCodes = that.detailForm.moduleList
          that.checkedPermissionCodes = that.detailForm.permissionList || []
          that.isPermissionItemVisibility = true
          that.requestRoleMenuGroup({ id: that.detailForm.menuGroupId })
        })
        that.isFromLoading = false
      }).catch((e) => {
        console.log('requestDetail e', e)
        that.isFromLoading = false
      })
    },
    requestRoleMenuGroupList() {
      const that = this
      that.isFromLoading = true
      that.$wtRequest({
        url: '/auth/roleMenuGroupList',
        method: 'post',
        data: {}
      }).then((resp) => {
        that.menuGroupList = resp.data ? resp.data.list || [] : []
        that.isFromLoading = false
      }).catch(() => {
        that.isFromLoading = false
      })
    },
    requestRoleMenuGroup({ id }) {
      const that = this
      that.isFromLoading = true
      that.$wtRequest({
        url: '/auth/roleMenuGroup',
        method: 'post',
        data: { id: id }
      }).then((resp) => {
        that.isFromLoading = false
        that.allModuleList = JSON.parse(resp.data || '[]') || []
      }).catch(() => {
        that.isFromLoading = false
      })
    },
    requestPermissionList() {
      const that = this
      that
        .$wtRequest({
          url: '/auth/permissionList',
          method: 'post',
          data: {}
        })
        .then((resp) => {
          const tempMap = {}
          resp.data.forEach((item) => {
            if (!tempMap[item.moduleName]) {
              tempMap[item.moduleName] = []
            }
            tempMap[item.moduleName].push(item)
          })
          that.modulePermissionMap = tempMap
        })
        .catch(() => {})
    },
    requestUpdate() {
      const that = this
      that.isButtonLoading = true
      that.isFromLoading = true
      const permissionList = that.checkedPermissionCodes.filter(
        (permissionCode) => {
          for (let i = 0; i < that.checkedModuleCodes.length; i++) {
            if (permissionCode.indexOf(that.checkedModuleCodes[i]) === 0) {
              return true
            }
          }
          return false
        }
      )
      that
        .$wtRequest({
          url: '/auth/roleUpdate',
          method: 'post',
          data: Object.assign(that.detailForm, {
            moduleList: this.checkedModuleCodes,
            permissionList: permissionList
          })
        })
        .then((resp) => {
          that.isButtonLoading = false
          that.isFromLoading = false
          that.$message({ type: 'success', message: '保存成功' })
          that.$emit('on-refresh')
          that.$emit('on-close')
        })
        .catch(() => {
          that.isButtonLoading = false
          that.isFromLoading = false
        })
    },
    requestAdd() {
      const that = this
      that.isButtonLoading = true
      that.isFromLoading = true
      const permissionList = that.checkedPermissionCodes.filter(
        (permissionCode) => {
          for (let i = 0; i < that.checkedModuleCodes.length; i++) {
            if (permissionCode.indexOf(that.checkedModuleCodes[i]) === 0) {
              return true
            }
          }
          return false
        }
      )
      that
        .$wtRequest({
          url: '/auth/roleAdd',
          method: 'post',
          data: Object.assign(that.detailForm, {
            moduleList: this.checkedModuleCodes,
            permissionList: permissionList
          })
        })
        .then((resp) => {
          that.isButtonLoading = false
          that.isFromLoading = false
          that.$message({ type: 'success', message: '保存成功' })
          that.$emit('on-refresh')
          that.$emit('on-close')
        })
        .catch(() => {
          that.isButtonLoading = false
          that.isFromLoading = false
        })
    }
  },
  computed: {
    moduleTreeData() {
      const that = this
      const treeData = []
      const makeChildrenFunction = (menuItem) => {
        const children = []
        if (!menuItem.hidden) {
          if (menuItem.children && menuItem.children.length > 0) {
            const parentRouteId = menuItem.menuName
            if (that.defaultCheckedKeys.indexOf(parentRouteId) !== -1) {
              that.defaultCheckedKeys.splice(
                that.defaultCheckedKeys.indexOf(parentRouteId),
                1
              )
            }
            menuItem.children.forEach((child) => {
              const routeId = child.menuName
              children.push({
                id: routeId,
                label: child.menuTitle,
                disabled: that.isReadMode,
                children: makeChildrenFunction(child)
              })
            })
          }
        }
        return children
      }
      this.allModuleList.forEach((staticRoute) => {
        if (!staticRoute.hidden) {
          const routeId = staticRoute.menuName
          treeData.push({
            id: routeId,
            label: staticRoute.menuTitle,
            disabled: that.isReadMode,
            children: makeChildrenFunction(staticRoute)
          })
        }
      })
      return treeData
    },
    isReadMode() {
      return this.viewType === 'view'
    },
    ...mapState({
    })
  }
}
</script>
<style scoped>
  .module-permission-content {display: flex;width: 100%;margin-top: 16px;}
  .module-permission-content .left-content {width: 250px;padding: 0 24px;}
  .module-permission-content .right-content {flex: 1;}
  .module-menu-content {display: flex;width: 100%;}
  .module-menu-content .left-content {width: 300px;padding: 0 24px;}
  .module-menu-content .right-content {flex: 1;}
</style>
