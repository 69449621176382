<template>
  <wt-page>
    <div class="wt-page-container" v-loading="isFromLoading">
      <el-form :model="detailForm" ref="form" class="wt-page-form" label-width="120px" size="small">
        <el-form-item class="wt-detail-form-item" label="用户名" prop="username"
                      :rules="[ { required: true, message: '输入内容不能为空', validator: $wtValidator.ValidatorNotBlank, trigger: 'blur' },
                       { pattern: /^[a-zA-Z0-9][\.a-zA-Z0-9_-]{1,30}$/, message: '请填写以英文或数字开头，2-30位的英文或数字或下划线', trigger: 'blur' }]">
          <el-input placeholder="请输入" v-model="detailForm.username" :disabled="viewType != 'add'" maxlength="30"></el-input>
        </el-form-item>
        <el-form-item class="wt-detail-form-item" label="用户姓名" prop="realname"
                      :rules="[ { required: true, message: '输入内容不能为空', validator: $wtValidator.ValidatorNotBlank, trigger: 'blur' } ]">
          <el-input placeholder="请输入" v-model="detailForm.realname" :disabled="viewType == 'view'" maxlength="100"></el-input>
        </el-form-item>
        <el-form-item class="wt-detail-form-item" label="角色" prop="roleList">
          <el-select placeholder="请选择角色" v-model="detailForm.roleId" :disabled="viewType == 'view' || isCompany">
            <el-option v-for="item in roleList" :label="item.roleName" :value="item.id" :key="item.id"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item v-if="viewType!== 'edit' && detailForm.roleId == 2" class="wt-detail-form-item" label="公司名称" prop="orgList">
          <el-select placeholder="请选择公司" v-model="detailForm.orgId" :disabled="viewType == 'view' || isCompany">
            <el-option v-for="item in orgList" :label="item.name" :value="item.id" :key="item.id"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item v-if="viewType === 'edit'" class="wt-detail-form-item" label="所属分公司" prop="orgName">
          <el-input  :disabled="true" v-model="detailForm.orgName" maxlength="100"></el-input>
        </el-form-item>
<!--
<el-form-item class="wt-detail-form-item" label="所属角色" prop="roleIdList"
                      :rules="[ { required: true, message: '选择内容不能为空', validator: $wtValidator.ValidatorNotBlank, trigger: 'change' } ]">
          <el-select multiple placeholder="请选择角色" v-model="detailForm.roleIdList" :disabled="viewType == 'view'">
            <el-option v-for="item in roleList" :label="item.roleName" :value="item.id" :key="item.id"></el-option>
          </el-select>
        </el-form-item>
-->
        <!-- <el-form-item class="wt-detail-form-item" label="机构角色" prop="orgRoleList"
                      :rules="[ { required: true, message: '选择内容不能为空', validator: $wtValidator.ValidatorNotBlank, trigger: 'blur'} ]">
          <org-role-picker v-model="detailForm.orgRoleList" @on-confirm="onOrgRoleConfirm"/>
        </el-form-item> -->
        <el-form-item class="wt-detail-form-item" label="是否启用" prop="isPublish"
                      :rules="[{ required: true, message: '请选择启用状态', validator: $wtValidator.ValidatorNotBlank, trigger: 'change' }]">
          <el-switch v-model="detailForm.isPublish" :active-value="1" :inactive-value="0" :disabled="viewType == 'view'"></el-switch>
        </el-form-item>
        <el-form-item v-if="viewType == 'edit'" class="wt-detail-form-item" label="修改密码" prop="isModifyPassword">
          <el-switch v-model="detailForm.isModifyPassword" :active-value="1" :inactive-value="0"></el-switch>
        </el-form-item>
        <template v-if="viewType == 'add' || (viewType == 'edit' && detailForm.isModifyPassword == 1)">
          <el-form-item class="wt-detail-form-item" label="密码" prop="newPassword"
                        :rules="[ { required: true, message: '输入内容不能为空', validator: $wtValidator.ValidatorNotBlank, trigger: 'blur' },
                         { pattern: /^[\da-zA-Z\~\!\@\#\$\%\^\&\*\.\-\_\(\)]{6,32}$/, min: 6, max: 32, message: '请填写6-32位英文或数字或特殊字符', trigger: 'blur' }]">
            <el-input placeholder="请输入" v-model="detailForm.newPassword" show-password maxlength="32"></el-input>
          </el-form-item>
          <el-form-item class="wt-detail-form-item" label="确认密码" prop="confirmPassword"
                        :rules="[ { required: true, message: '输入内容不能为空', validator: $wtValidator.ValidatorNotBlank, trigger: 'blur' },
                        { message: '输入的密码和确认密码需要相同', validator: this.onValidatorPassword, trigger: 'blur' },
                         { pattern: /^[\da-zA-Z\~\!\@\#\$\%\^\&\*\.\-\_\(\)]{6,32}$/, min: 6, max: 32, message: '请填写6-32位英文或数字或特殊字符', trigger: 'blur' }]">
            <el-input placeholder="请输入" v-model="detailForm.confirmPassword" show-password maxlength="32"></el-input>
          </el-form-item>
        </template>
      </el-form>
    </div>
    <div slot="footer">
      <wt-button type="default" @click="onClickClose">关闭</wt-button>
      <wt-button type="primary" v-if="viewType == 'add'" v-has-any-premission="['SYSUSER:ADD','FGSROLELIST:ADD']" :loading="isFromLoading" @click="onClickAdd">保存</wt-button>
      <wt-button type="primary" v-if="viewType == 'edit'" v-has-any-premission="['SYSUSER:UPDATE','FGSROLELIST:UPDATE']" :loading="isFromLoading" @click="onClickEdit">保存</wt-button>
    </div>
  </wt-page>
</template>

<script>
import { mapState } from 'vuex'
// import orgRolePicker from './orgRolePicker'
export default {
  name: 'userDetail',
  isAutoComponent: true,
  components: {
    // orgRolePicker
  },
  props: ['detailData', 'viewType'],
  data() {
    const onValidatorPassword = (rule, value, callback) => {
      if (this.$wtUtil.isNotBlank(this.detailForm.newPassword) && this.detailForm.newPassword !== this.detailForm.confirmPassword) {
        callback(new Error())
      } else {
        callback()
      }
    }
    return {
      onValidatorPassword: onValidatorPassword,
      roleList: [],
      orgList: [],
      isFromLoading: false,
      detailForm: {
        isPublish: 1,
        isModifyPassword: 0,
        orgRoleList: [],
        orgId: '',
        roleId: ''
      },
      isCompany: false
    }
  },
  mounted() {
    this.requestOrgList()
    this.requestRoleList()
    if (this.viewType !== 'add') {
      this.requestDetail()
    }
  },
  methods: {
    onClickAdd() {
      const that = this
      this.$wtUtil.validateDetailFormsAndConfirm(this, '是否保存?').then(() => {
        that.requestAdd()
      }).catch(() => {
      })
    },
    onClickEdit() {
      const that = this
      this.$wtUtil.validateDetailFormsAndConfirm(this, '是否保存?').then(() => {
        that.requestSave()
      }).catch(() => {
      })
    },
    onClickClose() {
      this.$emit('on-close')
    },
    onOrgRoleConfirm({ orgId, roleId, orgRoleName }) {
      this.detailForm.orgRoleList.push({ orgId, roleId, orgRoleName })
    },
    // 获取角色
    requestRoleList() {
      const that = this
      this.$wtRequest({
        url: '/auth/roleListAll',
        method: 'post',
        data: { search: '' }
      }).then(res => {
        this.roleList = res.data.list
        if (that.isCompany) {
          this.roleList.push({ id: '2', roleName: '分公司管理员' })
        }
      })
    },
    // 获取公司
    requestOrgList() {
      this.$wtRequest({
        url: '/auth/orgQueryAll',
        method: 'post',
        data: { search: '' }
      }).then(res => {
        this.orgList = res.data.list
      })
    },
    // 查看详情
    requestDetail() {
      const that = this
      this.$wtRequest({
        url: '/auth/userDetail',
        method: 'post',
        data: {
          id: that.detailData.id
        }
      }).then(res => {
        this.detailForm = res.data
        if (that.viewType === 'edit' && res.data.roleId === 2) {
          that.isCompany = true
          that.roleList.push({ id: '2', roleName: '分公司管理员' })
        }
      })
    },
    requestSave() {
      const that = this
      that.isFromLoading = true
      const formData = { ...that.detailForm }
      that.$wtRequest({
        url: '/auth/userUpdate',
        method: 'post',
        data: formData
      }).then((resp) => {
        that.detailForm = resp.data || {}
        that.isFromLoading = false
        that.$message({ type: 'success', message: '保存成功' })
        that.$emit('on-close')
        that.$emit('on-refresh')
      }).catch(() => {
        that.isFromLoading = false
      })
    },
    requestAdd() {
      const that = this
      that.isFromLoading = true
      const formData = { ...that.detailForm }
      that.$wtRequest({
        url: '/auth/userAdd',
        method: 'post',
        data: formData
      }).then((resp) => {
        that.detailForm = resp.data || {}
        that.isFromLoading = false
        that.$emit('on-close')
        that.$emit('on-refresh')
      }).catch(() => {
        that.isFromLoading = false
      })
    }
  },
  computed: {
    ...mapState({})
  }
}
</script>
<style scoped lang="scss">
.wt-drawer-container {
  .wt-drawer-body {
    .wt-drawer-body-header-title {
      color: #97d0ff;
      font-size: 16px;
      margin: 32px 33px;
    }
  }
}
</style>
