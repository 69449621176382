<template>
  <div>
    <!--查询条件 开始-->
    <wt-data-form-search-form>
      <slot name="search" slot="search"></slot>
      <slot name="search-buttons" slot="buttons">
        <!-- <wt-button type="primary" :loading="form.isFromLoading" @click="onClickSearch">查询</wt-button> -->
        <wt-button type="primary" @click="onClickSearch">查询</wt-button>
        <wt-button @click="onClickReset" class="wt-reset-btn">重置</wt-button>
      </slot>
    </wt-data-form-search-form>
    <!--查询条件 结束-->
    <!--表格按钮 开始-->
    <wt-data-form-toolbar :form="form" v-if="$slots.toolbar">
      <slot name="toolbar"></slot>
    </wt-data-form-toolbar>
    <!--表格按钮 结束-->
    <!--表格 开始-->
    <div class="wt-table-container">
      <wt-form-table :form="form" @selection-change="handleSelectionChange">
        <slot name="table"></slot>
      </wt-form-table>
    </div>
    <!--表格 结束-->
    <!--分页 开始-->
    <wt-data-form-pagination v-if="!form.noPagination" :form="form" @request-form-list="requestDataList" :layout="form.pagination && form.pagination.layout ? form.pagination.layout : null" />
    <!--分页 结束-->
  </div>
</template>

<script>
import WtDataFormSearchForm from './WtDataFormSearchForm'
import WtDataFormToolbar from './WtDataFormToolbar'
import WtDataFormPagination from './WtDataFormPagination'
export default {
  name: 'WtDataForm',
  components: {
    WtDataFormSearchForm,
    WtDataFormToolbar,
    WtDataFormPagination
  },
  isAutoComponent: true,
  props: ['form', 'height', 'isFromLoading', 'requestListUrl', 'requestDeleteUrl', 'export'],
  data() {
    return {
      formData: {}
    }
  },
  created() {},
  methods: {
    onClickSearch() {
      this.formData = Object.assign({}, this.form.formData)
      this.form.pageData.currentPage = 1
      this.requestDataList()
    },
    onClickReset() {
      this.form.formData = Object.assign({}, this.form.extFormData)
      this.formData = this.form.formData
      this.requestDataList()
    },
    toView(rowData) {
      this.form.detailData = rowData
      this.form.detailViewType = 'view'
    },
    toAdd() {
      this.form.detailViewType = 'add'
    },
    toEdit(rowData) {
      this.form.detailData = rowData
      this.form.detailViewType = 'edit'
    },
    onClickDelete(dataId) {
      const that = this
      this.$confirm('是否删除?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        that.requestDataDelete([dataId])
      }).catch(() => {
      })
    },
    onClickMultipleDelete() {
      if (!this.form.formSelectedList || this.form.formSelectedList.length <= 0) {
        this.$alert('请选中至少一列数据', '提示', {
          type: 'error',
          confirmButtonText: '确定'
        }).then(() => {
        }).catch(() => {})
        return
      }
      const that = this
      this.$confirm('是否删除?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        const dataIdList = []
        that.form.formSelectedList.forEach((item) => {
          dataIdList.push(item.id)
        })
        that.requestDataDelete(dataIdList)
      }).catch(() => {
      })
    },
    handleSelectionChange(val) {
      this.form.formSelectedList = val
    },
    requestDataDelete(dataIdList) {
      if (this._events && this._events['on-request-delete']) {
        this.$emit('on-request-delete')
        return
      }
      const that = this
      that.form.isFromLoading = true
      that.$wtRequest({
        url: this.requestDeleteUrl,
        method: 'post',
        data: {
          list: dataIdList
        }
      }).then((resp) => {
        if (resp.isSuccess) {
          that.$message({ type: 'success', message: '删除成功' })
          that.requestDataList()
        } else {
          that.form.isFromLoading = false
        }
      }).catch(() => {
        that.form.isFromLoading = false
      })
    },
    requestDataList() {
      if (this._events && this._events['on-request-list']) {
        this.$emit('on-request-list')
        return
      }
      const that = this
      that.form.isFromLoading = true
      that.$wtRequest({
        url: this.requestListUrl,
        method: 'post',
        data: {
          formData: that.formData,
          pageData: that.form.pageData
        }
      }).then((resp) => {
        that.form.isFromLoading = false
        that.form.formResult = resp.data
        this.$emit('on-request-data', resp.data)
      }).catch(() => {
        that.form.isFromLoading = false
      })
    }
  },
  computed: {
    dataList() {
      if (this.form == null || this.form.formResult == null) {
        return []
      }
      return this.form.formResult.list
    }
  }
}
</script>

<style scoped>
.wt-table-container{flex: 1; width: 100%; overflow: hidden;}
</style>
