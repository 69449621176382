import { render, staticRenderFns } from "./WtNormalLayout.vue?vue&type=template&id=57b917be&scoped=true&"
import script from "./WtNormalLayout.vue?vue&type=script&lang=js&"
export * from "./WtNormalLayout.vue?vue&type=script&lang=js&"
import style0 from "./WtNormalLayout.vue?vue&type=style&index=0&id=57b917be&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "57b917be",
  null
  
)

export default component.exports