<template>
  <div class="wt-oss-upload" :class="className">
    <input :ref="componentName + uploadUuid" type="file" v-show="false" :accept="accept" :multiple="multiple" @change="onFilePickerChange">
    <div class="wt-oss-upload-btn" v-if="listType === 'list'" v-show="isUploadVisiable">
      <wt-button :loading="isLoading" type="primary" size="small" @click="onClickUpload">点击上传</wt-button>
    </div>
    <div class="wt-oss-upload-imagelist" v-if="listType === 'image'">
      <div class="wt-oss-upload-item-plus" :class="[isLoading ? 'item-loading' : '']" v-show="isUploadVisiable" v-loading="isLoading" @click="onClickUpload"><i class="el-icon-plus"></i></div>
      <div class="wt-oss-upload-item" v-for="(item, index) in dataList" :key="index">
        <el-image class="item-image" v-if="item.state === 'success'" :src="wtFileServerUrl + '' + item.fileUrl" fit="cover"></el-image>
        <div class="panel-progress" v-if="item.state === 'process'">
          <el-progress class="progress" type="circle" :percentage="item.percent" :width="80" :stroke-width="4"></el-progress>
        </div>
        <div class="panel" v-if="item.state === 'success'">
          <div class="panel-item" @click="onClickPreview(item)"><i class="el-icon-zoom-in"></i></div>
          <div class="panel-item" v-show="!readonly" @click="onFileListItemDelete(index)"><i class="el-icon-delete"></i></div>
        </div>
      </div>
    </div>
    <!--提示文字区域-->
    <div class="wt-oss-upload-tip">
      <template v-if="!readonly">
        <slot name="tip" v-if="$slots.tip"></slot>
        <span v-else-if="$wtUtil.isNotBlank(tip)">{{tip}}</span>
        <template v-else-if="isAutoTip">
          <span>注：</span>
          <template v-if="$wtUtil.isNotBlank(tipProps.fileType)"><span>只能上传</span><span class="important-text">{{tipProps.fileType}}</span><span>文件，</span></template>
          <template v-if="$wtUtil.isNotBlank(tipProps.fileLimit)"><span>建议不超过</span><span class="important-text">{{tipProps.fileLimit}}</span><span>。</span></template>
          <!-- <span>上传区域出现</span><span class="important-text">图片</span><span>表示上传完成。</span><br/> -->
          <template v-if="$wtUtil.isNotBlank(tipProps.fileSize)"><span>建议尺寸：</span><span class="important-text">{{tipProps.fileSize}}</span></template>
        </template>
      </template>
    </div>
    <!--文件列表-->
    <div class="wt-oss-upload-filelist" v-if="listType === 'list' && dataList.length > 0">
      <div class="wt-oss-upload-item" v-for="(item, index) in dataList" :key="index">
        <div class="content">
          <div class="title link-title" @click="onClickPreview(item)"><i class="el-icon-document"></i><span>{{item.fileName}}</span></div>
          <div class="panel">
            <div class="panel-btn" v-if="item.state === 'success'" v-show="!readonly" @click="onFileListItemDelete(index)"><i class="el-icon-close"></i></div>
            <span class="panel-text" v-if="item.state === 'process'">{{item.percent}}%</span>
          </div>
        </div>
        <el-progress class="progress" v-show="item.state === 'process'" :percentage="item.percent" :show-text="false" :stroke-width="4"></el-progress>
      </div>
    </div>
    <!-- 文件查看-->
    <teleport to="body">
      <el-image-viewer v-if="isViewerVisiable" :hide-on-click-modal="true"
                       :initial-index="previewIndex" :url-list="previewSrcList"
                       @close="isViewerVisiable = false" :z-index="3000">
      </el-image-viewer>
    </teleport>
  </div>
</template>

<script>
import asyncPool from './asyncPool'
import OSS from 'ali-oss'
import ElImageViewer from 'element-ui/packages/image/src/image-viewer'
import ObsClient from 'esdk-obs-browserjs'

// eslint-disable-next-line no-unused-vars
const FILE_STATE = {
  PROCESS: 'process',
  FAIL: 'fail',
  SUCCESS: 'success'
}
const printLog = (...args) => {
  // console.log(...args)
}

export default {
  name: 'wt-file-upload',
  components: {
    ElImageViewer
  },
  data() {
    return {
      wtFileServerUrl: process.env.VUE_APP_FILE_SERVER_URL,
      componentName: 'wt-oss-upload-',
      uploadUuid: this.$wtUtil.uuid(),
      assumeRoleCredentials: {},
      isLoading: false,
      isViewerVisiable: false,
      previewSrcList: [],
      previewIndex: 0,
      ossClientMap: {},
      dataList: [],
      uploadDataList: []
    }
  },
  methods: {
    updateFileList(fileDataList) {
      const that = this
      if (!fileDataList) {
        return
      }
      if (that.dataType === 'list') {
        const fileList = []
        fileDataList.forEach(item => {
          fileList.push({ fileId: that.$wtUtil.uuid(), fileName: item.fileName, fileUrl: item.fileUrl, state: FILE_STATE.SUCCESS })
        })
        that.dataList = fileList
      } else if (that.dataType === 'url') {
        that.dataList = [{ fileId: that.$wtUtil.uuid(), fileName: fileDataList, fileUrl: fileDataList, state: FILE_STATE.SUCCESS }]
      } else if (that.dataType === 'url-list') {
        const fileList = []
        fileDataList.forEach(item => {
          fileList.push({ fileId: that.$wtUtil.uuid(), fileName: item, fileUrl: item, state: FILE_STATE.SUCCESS })
        })
        that.dataList = fileList
      }
    },
    onClickUpload() {
      if (!this.isLoading) {
        this.$refs[this.componentName + this.uploadUuid].click()
      }
    },
    onFilePickerChange(event) {
      const that = this
      const componentRef = this.$refs[this.componentName + this.uploadUuid]
      try {
        const files = event.target.files
        if (!files || files.length <= 0) {
          throw new Error('请选择要上传的文件')
        }
        if (this.dataType === 'singe' && this.dataList.length >= 1) {
          throw new Error('最多可以上传1个文件')
        }
        //  检查文件数量
        if (this.max && this.max < this.dataList.length + files.length) {
          throw new Error(`最多可以上传${this.max}个文件`)
        }
        //  检查单文件大小
        const fileLimit = that.fileLimit
        for (let i = 0; i < files.length; i++) {
          const fileItem = files[i]
          const fileSize = fileItem.size
          const fileName = fileItem.name
          if (fileSize > this.fileLimit) {
            throw new Error(`文件 ${fileName} (${this.$wtUtil.covertByteToString(fileSize)}) 超过单文件大小限制 ${this.$wtUtil.covertByteToString(fileLimit)}`)
          }
        }
        //  添加文件到待上传列表
        const fileList = []
        for (let i = 0; i < files.length; i++) {
          const file = files[i]
          file.fileId = this.$wtUtil.uuid()
          file.fileName = file.name
          file.state = FILE_STATE.PROCESS
          file.percent = 0
          fileList.push(file)
        }
        that.dataList = that.dataList.concat(fileList)
        that.isLoading = true
        that.$wtUtil.getFileListMd5(fileList).then((md5FileList) => {
          return that.startCheckFileList(md5FileList)
        }).then((md5FileList) => {
          return that.startCheckStsAssumeRole(md5FileList)
        }).then((md5FileList) => {
          return that.startUploadFileList(md5FileList)
        }).then((md5FileList) => {
          that.isLoading = false
          return that.requestFileCompleteNotify(md5FileList)
        }).catch((errItem) => {
          that.isLoading = false
          that.$message.error(`文件 ${errItem.name} 上传出错`)
        })
      } catch (e) {
        that.isLoading = false
        that.$message.error(e.message)
      }
      componentRef.value = null
    },
    onClickPreview(item) {
      const fileEnd = (item.fileName.lastIndexOf('.') !== -1 ? item.fileName.substring(item.fileName.lastIndexOf('.')) : '').toLowerCase()
      if (fileEnd.endsWith('.pdf') || fileEnd.endsWith('.xls') || fileEnd.endsWith('.xlsx') || fileEnd.endsWith('.doc') || fileEnd.endsWith('.docx')) {
        window.open(this.wtFileServerUrl + '/' + item.fileUrl, '_blank')
      } else if (fileEnd.endsWith('jpeg') || fileEnd.endsWith('jpg') || fileEnd.endsWith('png') || fileEnd.endsWith('bmp')) {
        const itemFileUrl = this.wtFileServerUrl + '/' + item.fileUrl
        const previewItemList = []
        this.dataList.forEach((fileItem) => {
          if (fileItem.fileUrl.endsWith('jpeg') || fileItem.fileUrl.endsWith('jpg') || fileItem.fileUrl.endsWith('png') || fileItem.fileUrl.endsWith('bmp')) {
            previewItemList.push(this.wtFileServerUrl + '/' + fileItem.fileUrl)
          }
        })
        this.previewSrcList = previewItemList
        this.previewIndex = Math.max(previewItemList.indexOf(itemFileUrl), 0)
        this.isViewerVisiable = true
      } else {
        window.open(this.wtFileServerUrl + '/' + item.url, '_blank')
      }
    },
    startUploadFileList(md5FileList) {
      const that = this
      if (!md5FileList || md5FileList.length <= 0) {
        return new Promise((resolve) => {
          resolve([])
        })
      }
      printLog('>>>> startUploadFileList md5FileList', md5FileList)
      return asyncPool(that.maxFileUploadNum, md5FileList, that.uploadOneFile)
    },
    uploadOneFile(md5File) {
      const that = this
      // eslint-disable-next-line no-unused-vars
      console.log(md5File)
      const obsClient = new ObsClient({
        access_key_id: 'AXEJG9H24FG47MZ0AG5U',
        secret_access_key: 'Z5DskzUhl31BRRUg9m6c7iIQEs8hydy7lVGsqbr1',
        //这里以华北-北京四为例，其他地区请按实际情况填写
        server: 'https://obs.cn-north-4.myhuaweicloud.com'
      });
      // const client = new OSS({
      //   bucket: that.assumeRoleCredentials.bucket,
      //   region: that.assumeRoleCredentials.region,
      //   accessKeyId: that.assumeRoleCredentials.accessKeyId,
      //   accessKeySecret: that.assumeRoleCredentials.accessKeySecret,
      //   stsToken: that.assumeRoleCredentials.securityToken,
      //   refreshSTSToken: async() => {
      //     await that.requestStsAssumeRole()
      //     return that.assumeRoleCredentials
      //   },
      //   refreshSTSTokenInterval: 1000 * 3000
      // })
      return new Promise((resolve, reject) => {
        const fileMd5 = md5File.md5
        const fileName = md5File.fileName
        const basePath = that.$wtUtil.isNotBlank(that.assumeRoleCredentials.ossPath) ? that.assumeRoleCredentials.ossPath + '/' : ''
        const ossFileEnd = fileName.lastIndexOf('.') !== -1 ? fileName.substring(fileName.lastIndexOf('.')) : ''
        const ossFilePath = fileMd5.substring(0, 2) + '/' + fileMd5.substring(2, 4) + '/'
        const ossFileName = basePath + ossFilePath + fileMd5 + ossFileEnd
        // console.log(fileMd5 + ossFileEnd)
        const filePath = fileMd5 + ossFileEnd
        const callback = function(transferredAmount, totalAmount){
          // 获取上传进度百分比
          console.log(transferredAmount * 100.0 / totalAmount);
          const percent = Math.min(Math.floor((transferredAmount * 100.0 / totalAmount), 100))
          that.updateFileItemByMD5({ md5: md5File.md5, state: FILE_STATE.PROCESS, percent: percent })
        };
        obsClient.putObject({
          Bucket: 'wangtiansoft-prod',
          Key: filePath,
          SourceFile: md5File,
          ProgressCallback: callback
        }, function (err, result) {
          if(err){
            console.error('Error-->' + err);
          }else{
            console.log('Status-->', result);
            that.updateFileItemByMD5({ md5: md5File.md5, state: FILE_STATE.SUCCESS, percent: 100, fileUrl: filePath })
            resolve(md5File);
          }
        });
        // client.multipartUpload(ossFileName, md5File, {
        //   progress: (p, checkpoint) => {
        //     const percent = Math.min(Math.floor(p * 100, 100))
        //     that.updateFileItemByMD5({ md5: md5File.md5, state: FILE_STATE.PROCESS, percent: percent })
        //   }
        // }).then((resp) => {
        //   that.updateFileItemByMD5({ md5: md5File.md5, state: FILE_STATE.SUCCESS, percent: 100, fileUrl: resp.name })
        //   resolve(md5File)
        // })
      })
      // 测试用
      // return new Promise((resolve, reject) => {
      //   setTimeout(() => {
      //     that.updateFileItemByMD5({ md5: md5File.md5, state: FILE_STATE.SUCCESS, percent: 100, fileUrl: 'example.jpeg' })
      //     resolve(md5File)
      //   }, 1000 + Math.random() * 2000)
      // })
    },
    startCheckFileList(md5FileList) {
      printLog('>>>> startCheckFileList md5FileList', md5FileList)
      const that = this
      const md5List = []
      md5FileList.forEach((md5File) => {
        md5List.push(md5File.md5)
      })
      return new Promise((resolve, reject) => {
        that.$wtRequest({
          url: '/file/checkFiles',
          method: 'post',
          data: { md5List: md5List }
        }).then(resp => {
          if (resp.code !== '0') {
            reject(new Error('检查文件失败'))
          }
          if (resp.data && resp.data.length > 0) {
            const itemMd5Map = {}
            resp.data.forEach(item => {
              itemMd5Map[item.fileMd5] = item.fileUrl
              that.updateFileItemByMD5({ md5: item.fileMd5, state: FILE_STATE.SUCCESS, percent: 100, fileUrl: item.fileUrl })
            })
            md5FileList.forEach((md5File) => {
              md5File.fileUrl = itemMd5Map[md5File.md5]
            })
          }
          const resultMd5FileList = that.getProcessMd5FileList(md5FileList)
          resolve(resultMd5FileList)
        }).catch((err) => {
          reject(err)
        })
      })
    },
    //  获取sts角色
    startCheckStsAssumeRole(md5FileList) {
      const that = this
      if (!md5FileList || md5FileList.length <= 0) {
        return new Promise((resolve) => {
          resolve([])
        })
      }
      printLog('>>>> startCheckStsAssumeRole', md5FileList)
      let isNeedRefreshStsAssumeRole = false
      const assumeRoleCredentialsStr = sessionStorage.getItem('STS:CRED') || '{}'
      that.assumeRoleCredentials = JSON.parse(assumeRoleCredentialsStr)
      if (!that.assumeRoleCredentials.expiration) {
        isNeedRefreshStsAssumeRole = true
      } else {
        const expiration = new Date(that.assumeRoleCredentials.expiration)
        const now = new Date()
        isNeedRefreshStsAssumeRole = (expiration.getTime() - now.getTime() < 1000 * 30)
      }
      if (isNeedRefreshStsAssumeRole) {
        return that.requestStsAssumeRole(md5FileList)
      }
      return new Promise((resolve, reject) => {
        resolve(md5FileList)
      })
    },
    requestStsAssumeRole(md5FileList) {
      const that = this
      return new Promise((resolve, reject) => {
        that.$wtRequest({
          url: '/file/stsGrantAuthorization',
          method: 'post',
          data: {}
        }).then(resp => {
          if (resp.code === '0') {
            that.assumeRoleCredentials = resp.data
            sessionStorage.setItem('STS:CRED', JSON.stringify(resp.data))
            resolve(md5FileList)
          } else {
            reject(new Error('获取上传凭据失败'))
          }
        }).catch((e) => {
          reject(e)
        })
      })
    },
    requestFileCompleteNotify(md5FileList) {
      if (!md5FileList || md5FileList.length <= 0) {
        return new Promise((resolve) => {
          resolve([])
        })
      }
      printLog('>>>> requestFileCompleteNotify md5FileList', md5FileList)
      const that = this
      const notifyFormData = {}
      md5FileList.forEach(item => {
        notifyFormData[item.md5] = item.fileUrl
      })
      return new Promise((resolve, reject) => {
        that.$wtRequest({
          url: '/file/uploadCompleteNotify',
          method: 'post',
          data: notifyFormData
        }).then(resp => {
          resolve(md5FileList)
        }).catch((e) => {
          reject(e)
        })
      })
    },
    onFileListItemDelete(index) {
      const that = this
      printLog('\tonFileListItemDelete', that.dataList, index)
      if (that.dataList.length > index) {
        that.dataList.splice(index, 1)
        const tempModelValue = []
        that.dataList.forEach(item => {
          tempModelValue.push({ fileName: item.fileName, fileUrl: item.fileUrl })
        })
        that.dataValue = tempModelValue
      }
    },
    updateFileItemByMD5({ md5, state, percent, fileUrl }) {
      printLog('\tupdateFileItemByMD5', md5, state, percent)
      const that = this
      const tempDataList = []
      const tempModelValue = []
      that.dataList.forEach((item, itemIndex) => {
        if (item.md5 === md5) {
          item.state = state
          item.percent = percent
          if (fileUrl) {
            item.fileUrl = fileUrl
          }
        }
        if (item.state === FILE_STATE.SUCCESS) {
          tempModelValue.push({ fileName: item.fileName, fileUrl: item.fileUrl })
        }
        tempDataList.push(item)
      })
      that.dataList = tempDataList
      if (state === FILE_STATE.SUCCESS) {
        that.dataValue = tempModelValue
        if (that.$parent && that.$parent.clearValidate) {
          that.$parent.clearValidate()
        }
      }
    },
    getProcessMd5FileList(md5FileList) {
      const resultList = []
      const that = this
      md5FileList.forEach(md5File => {
        if (that.$wtUtil.isBlank(md5File.fileUrl)) {
          resultList.push(md5File)
        }
      })
      return resultList
    }
  },
  computed: {
    isUploadVisiable() {
      return this.dataList.length < this.max && !this.readonly
    },
    dataValue: {
      get() {
        return this.value
      },
      set(val) {
        printLog('dataValue set', val, JSON.stringify(val))
        const that = this
        if (that.dataType === 'list') {
          const fileList = []
          val.forEach(item => {
            fileList.push({ fileId: that.$wtUtil.uuid(), fileName: item.fileName, fileUrl: item.fileUrl, state: FILE_STATE.SUCCESS })
          })
          that.dataList = fileList
          this.$emit('input', fileList)
        } else if (that.dataType === 'url') {
          const url = (val && val.length > 0 ? val[0].fileUrl : '')
          if (that.$wtUtil.isBlank(url)) {
            that.dataList = []
          } else {
            that.dataList = [{ fileId: that.$wtUtil.uuid(), fileName: url, fileUrl: url, state: FILE_STATE.SUCCESS }]
          }
          this.$emit('input', url)
        } else if (that.dataType === 'url-list') {
          const fileList = []
          const fileUrlList = []
          val.forEach(item => {
            fileList.push({ fileId: that.$wtUtil.uuid(), fileName: item, fileUrl: item, state: FILE_STATE.SUCCESS })
            fileUrlList.push(item)
          })
          that.dataList = fileList
          this.$emit('input', fileUrlList)
        }
      }
    }
  },
  props: {
    value: {
      type: [Object, String, Array]
    },
    // 组件展示类型， list为默认列表形式, image为图片形式
    listType: {
      type: String,
      default: 'list'
    },
    //  数据类型， list表示json数组如[{ fileName: '', fileUrl: '' }]，url-list表示url数组['']，url表示只返回url
    dataType: {
      type: String,
      default: 'list'
    },
    // 上传个数限制
    max: {
      type: Number,
      default: 9
    },
    fileLimit: {
      type: Number,
      default: 1024 * 1024 * 100
    },
    className: {
      type: String,
      default: ''
    },
    //  同时上传文件的最大数量
    maxFileUploadNum: {
      type: Number,
      default: 1
    },
    tip: {
      type: String,
      default: ''
    },
    //  是否自动生成提示文字
    isAutoTip: {
      type: Boolean,
      default: true
    },
    tipProps: {
      type: Object,
      default: () => {
        return {
          fileType: '', //  jpg,png
          fileLimit: '', //  200kb
          fileSize: '' //  1200*2400
        }
      }
    },
    // 接受上传的文件类型(/分割) 支持：'jpg','png','txt','zip', 'rar','pdf','doc','docx','xlsx', 'mp4'
    accept: {
      type: String,
      default: '*'
    },
    multiple: {
      type: Boolean,
      default: true
    },
    readonly: {
      type: Boolean,
      default: false
    },
    // 是否禁用
    disabled: {
      type: Boolean,
      default: false
    }
  }
}
</script>

<style scoped lang="scss">
  .wt-oss-upload{display: flex;flex-direction: column;}
  .wt-oss-upload .wt-oss-upload-btn{margin-bottom: 0px;}
  .wt-oss-upload .wt-oss-upload-preview-container{width: 0px;height: 0px;overflow: hidden;}

  .wt-oss-upload .wt-oss-upload-filelist{display: flex;flex-direction: column;margin: 8px 0;}
  .wt-oss-upload .wt-oss-upload-filelist .wt-oss-upload-item{width: 100%;max-width: 450px;display: flex;flex-direction: column; justify-content: center;margin-bottom: 4px;}
  .wt-oss-upload .wt-oss-upload-filelist .wt-oss-upload-item:hover{}
  .wt-oss-upload .wt-oss-upload-filelist .wt-oss-upload-item .content{width: 100%;display: flex;justify-content: space-between;color: #666666;font-size: 14px;}
  .wt-oss-upload .wt-oss-upload-filelist .wt-oss-upload-item .content .title{width: calc(100% - 50px); padding-left: 4px;overflow: hidden;text-overflow: ellipsis;transition: color .3s;white-space: nowrap;line-height: 25px;}
  .wt-oss-upload .wt-oss-upload-filelist .wt-oss-upload-item .content .title span{margin-left: 4px;}
  .wt-oss-upload .wt-oss-upload-filelist .wt-oss-upload-item .content .link-title{cursor: pointer;}
  .wt-oss-upload .wt-oss-upload-filelist .wt-oss-upload-item .content .panel{width: 50px;display: flex;align-items: center;justify-content: flex-end;}
  .wt-oss-upload .wt-oss-upload-filelist .wt-oss-upload-item .content .panel .panel-text{margin-right: 4px;}
  .wt-oss-upload .wt-oss-upload-filelist .wt-oss-upload-item .content .panel .panel-btn{width: 25px;height: 25px;cursor: pointer;display: flex;align-items: center;}
  .wt-oss-upload .wt-oss-upload-filelist .wt-oss-upload-item .content .panel .panel-btn i{font-size: 16px;}
  .wt-oss-upload .wt-oss-upload-filelist .wt-oss-upload-item .progress{width: 100%;}

  .wt-oss-upload-imagelist{display: flex;flex-wrap: wrap;margin: 8px 0;}
  .wt-oss-upload-imagelist .wt-oss-upload-item{width: 100px;height: 100px;border: 1px solid #CCCCCC;border-radius: 4px;position: relative;margin-right: 12px;}
  .wt-oss-upload-imagelist .wt-oss-upload-item:hover .panel{display: flex;background-color: rgba(0, 0, 0, 0.4);}
  .wt-oss-upload-imagelist .wt-oss-upload-item .item-image{width: 100%;height: 100%;}
  .wt-oss-upload-imagelist .wt-oss-upload-item .panel{width: 100%;height: 100px;position: absolute; left: 0;top: 0; display: none;justify-content: center;align-items: center;}
  .wt-oss-upload-imagelist .wt-oss-upload-item .panel .panel-item{width: 33px; height: 33px;display: flex;align-items: center;justify-content: center;cursor: pointer;}
  .wt-oss-upload-imagelist .wt-oss-upload-item .panel .panel-item:active{opacity: 0.7;}
  .wt-oss-upload-imagelist .wt-oss-upload-item .panel .panel-item i{color: #FFFFFF;}
  .wt-oss-upload-imagelist .wt-oss-upload-item .panel-progress{width: 100%;height: 100px;position: absolute; left: 0;top: 0; display: flex; justify-content: center;align-items: center;}
  .wt-oss-upload-imagelist .wt-oss-upload-item .panel-progress .progress{width: 80%;height: 80%;}

  .wt-oss-upload-imagelist .wt-oss-upload-item-plus{width: 100px;height: 100px;border: 1px dashed #CCCCCC;border-radius: 4px;margin-right: 12px;display: flex;align-items: center;justify-content: center;cursor: pointer;}
  .wt-oss-upload-imagelist .wt-oss-upload-item-plus:hover{border: 1px dashed #409eff;}
  .wt-oss-upload-imagelist .wt-oss-upload-item-plus:hover .el-icon-plus{color: #409eff;}
  .wt-oss-upload-imagelist .item-loading{border: 1px dashed #CCCCCC!important;cursor: default!important;;}
  .wt-oss-upload-imagelist .item-loading .el-icon-plus{color: #CCCCCC!important;}

  .wt-oss-upload .wt-oss-upload-tip{font-size: 12px;line-height: 20px; color: #666;min-height: 4px;padding: 4px 0;}
  .wt-oss-upload .wt-oss-upload-tip .important-text{color: #ea2d4b;margin: 0 4px;}
</style>
