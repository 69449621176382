<template>
  <div class="page-container">
    <wt-data-form class="wt-table-full" ref="dataForm" :form.sync="form"
                  request-list-url="/sysLog/list"
                  request-delete-url=""
                  request-detail-url="">
      <template slot="search">
        <wt-search-form-item title="用户名" v-model="form.formData.accoutName" type="input"/>
        <wt-search-form-item title="操作时间" v-model="form.formData.createTimeRange"  @on-input-change="dateChange" type="daterange"/>
        <wt-search-form-item title="功能模块" v-model="form.formData.logModule" type="select" :dataList="logModuleOptions"/>
      </template>
      <template slot="table">
        <el-table-column type="selection" width="55"></el-table-column>
        <el-table-column prop="logModule" label="功能模块"></el-table-column>
        <el-table-column prop="logContent" label="操作"></el-table-column>
        <el-table-column prop="username" label="用户名"></el-table-column>
        <el-table-column prop="logIp" label="IP"></el-table-column>
        <el-table-column prop="createTime" label="操作时间"></el-table-column>
      </template>
    </wt-data-form>
  </div>
</template>

<script>
import { mapState } from 'vuex'

export default {
  name: 'rzglInfo',
  data() {
    return {
      form: {
        isFromLoading: false,
        detailViewType: 'edit',
        detailData: {},
        extFormData: {},
        formData: {
          accoutName: '',
          createTimeRange: [],
          logMethodName: '',
          logModule: ''
        },
        pageData: { currentPage: 1, pageSize: 20, sortName: '', sortOrder: '' },
        formSelectedList: [],
        formResult: {}
      },
      detailForm: {
        name: '',
        file: null
      },
      fileList: [],
      logModuleOptions: []
    }
  },
  mounted() {
    this.queryLogModule()
    this.$refs.dataForm.requestDataList()
  },
  created() {
  },
  methods: {
    fileReady(file) {
      console.log('change', file)
      this.detailForm.file = file
    },
    fileInput(data) {
      console.log('input==', data)
      this.detailForm.file = data.fileUrl
    },
    dateChange() {
      console.log('change')
    },
    onModuleCurrentChanged(nodeData, node) {
      console.log(nodeData, node)
      this.form.formData.checkedModuleCodes = node.checkedKeys
      this.$nextTick(() => {
        this.$refs.dataForm.onClickSearch()
      })
    },
    onClickDownload(rowData) {
      window.open('rowData.url', '_blank')
    },
    queryLogModule() {
      this.$wtRequest({
        url: '/sysLog/logModule',
        method: 'post',
        data: {}
      }).then(res => {
        if (res.code === '0') {
          this.logModuleOptions = res.data.list.map((x, idx) => {
            const nx = {
              label: x,
              value: x
            }
            return nx
          })
        }
      })
    }
  },
  computed: {
    ...mapState({})
  }
}
</script>
<style lang="scss">
  .page-container {
  }
  .message-override {
    z-index: 3000 !important;
  }
</style>
