<template>
  <div class="page-container">
    <wt-data-form class="wt-table-full" ref="dataForm" :form.sync="form"
                  request-list-url="/auth/orgList"
                  request-delete-url="/auth/orgDelete"
                  request-detail-url="/auth/orgDetail">
      <template slot="search">
        <wt-search-form-item title="公司名称" v-model="form.formData.name" type="input"/>
        <wt-search-form-item title="公司编号" v-model="form.formData.code" type="input"/>
        <wt-search-form-item title="所属区域" v-model="form.formData.area" type="select" :dataList="areaList"/>
        <!-- <wt-search-form-item title="所属角色" v-model="form.formData.roleIdList" type="multi-select" :multipleLimit="5" :dataList="roleList"/>
        <wt-search-form-item title="账号状态" v-model="form.formData.isPublish" type="select" :dataList="$wtConst.ISPUBLISH_ENUM_OPTIONS"/> -->
      </template>
      <template slot="toolbar">
        <wt-button type="primary" v-has-any-premission="['SYSUSER:ADD']" @click="onClickAdd">新建</wt-button>
        <wt-button type="" v-has-any-premission="['SYSROLE:ADD']" @click="onClickMultDelete">删除</wt-button>
      </template>
      <template slot="table">
        <el-table-column type="selection" width="55"></el-table-column>
        <el-table-column type="index" label="序号"></el-table-column>
        <el-table-column prop="name" label="公司名称"></el-table-column>
        <el-table-column prop="code" label="公司编号" sortable></el-table-column>
        <el-table-column label="所属区域">
          <template slot-scope="scope">
            <div>{{areaName(scope.row.area)}}</div>
          </template>
        </el-table-column>
        <el-table-column prop="isPublish" label="状态">
          <template slot-scope="scope"><wt-enum-content v-bind="$wtConst.ISPUBLISH_ENUM_CONTENT[scope.row.isPublish]"></wt-enum-content></template>
        </el-table-column>
        <el-table-column prop="createUserName" label="创建人"></el-table-column>
        <el-table-column prop="createTime" label="创建时间" :sortable='true'></el-table-column>
        <el-table-column label="操作" fixed="right" width="150">
          <template slot-scope="scope">
            <!-- <wt-text-button v-has-any-premission="['SYSUSER:VIEW']" @click="onClickView(scope.row)">查看</wt-text-button> -->
            <wt-text-button v-has-any-premission="['SYSUSER:UPDATE']" @click="onClickEdit(scope.row)">编辑</wt-text-button>
            <wt-text-button v-if="scope.row.isPublish === 1" v-has-any-premission="['SYSUSER:UPDATE']" @click="onClickStatus(scope.row, 0)">禁用</wt-text-button>
            <wt-text-button v-else v-has-any-premission="['SYSUSER:UPDATE']" @click="onClickStatus(scope.row, 1)">启用</wt-text-button>
            <!-- <wt-text-button v-if="scope.row.isPublish === 0" v-has-any-premission="['SYSUSER:DELETE']" @click="onClickDelete(scope.row)" class="danger">删除</wt-text-button> -->
          </template>
        </el-table-column>
      </template>
    </wt-data-form>
    <!--弹层 开始-->
    <wt-page-dialog ref="editDrawer" :title="viewTitle">
      <org-detail :detail-data="form.detailData" :view-type="form.dialogViewType"
                    @on-close="$refs.editDrawer.close()" @on-refresh="$refs.dataForm.requestDataList()"></org-detail>
    </wt-page-dialog>
    <!--弹层 结束-->
  </div>
</template>

<script>
import { mapState } from 'vuex'

export default {
  name: 'orgList',
  data() {
    return {
      form: {
        table: { flag: 'result' },
        isFromLoading: false,
        isViewDialogVisible: false,
        isEditDialogVisible: false,
        dialogViewType: 'view',
        detailData: {},
        formData: {
          // isPublish: '',
          // username: '',
          // realname: '',
          // roleIdList: []
          name: '',
          code: '',
          area: ''
        },
        pageData: { currentPage: 1, pageSize: 20, sortName: '', sortOrder: '' },
        formResult: {}
      },
      roleList: [],
      viewTitle: '新增架构',
      areaList: [
        {
          'value': '北京区域', 'label': '北京区域'
        },
        {
          'value': '新疆区域', 'label': '新疆区域'
        },
        {
          'value': '广东区域', 'label': '广东区域'
        },
        {
          'value': '辽宁区域', 'label': '辽宁区域'
        },
        {
          'value': '江苏区域', 'label': '江苏区域'
        },
        {
          'value': '山东区域', 'label': '山东区域'
        },
        {
          'value': '上海区域', 'label': '上海区域'
        },
        {
          'value': '湖北区域', 'label': '湖北区域'
        },
        {
          'value': '四川区域', 'label': '四川区域'
        },
        {
          'value': '广西区域', 'label': '广西区域'
        },
        {
          'value': '河北区域', 'label': '河北区域'
        },
        {
          'value': '解放军总医院', 'label': '解放军总医院'
        }
      ]
    }
  },
  mounted() {
    this.$refs.dataForm.requestDataList()
    // this.getRoleList()
  },
  created() {
  },
  methods: {
    areaName(obj) {
      let name = ''
      if (obj && this.areaList.filter(x => x.value === obj).length > 0) {
        name = this.areaList.filter(x => x.value === obj)[0].label
      }
      return name
    },
    onClickStatus(rowData, status) {
      const that = this
      that.isFromLoading = true
      const formData = {
        id: rowData.id,
        isPublish: status
      }
      that.$wtRequest({
        url: '/auth/orgUpdate',
        method: 'post',
        data: formData
      }).then((resp) => {
        that.isFromLoading = false
        this.$refs.dataForm.requestDataList()
      }).catch(() => {
        that.isFromLoading = false
      })
    },
    onClickView(rowData) {
      this.form.dialogViewType = 'view'
      this.form.detailData = rowData
      this.$refs.editDrawer.open()
    },
    onClickEdit(rowData) {
      this.form.dialogViewType = 'edit'
      this.form.detailData = rowData
      this.$refs.editDrawer.open()
    },
    onClickAdd() {
      this.form.dialogViewType = 'add'
      this.$refs.editDrawer.open()
    },
    onClickMultDelete() {
      this.$refs.dataForm.onClickMultipleDelete()
    },
    onClickDelete(rowData) {
      this.$confirm('此操作将永久删除账号, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        this.$wtRequest({
          url: '/auth/userDelete',
          method: 'post',
          data: {
            list: [rowData.id]
          }
        }).then(res => {
          if (res.code === '0') {
            this.$message({ type: 'success', message: '删除成功' })
            this.$refs.dataForm.requestDataList()
          }
        })
      }).catch(() => {
        this.$message({ type: 'success', message: '删除失败' })
      })
    },
    // 获取角色
    async getRoleList() {
      this.roleList = await this.$store.dispatch('getRoleList')
    }
  },
  computed: {
    ...mapState({})
  }
}
</script>
