<template>
  <div class="wt-container">
    <div>
      <h1>{{testValue}}</h1>
    </div>
    <div>
      <el-button type="primary">测试</el-button>
    </div>
    <div>
      <el-button type="primary">测试</el-button>
      <el-button type="primary" @click="onClickExport">导出</el-button>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Test',
  data() {
    return {
      testValue: ''
    }
  },
  mounted() {
    this.testValue = this.$route.name
  },
  methods: {
    onClickExport() {
      const that = this
      this.$wtUtil.downloadFile({
        url: '/sample/excel/exportExcel',
        fileName: 'ssdsdf.xls'
      }).then((resp) => {
      }).catch((e) => {
        that.$message({ type: 'error', message: '导出失败，请稍后再试' })
      })
    }
  }
}
</script>

<style rel="stylesheet/scss" lang="scss" scoped>
</style>
