<template>
  <div>
  <wt-page>
    <div class="wt-page-container" v-loading="isFromLoading">
      <div class="page-detail">
        <el-form :model="detailForm" ref="addTeamForm" class="wt-page-form" label-width="120px" size="small">
          <div class="form-list">
            <div class="form-item">
              <el-form-item label="小组组名：" prop="name" :rules="[ { required: true, message: '小组组名不能为空', validator: $wtValidator.ValidatorNotBlank, trigger: 'blur' }]">
                <el-input v-model="detailForm.name" placeholder="请输入"></el-input>
              </el-form-item>
            </div>
          </div>
          <div class="form-list" v-if="typeOptions.length > 0">
            <div class="form-item" v-for="to in typeOptions" :key="to.code">
              <el-form-item :label="`${to.name}范围：`">
                <el-select v-model="detailForm[to.code]" multiple placeholder="请选择">
                  <el-option
                    v-for="item in to.sonList"
                    :key="item.code"
                    :label="item.name"
                    :value="item.code">
                  </el-option>
                </el-select>
              </el-form-item>
            </div>
          </div>
          <div class="team-table">
            <div class="team-row team-header">
              <div class="title">角色</div>
              <div class="content">手机号</div>
              <div class="name">姓名</div>
            </div>
            <div class="team-row">
              <div class="team-row-left">
              <el-form-item label="初验人：" prop="firstAccount" :rules="[ { required: true, message: '初验人不能为空', validator: $wtValidator.ValidatorNotBlank, trigger: 'blur' }]">
                <el-input v-model="detailForm.firstAccount" placeholder="请输入"></el-input>
              </el-form-item>
              </div>
              <div class="team-row-right">
                <el-input v-model="detailForm.firstName" placeholder="请输入" ></el-input>
              </div>
            </div>
            <div class="team-row">
              <div class="team-row-left">
              <el-form-item label="初验核定人：" prop="firstAuditAccount" :rules="[ { required: true, message: '初验核定人不能为空', validator: $wtValidator.ValidatorNotBlank, trigger: 'blur' }]">
                <el-input v-model="detailForm.firstAuditAccount" placeholder="请输入"></el-input>
              </el-form-item>
              </div>
              <div class="team-row-right">
                <el-input v-model="detailForm.firstAuditName" placeholder="请输入"></el-input>
              </div>
            </div>
            <div class="team-row">
              <div class="team-row-left">
              <el-form-item label="复验人：" prop="secondAccount"  >
                <el-input v-model="detailForm.secondAccount" placeholder="请输入" @change="checkAccount('secondAccount')"></el-input>
              </el-form-item>
              </div>
              <div class="team-row-right">
                <el-input v-model="detailForm.secondName" placeholder="请输入"></el-input>
              </div>
            </div>
            <div class="team-row">
              <div class="team-row-left">
              <el-form-item label="复验核定人：" prop="secondAuditAccount" >
                <el-input v-model="detailForm.secondAuditAccount" placeholder="请输入" @change="checkAccount('secondAuditAccount')"></el-input>
              </el-form-item>
              </div>
              <div class="team-row-right">
                <el-input v-model="detailForm.secondAuditName" placeholder="请输入"></el-input>
              </div>
            </div>
          </div>
        </el-form>
      </div>
    </div>
    <div slot="footer">
      <wt-button type="default" @click="onClickClose">取消</wt-button>
      <wt-button :disabled="disabled" type="default" @click="onClickConfirm('addTeamForm')">保存</wt-button>
    </div>
  </wt-page>
</div>
</template>

<script>
import { mapState } from 'vuex'

export default {
  name: 'addTeam',
  isAutoComponent: true,
  props: ['viewType', 'detailData', 'profestional'],
  data() {
    return {
      isButtonLoading: false,
      isFromLoading: false,
      form: {
        isFromLoading: false,
        detailViewType: 'edit',
        detailData: {},
        extFormData: {},
        formData: {
          projectId: this.detailData.id,
          code: ''
        },
        pageData: { currentPage: 1, pageSize: 20, sortName: '', sortOrder: '' },
        formResult: {}
      },
      detailForm: {
        name: '',
        firstAccount: '',
        firstAuditAccount: '',
        secondAccount: '',
        secondAuditAccount: '',
        firstName: '',
        firstAuditName: '',
        secondName: '',
        secondAuditName: '',
        range: [],
        LouYu: [],
        YuanQu: [],
        DiXiaShi: []
      },
      addBaseTitle: '新建基础信息',
      dialogType: 'add',
      first1: false,
      first2: false,
      second1: false,
      second2: false,
      typeOptions: [],
      disabled: false
    }
  },
  async mounted() {
    this.requestTypeOptions()
    if (this.viewType === 'edit') {
      this.detailForm.name = this.profestional.name
      this.detailForm.firstAccount = this.profestional.firstAccount
      this.detailForm.firstAuditAccount = this.profestional.firstAuditAccount
      this.detailForm.secondAccount = this.profestional.secondAccount
      this.detailForm.secondAuditAccount = this.profestional.secondAuditAccount
      this.detailForm.firstName = this.profestional.firstName
      this.detailForm.firstAuditName = this.profestional.firstAuditName
      this.detailForm.secondName = this.profestional.secondName
      this.detailForm.secondAuditName = this.profestional.secondAuditName
    } else {
      this.detailForm.name = ''
      this.detailForm.firstAccount = ''
      this.detailForm.firstAuditAccount = ''
      this.detailForm.secondAccount = ''
      this.detailForm.secondAuditAccount = ''
      this.detailForm.firstName = ''
      this.detailForm.firstAuditName = ''
      this.detailForm.secondName = ''
      this.detailForm.secondAuditName = ''
    }
  },
  created() {},
  methods: {
    checkAccount(type) {console.log(type)
      const that = this
      let acVal = ''
      if (type === 'firstAccount') {
        acVal = that.detailForm.firstAccount
        if (that.viewType === 'edit' && that.detailForm.firstAccount === that.profestional.firstAccount) {
          that.first1 = false;
          return true;
        }
      } else if (type === 'firstAuditAccount') {
        acVal = that.detailForm.firstAuditAccount
        if (that.viewType === 'edit' && that.detailForm.firstAuditAccount === that.profestional.firstAuditAccount) {
          that.first2 = false;
          return true;
        }
      } else if (type === 'secondAccount') {
        acVal = that.detailForm.secondAccount
        if (that.viewType === 'edit' && that.detailForm.secondAccount === that.profestional.secondAccount) {
          that.second1 = false;
          return true;
        }
      } else if (type === 'secondAuditAccount') {
        acVal = that.detailForm.secondAuditAccount
        if (that.viewType === 'edit' && that.detailForm.secondAuditAccount === that.profestional.secondAuditAccount) {
          that.second2 = false;
          return true;
        }
      }
      if (!acVal) {
        return false
      }
      that.$wtRequest({
        url: '/tProjectProfestionalWorkTeam/existAccount',
        method: 'post',
        data: {
          account: acVal,
          projectId: that.detailData.id
        }
      }).then((resp) => {
        if (resp.code === '0' && resp.data) {
          let errTxt = ''
          if (type === 'firstAccount') {
            errTxt = '初验人已存在，请重新输入'
            that.first1 = true
          } else if (type === 'firstAuditAccount') {
            errTxt = '初验核定人已存在，请重新输入'
            that.first2 = true
          } else if (type === 'secondAccount') {
            errTxt = '复验人已存在，请重新输入'
            that.second1 = true
          } else if (type === 'secondAuditAccount') {
            errTxt = '复验核定人已存在，请重新输入'
            that.second2 = true
          }
          that.$message.error(errTxt)
        } else {
          if (type === 'firstAccount') {
            that.first1 = false
          } else if (type === 'firstAuditAccount') {
            that.first2 = false
          } else if (type === 'secondAccount') {
            that.second1 = false
          } else if (type === 'secondAuditAccount') {
            that.second2 = false
          }
        }
      })
    },
    onClickConfirm(formName) {console.log(formName)
      const that = this
      // if (that.disabled) {
      //   return false
      // }
      that.disabled = true
      setTimeout(() => {
        that.disabled = false
      }, 3000)
      if (that.viewType !== 'edit' || (that.viewType === 'edit' && that.detailForm.firstAccount !== that.profestional.firstAccount)) {
        that.checkAccount('firstAccount')
      }
      if (that.viewType !== 'edit' || (that.viewType === 'edit' && that.detailForm.firstAuditAccount !== that.profestional.firstAuditAccount)) {
        that.checkAccount('firstAuditAccount')
      }
      if (that.viewType !== 'edit' || (that.viewType === 'edit' && that.detailForm.secondAccount !== that.profestional.secondAccount)) {
        that.checkAccount('secondAccount')
      }
      if (that.viewType !== 'edit' || (that.viewType === 'edit' && that.detailForm.secondAuditAccount !== that.profestional.secondAuditAccount)) {
        that.checkAccount('secondAuditAccount')
      }
      // that.checkAccount('firstAccount')
      // that.checkAccount('firstAuditAccount')
      // that.checkAccount('secondAccount')
      // that.checkAccount('secondAuditAccount')
      setTimeout(() => {
        that.$refs[formName].validate((valid) => {
          if (valid) {
            if (that.first1 || that.first2 || that.second1 || that.second2) {
              return false
            }
            const sendData = {
              id: that.viewType === 'edit' ? that.profestional.id : 0,
              checkTypeId: that.profestional.checkTypeId,
              name: that.detailForm.name,
              profestionalId: that.profestional.id,
              projectId: that.detailData.id,
              firstAccount: that.detailForm.firstAccount,
              firstAuditAccount: that.detailForm.firstAuditAccount,
              secondAccount: that.detailForm.secondAccount,
              secondAuditAccount: that.detailForm.secondAuditAccount,
              firstName: that.detailForm.firstName,
              firstAuditName: that.detailForm.firstAuditName,
              secondName: that.detailForm.secondName,
              secondAuditName: that.detailForm.secondAuditName,
              diXiashiRanges: [],
              louYuRanges: [],
              yuanQuRanges: []
            }
            that.typeOptions.forEach((x) => {
              x.sonList.forEach((y) => {
                if (this.detailForm[y.pcode] && this.detailForm[y.pcode].length > 0 && this.detailForm[y.pcode].indexOf(y.code) > -1) {
                  const item = {
                    baseTypeCode: y.code,
                    baseTypePcode: y.pcode,
                    projectId: that.detailData.id
                  }
                  if (that.viewType === 'edit') {
                    const newCode = y.pcode === 'LouYu' ? 'louYu' : y.pcode === 'YuanQu' ? 'yuanQu' : 'diXiaShi'
                    console.log(y.pcode, this.profestional, this.profestional[newCode])
                    if (this.profestional[newCode]) {
                      const ypcode = this.profestional[newCode].filter(yp => yp.baseTypeCode === y.code)
                      if (ypcode.length > 0) {
                        item.id = ypcode[0].id
                        item.projectWorkTeamId = ypcode[0].projectWorkTeamId
                      }
                    }
                  }
                  switch (y.pcode) {
                    case 'LouYu':
                      sendData.louYuRanges.push(item)
                      break
                    case 'YuanQu':
                      sendData.yuanQuRanges.push(item)
                      break
                    case 'DiXiaShi':
                      sendData.diXiashiRanges.push(item)
                      break
                    default:
                      break
                  }
                }
              })
            })
            console.log('sss', sendData)
            if (that.viewType === 'edit') {
              that.$wtRequest({
                url: '/tProjectProfestionalWorkTeam/update',
                method: 'post',
                data: sendData
              }).then((resp) => {
                if (resp.code === '0') {
                  that.$message.success('修改成功')
                } else {
                  that.$message.error('修改失败')
                  alert(1);
                  return false;
                }
                that.$emit('on-refresh')
                that.$emit('on-close')
              }).catch(() => {
                //that.$message.error('修改失败')
                //that.$emit('on-refresh')
                //that.$emit('on-close')
              })
            } else {
              that.$wtRequest({
                url: '/tProjectProfestionalWorkTeam/add',
                method: 'post',
                data: sendData
              }).then((resp) => {
                if (resp.code === '0') {
                  that.$message.success('新增成功')
                } else {
                  that.$message.error('新增失败')
                  return false;
                }
                that.$emit('on-refresh')
                that.$emit('on-close')
              }).catch(() => {
                //that.$message.error('新增失败')
                // that.$emit('on-refresh')
                // that.$emit('on-close')
              })
            }
          } else {
            console.log('error submit!!')
            return false
          }
        })
      }, 1000)
    },
    onClickViewStandard(row) {
      console.log(row)
    },
    onClickViewFloor(row) {
      console.log(row)
    },
    onClickViewPark(row) {
      console.log(row)
    },
    onClickViewBasement(row) {
      console.log(row)
    },
    documentsDownload(row) {
      window.open('row.url', '_blank')
    },
    onClickClose() {
      this.$emit('on-close')
    },
    initDetailData(data) {
      this.detail = data
    },
    requestTypeOptions() {
      const that = this
      that.$wtRequest({
        url: '/tProjectBaseTypeInfo/queryTree',
        method: 'post',
        data: {
          id: this.detailData.id
        }
      }).then((resp) => {
        if (resp.code === '0') {
          const list = resp.data.list
          list.forEach((x) => {
            this.$set(this.detailForm, x.code, [])
          })
          this.typeOptions = list || []
          if (this.profestional.louYu) {
            this.detailForm.LouYu = this.profestional.louYu.map(x => x.baseTypeCode)
          }
          if (this.profestional.yuanQu) {
            this.detailForm.YuanQu = this.profestional.yuanQu.map(x => x.baseTypeCode)
          }
          if (this.profestional.diXiaShi) {
            this.detailForm.DiXiaShi = this.profestional.diXiaShi.map(x => x.baseTypeCode)
          }
        }
      }).catch((e) => {
        console.log('requestDetail e', e)
      })
    },
    requestDetail() {
      const that = this
      that.isFromLoading = true
      that.$wtRequest({
        url: '/tProjectInfo/detail',
        method: 'post',
        data: { id: that.detailData.id }
      }).then((resp) => {
        console.log('requestDetail resp', resp)
        // that.detail = resp.data || {}
        this.initDetailData(resp.data)
        that.isFromLoading = false
      }).catch((e) => {
        console.log('requestDetail e', e)
        that.isFromLoading = false
      })
    }
  },
  computed: {
    isReadMode() {
      return this.viewType === 'view'
    },
    ...mapState({
    })
  }
}
</script>
<style lang="scss" scoped>
  .module-permission-content {display: flex;width: 100%;margin-top: 16px; color: #666;}
  .module-permission-content .left-content {width: 250px;padding: 0 24px;}
  .module-permission-content .right-content {flex: 1;}
  .module-menu-content {display: flex;width: 100%;}
  .module-menu-content .left-content {width: 300px;padding: 0 24px;}
  .module-menu-content .right-content {flex: 1;}
  .page-detail {
    padding: 0px;
  }
  .wt-page-form {
    width: 100%;
    max-width: 1200px;
  }
  .form-list {
    display: flex;
    flex-wrap: wrap;
    width: 100%;
    .form-item {
      width: 50%;
      flex-basis: 50%;
    }
  }
  .team-table {
    .team-header {
      display: flex;
      line-height: 40px;
      background: #f1f1f1;
      margin: 10px 0 20px 0;
      color: #333;
      font-weight: bold;
      .title {
        width: 120px;
        flex-basis: 120px;
        padding-right: 22px;
        text-align: right;
      }
      .content {
        flex: 1;
      }
      .name {
        flex-basis: 200px;
      }
    }
    .team-row {
      display: flex;
      .team-row-left {
        flex: 1;
        padding-right: 40px;
      }
      .team-row-right {
        flex-basis: 200px;
        padding-right: 20px;
      }
    }
  }
</style>
