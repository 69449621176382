<template>
  <wt-page>
    <div class="wt-page-container" v-loading="isFromLoading">
      <div class="page-detail">
        <wt-page-section title="版本管理">
          <div class="main-box">
            <el-input class="input" size="small" v-model="value" :disabled="readonly" placeholder="请输入内容"></el-input>
            <template>
              <wt-button v-if="readonly" type="primary" @click="onClickEdit">编辑</wt-button>
              <wt-button v-else type="primary" @click="onClickSave">保存</wt-button>
            </template>
          </div>
        </wt-page-section>
      </div>
    </div>
  </wt-page>
</template>

<script>
import { mapState } from 'vuex'

export default {
  name: 'companyDetail',
  isAutoComponent: true,
  props: ['viewType', 'detailData'],
  data() {
    return {
      isButtonLoading: false,
      isFromLoading: false,
      detail: {},
      value: '',
      readonly: true
    }
  },
  async mounted() {
    this.requestDetail()
  },
  created() {},
  methods: {
    valueChange(val) {
      this.value = val
    },
    onClickSave() {
      const that = this
      that.readonly = true
      that.isFromLoading = true
      that.$wtRequest({
        url: '/tSetting/update/version',
        method: 'post',
        data: {
          content: that.value
        }
      }).then((resp) => {
        that.isFromLoading = false
        that.$message({ type: 'success', message: '保存成功' })
      }).catch((e) => {
        that.isFromLoading = false
        that.$message({ type: 'error', message: '保存失败' })
      })
    },
    onClickEdit() {
      const that = this
      that.readonly = false
    },
    initDetailData(data) {
      this.detail = data
      this.value = data.content
    },
    requestDetail() {
      const that = this
      that.isFromLoading = true
      that.$wtRequest({
        url: '/tSetting/detail/version',
        method: 'post'
      }).then((resp) => {
        console.log('requestDetail resp', resp)
        this.initDetailData(resp.data)
        that.isFromLoading = false
      }).catch((e) => {
        that.isFromLoading = false
      })
    }
  },
  computed: {
    isReadMode() {
      return this.viewType === 'view'
    },
    ...mapState({
    })
  }
}
</script>
<style lang="scss" scoped>
  .wt-page-container {
    padding: 0;
    min-height: 500px;
  }
  .main-box {
    padding: 20px 10px;
    display: flex;
    .input {
      width: 400px;
      margin-right: 20px;
    }
  }
  .footer {
    display: flex;
    padding: 15px 0;
    justify-content: flex-end;
  }
</style>
