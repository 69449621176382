<template>
  <wt-page>
    <div class="wt-page-container" v-loading="isFromLoading">
      <div class="page-detail">
        <el-table
          :data="tableData"
          style="width: 100%">
          <el-table-column type="index" label="序号"></el-table-column>
          <el-table-column prop="scenes.name" label="专业"></el-table-column>
          <el-table-column label="文件名称">
            <template slot-scope="scope">
              <span>{{scope.row.name}}</span>
            </template>
          </el-table-column>
          <el-table-column label="操作" fixed="right" width="80">
            <template slot-scope="scope">
              <wt-text-button @click="onClickDownload(scope.row)">下载</wt-text-button>
            </template>
          </el-table-column>
        </el-table>
      </div>
    </div>
    <div slot="footer">
      <wt-button type="default" @click="onClickClose">关闭</wt-button>
    </div>
  </wt-page>
</template>

<script>
import { mapState } from 'vuex'

export default {
  name: 'xmglReport',
  isAutoComponent: true,
  props: ['viewType', 'detailData'],
  data() {
    return {
      isButtonLoading: false,
      isFromLoading: false,
      detail: {},
      tableData: [],
      detailForm: {
        menuGroupId: '',
        orderNum: 0,
        isPublish: 1
      }
    }
  },
  async mounted() {
    if (this.viewType !== 'add') {
      await this.requestDetail()
    }
  },
  created() {},
  methods: {
    onClickDownload(row) {
      window.open(row.url, '_blank')
    },
    onClickClose() {
      this.$emit('on-close')
    },
    onClickAdd() {
      const that = this
      this.$wtUtil.validateConfirm(this, '是否保存?').then(() => {
        that.requestAdd()
      })
    },
    onClickEdit() {
      const that = this
      this.$wtUtil.validateConfirm(this, '是否保存?').then(() => {
        that.requestUpdate()
      })
    },
    initDetailData(data) {
      this.detail = data
    },
    requestDetail() {
      const that = this
      that.isFromLoading = true
      that.$wtRequest({
        url: '/tProjectInfo/detail',
        method: 'post',
        data: { id: that.detailData.id }
      }).then((resp) => {
        console.log('requestDetail resp', resp)
        // that.detail = resp.data || {}
        this.initDetailData(resp.data)
        that.isFromLoading = false
      }).catch((e) => {
        console.log('requestDetail e', e)
        that.isFromLoading = false
      })
    }
  },
  computed: {
    isReadMode() {
      return this.viewType === 'view'
    },
    ...mapState({
    })
  }
}
</script>
<style lang="scss" scoped>
  .module-permission-content {display: flex;width: 100%;margin-top: 16px; color: #666;}
  .module-permission-content .left-content {width: 250px;padding: 0 24px;}
  .module-permission-content .right-content {flex: 1;}
  .module-menu-content {display: flex;width: 100%;}
  .module-menu-content .left-content {width: 300px;padding: 0 24px;}
  .module-menu-content .right-content {flex: 1;}
  .page-detail {
    padding: 0px;
  }
</style>
