<template>
  <div class="page-container">
    <wt-data-form class="wt-table-full" ref="dataForm" :form.sync="form"
                  request-list-url="/tDocumentInfo/list"
                  request-delete-url="/tDocumentInfo/delete"
                  request-detail-url="/auth/companyDetail">
      <template slot="search">
        <wt-search-form-item title="文档名" v-model="form.formData.name" type="input"/>
        <wt-search-form-item title="创建日期" v-model="form.formData.createTimeRange"  @on-input-change="dateChange" type="daterange"/>
      </template>
      <template slot="toolbar">
        <wt-button type="primary" v-has-any-premission="['WDGLINFO:ADD']" @click="onClickAdd">新建文档</wt-button>
      </template>
      <template slot="table">
        <el-table-column type="index" label="序号"></el-table-column>
        <el-table-column label="文档名">
          <template slot-scope="scope">
            <wt-text-button>{{scope.row.name}}</wt-text-button>
          </template>
        </el-table-column>
        <el-table-column prop="createUserName" label="操作人" width="120"></el-table-column>
        <el-table-column prop="createTime" label="创建时间" :sortable='true' width="150"></el-table-column>
        <el-table-column label="操作" fixed="right" width="150">
          <template slot-scope="scope">
            <wt-text-button v-has-any-premission="['WDGLINFO:VIEW']" @click="onClickDownload(scope.row)">下载</wt-text-button>
            <wt-text-button v-has-any-premission="['WDGLINFO:DELETE']" @click="onClickDelete(scope.row)" class="danger">删除</wt-text-button>
          </template>
        </el-table-column>
      </template>
    </wt-data-form>
    <!--弹层 开始-->
     <wt-dialog ref="wtWdglDialog" title="新建文档">
      <div class="wt-org-role-wrapper">
        <el-form :model="detailForm" ref="form" class="wt-page-form" label-width="120px" size="small">
          <el-form-item label="文档名：" :rules="[ { required: true, message: '文档名不能为空', validator: $wtValidator.ValidatorNotBlank, trigger: 'blur' }]">
            <el-input v-model="detailForm.name" placeholder="请输入"></el-input>
          </el-form-item>
          <!--<el-form-item label="文档：" :rules="[ { required: true, message: '文档不能为空', validator: $wtValidator.ValidatorNotBlank, trigger: 'blur' }]">
            <el-upload
              class="upload-demo"
              action="/file/upload"
              :auto-upload="false"
              :on-change="fileReady"
              :file-list="fileList">
              <el-button size="small" type="primary">点击上传</el-button>
            </el-upload>
          </el-form-item>-->
          <el-form-item label="文档：" :rules="[ { required: true, message: '文档不能为空', validator: $wtValidator.ValidatorNotBlank, trigger: 'blur' }]">
          <div class="main-box">
            <wt-file-upload v-model="detailForm.url"
                        list-type="list" data-type="url" :max="1" :file-limit="1024 * 20000" accept="*.doc,*.docx,*.pdf" :tip-props="{fileType: 'doc,docx,pdf', fileLimit: '20MB', fileSize: ''}"></wt-file-upload>
          </div>
          </el-form-item>
        </el-form>
      </div>
      <div class="wt-toolbar wt-margin-t wt-flex-end">
        <wt-button type="default" @click="$refs.wtWdglDialog.close()">关闭</wt-button>
        <wt-button type="primary" @click="onClickConfirm">确定</wt-button>
      </div>
    </wt-dialog>
    <!--弹层 结束-->
  </div>
</template>

<script>
import { mapState } from 'vuex'

export default {
  name: 'wdglInfo',
  data() {
    return {
      wtFileServerUrl: process.env.VUE_APP_FILE_SERVER_URL,
      form: {
        isFromLoading: false,
        detailViewType: 'edit',
        detailData: {},
        extFormData: {},
        formData: {
          name: '',
          createTimeRange: []
        },
        pageData: { currentPage: 1, pageSize: 20, sortName: '', sortOrder: '' },
        formResult: {}
      },
      detailForm: {
        name: '',
        file: null,
        url: ''
      },
      fileList: []
    }
  },
  mounted() {
    this.$refs.dataForm.requestDataList()
  },
  created() {
  },
  methods: {
    fileReady(file) {
      console.log('change', file)
      this.detailForm.file = file
    },
    fileInput(data) {
      console.log('input==', data)
      this.detailForm.file = data.fileUrl
    },
    onClickConfirm() {
      console.log('firm')
      const that = this
      if (!this.detailForm.name) {
        this.$message({
          message: '请输入文档名',
          type: 'error',
          customClass: 'message-override'
        })
        return
      } else if (!this.detailForm.url) {
        this.$message({
          message: '请输入上传文档',
          type: 'error',
          customClass: 'message-override'
        })
        return
      }

      const params = new FormData()
      // params.append('file', this.detailForm.file.raw)
      params.append('url', this.wtFileServerUrl + this.detailForm.url)
      params.append('name', this.detailForm.name)
      that.$wtRequest({
        url: '/tDocumentInfo/add',
        method: 'post',
        data: {
          url: this.wtFileServerUrl + this.detailForm.url,
          name: this.detailForm.name
        }
      }).then((resp) => {
        if (resp.code === '0') {
          that.$refs.dataForm.requestDataList()
          that.$message({
            message: '新建成功',
            type: 'success',
            customClass: 'message-override'
          })
        } else {
          that.$message({
            message: '新建失败',
            type: 'error',
            customClass: 'message-override'
          })
        }
        that.$refs.wtWdglDialog.close()
      }).catch((err) => {
        console.log(err)
        that.$message({
          message: '新建失败',
          type: 'error',
          customClass: 'message-override'
        })
        that.$refs.wtWdglDialog.close()
      })
    },
    dateChange() {
      console.log('change')
    },
    onModuleCurrentChanged(nodeData, node) {
      console.log(nodeData, node)
      this.form.formData.checkedModuleCodes = node.checkedKeys
      this.$nextTick(() => {
        this.$refs.dataForm.onClickSearch()
      })
    },
    onClickDownload(rowData) {
      // window.open(rowData.url, '_blank')
      const el = document.createElement('a')
      el.style.display = 'none'
      el.setAttribute('target', '_balank')
      el.setAttribute('download', rowData.name || '未知文件')
      el.href = rowData.url
      document.body.appendChild(el)
      el.click()
      document.body.removeChild(el)
    },
    onClickEdit(rowData) {
      this.form.detailViewType = 'edit'
      this.form.detailData = rowData
      this.$refs.detailDialog.open()
    },
    onClickAdd() {
      this.detailForm.name = ''
      this.detailForm.file = null
      this.detailForm.url = null
      this.$refs.wtWdglDialog.open()
    },
    onClickDelete(rowData) {
      this.$refs.dataForm.onClickDelete(rowData.id)
    },
    // 删除角色
    requestRoleDelete(rowData) {
      this.$wtRequest({
        url: '/auth/roleDelete',
        method: 'post',
        data: {
          list: [rowData.id]
        }
      }).then(res => {
        if (res.isSuccess) {
          this.$message({ type: 'success', message: '删除成功' })
          this.$refs.dataForm.requestDataList()
        }
      })
    }
  },
  computed: {
    ...mapState({})
  }
}
</script>
<style lang="scss">
  .page-container {
  }
  .message-override {
    z-index: 3000 !important;
  }
</style>
