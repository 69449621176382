<template>
<div>
  <wt-page>
    <div class="wt-page-container" v-loading="isFromLoading">
      <div class="page-detail">
        <wt-page-section title="问题信息">
          <div class="module-permission-content">
            <div class="text-list">
              <div class="text-item">
                <div class="title">问题ID：</div>
                <div class="content">{{detailForm.problemCode}}</div>
              </div>
              <div class="text-item">
                <div class="title">所属专业：</div>
                <div class="content">{{detailForm.checkName}}</div>
              </div>
              <div class="text-item">
                <div class="title">问题楼层：</div>
                <div class="content">{{detailForm.baseTypeName}}{{detailForm.loof}}</div>
              </div>
            </div>
            <div class="text-list">
              <div class="text-item">
                <div class="title">问题空间：</div>
                <div class="content">{{detailForm.space}}</div>
              </div>
              <div class="text-item">
                <div class="title">严重等级：</div>
                <div class="content">{{detailForm.level}}</div>
              </div>
              <div class="text-item">
                <div class="title">&nbsp;</div>
                <div class="content">&nbsp;</div>
              </div>
            </div>
            <div class="text-list">
              <div class="text-item">
                <div class="title">问题描述：</div>
                <div class="content">{{detailForm.content}}</div>
              </div>
            </div>
            <div class="text-list">
              <div class="text-item">
                <div class="title">物业建议：</div>
                <div class="content">{{detailForm.suggest}}</div>
              </div>
            </div>
            <div class="text-list">
              <div class="text-item">
                <div class="title">照片：</div>
                <div class="content">
                  <div class="image-view">
                    <div class="demo-image__preview" v-for="url in detailForm.urls">
                      <el-image
                        style="width: 146px; height: 146px"
                        :src="url"
                        :preview-src-list="detailForm.urls">
                      </el-image>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </wt-page-section>
        <wt-page-section v-if="detailForm.firstAuditStatus || detailForm.firstAuditStatus === 0" title="初验核定信息">
          <div class="module-permission-content">
            <div class="text-list">
              <div class="text-item">
                <div class="title">初验核定建议：</div>
                <div class="content">{{detailForm.auditSuggest}}</div>
              </div>
              <div class="text-item">
                <div class="title">初验核定状态：</div>
                <div class="content" v-if="detailForm.firstAuditStatus === 0">待核定</div>
                <div class="content" v-if="detailForm.firstAuditStatus === 1">通过</div>
                <div class="content" v-if="detailForm.firstAuditStatus === 2">不通过</div>
              </div>
            </div>
          </div>
        </wt-page-section>
        <wt-page-section v-if="detailForm.secondStatus || detailForm.secondStatus === 0" title="复验信息">
          <div class="module-permission-content">
            <div class="text-list">
              <div class="text-item">
                <div class="title">复验建议：</div>
                <div class="content">{{detailForm.secondSuggest}}</div>
              </div>
              <div class="text-item">
                <div class="title">复验状态：</div>
                <div class="content" v-if="detailForm.secondSuggest && detailForm.secondStatus === 0">待核定</div>
                <div class="content" v-if="detailForm.secondStatus === 1">通过</div>
                <div class="content" v-if="detailForm.secondStatus === 2">不通过</div>
              </div>
            </div>
            <div class="text-list">
              <div class="text-item">
                <div class="title">照片：</div>
                <div class="content">
                  <div class="image-view">
                    <div class="demo-image__preview" v-for="url in detailForm.auditUrls">
                      <el-image
                        style="width: 146px; height: 146px"
                        :src="url"
                        :preview-src-list="detailForm.auditUrls">
                      </el-image>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </wt-page-section>
        <wt-page-section v-if="detailForm.secondAuditStatus || detailForm.secondAuditStatus === 0" title="复验核定信息">
          <div class="module-permission-content">
            <div class="text-list">
              <div class="text-item">
                <div class="title">复验核定建议：</div>
                <div class="content">{{detailForm.secondAuditSuggest}}</div>
              </div>
              <div class="text-item">
                <div class="title">复验核定状态：</div>
                <div class="content" v-if="detailForm.secondAuditStatus === 0">待核定</div>
                <div class="content" v-if="detailForm.secondAuditStatus === 1">通过</div>
                <div class="content" v-if="detailForm.secondAuditStatus === 2">不通过</div>
              </div>
            </div>
            <div class="text-list">
              <div class="text-item">
                <div class="title">照片：</div>
                <div class="content">
                  <div class="image-view">
                    <div class="demo-image__preview" v-for="url in detailForm.secondAuditUrls">
                      <el-image
                        style="width: 146px; height: 146px"
                        :src="url"
                        :preview-src-list="detailForm.secondAuditUrls">
                      </el-image>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </wt-page-section>
      </div>
    </div>
    <div slot="footer">
      <wt-button type="default" @click="onClickClose">关闭</wt-button>
      <wt-button type="primary" v-if="viewType == 'add'" v-has-any-premission="['ALGLINFO:ADD']" :loading="isFromLoading" @click="onClickAdd">保存</wt-button>
      <wt-button type="primary" v-if="viewType == 'edit'" v-has-any-premission="['ALGLINFO:UPDATE']" :loading="isFromLoading" @click="onClickEdit">保存</wt-button>
    </div>
  </wt-page>
</div>
</template>

<script>
import { mapState } from 'vuex'

export default {
  name: 'xmglProblemDetail',
  isAutoComponent: true,
  props: ['viewType', 'detailData'],
  data() {
    return {
      menuGroupList: [],
      isPermissionItemVisibility: true,
      isMiniMode: true,
      allModuleList: [],
      defaultCheckedKeys: [],
      checkedModuleCodes: [],
      checkedPermissionCodes: [],
      modulePermissionMap: {},
      isButtonLoading: false,
      isFromLoading: false,
      detailForm: {
        problemCode: '',
        space: '',
        checkName: '',
        content: '',
        suggest: '',
        level: '',
        ulrs: []
      },
      dialogImageUrl: '',
      dialogVisible: false,
      disabled: false,
      fileList: []
    }
  },
  async mounted() {
    this.filelist = []
    if (this.viewType !== 'add') {
      await this.requestDetail()
    }
  },
  created() {},
  methods: {
    handleChange(file, fileList) {
      const that = this
      console.log(file, fileList)
      this.fileList = fileList.slice(-9)
      const formData = new FormData()
      /* Object.keys(this.fileList).forEach((key) => {
        formData.append('file', this.fileList[key].raw)
      })*/
      formData.append('file', file.raw)
      this.$wtRequest({
        url: '/file/upload',
        method: 'post',
        data: formData
      }).then(res => {
        console.log('res', file, that.fileList)
        if (res.isSuccess) {
          that.fileList.filter(x => x.uid === file.uid)[0].qUrl = res.data
        } else {
          that.fileList = that.fileList.filter(x => x.uid !== file.uid)
          that.$message({ type: 'error', message: `${file.name}上传失败` })
        }
      })
    },
    handleRemove(file) {
      this.fileList = this.fileList.filter(x => x.uid !== file.uid)
    },
    handlePictureCardPreview(file) {
      this.dialogImageUrl = file.url
      this.dialogVisible = true
    },
    handleDownload(file) {
      console.log(file)
    },
    onClickClose() {
      this.$emit('on-close')
    },
    requestDetail() {
      const that = this
      that.isFromLoading = true
      that.$wtRequest({
        url: '/tProjectRangeProblem/detail',
        method: 'post',
        data: { id: that.detailData.id }
      }).then((resp) => {
        console.log('requestDetail resp', resp)
        that.detailForm = resp.data || {}
        that.isFromLoading = false
      }).catch((e) => {
        console.log('requestDetail e', e)
        that.isFromLoading = false
      })
    }
  },
  computed: {
    isReadMode() {
      return this.viewType === 'view'
    },
    ...mapState({
    })
  }
}
</script>
<style lang="scss" scoped>
  .module-permission-content {width: 100%;margin-top: 16px; color: #666;}
  .module-permission-content .left-content {width: 250px;padding: 0 24px;}
  .module-permission-content .right-content {flex: 1;}
  .module-menu-content {display: flex;width: 100%;}
  .module-menu-content .left-content {width: 300px;padding: 0 24px;}
  .module-menu-content .right-content {flex: 1;}
  .wt-page-container {
    padding: 0;
    padding-bottom: 30px;
  }
  .text-list {
    display: flex;
    margin-top: 15px;
    .text-item {
      flex: 1;
      display: flex;
      line-height: 32px;
      padding: 5px 0;
      .title {
        width: 150px;
        text-align: right;
        .red {
          color: red;
        }
      }
      .content {
        flex: 1;
      }
    }
    .list2 {
      flex: 2;
    }
  }
  .image-view {
    display: flex;
    flex-wrap: wrap;
    .demo-image__preview {
      width: 148px;
      height: 148px;
      border: 1px solid #c0ccda;
      margin: 0 8px 8px 0;
      overflow: hidden;
      border-radius: 6px;
    }
  }
</style>
