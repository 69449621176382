<template>
  <div class="wt-theme-setting">
    <div class="wt-theme-setting-btn" @click="isDrawerVisiable = true">
      <i class="el-icon-setting"></i>
    </div>
    <el-drawer title="我是标题" :visible.sync="isDrawerVisiable" custom-class="wt-drawer"
               direction="rtl" :size="400" :withHeader="false" destroy-on-close modal-append-to-body>
      <div class="wt-setting-drawer-container">
        <div class="wt-drawer-wrapper wt-scroll">
          <div class="wt-theme-section">
            <div class="row">
              <el-alert title="使用说明" type="warning" show-icon :closable="false">
                <div class="wt-theme-tip">
                  配置栏只在开发环境用于预览，生产环境不会展现，请拷贝后手动修改配置文件 <b>src/wtLayoutConfig.js</b>
                </div>
              </el-alert>
            </div>
          </div>
          <div class="wt-theme-section">
            <div class="header">整体风格</div>
            <div class="row">
              <div class="title">导航模式</div>
              <div class="content">
                <el-select v-model="theme.name" @change="onThemeChange">
                  <el-option v-for="themeKey in Object.keys(THEME_ENUM)" :key="themeKey" :value="THEME_ENUM[themeKey].key" :label="THEME_ENUM[themeKey].name"></el-option>
                </el-select>
              </div>
            </div>
          </div>
          <div class="wt-theme-section">
            <div class="header">显示区域</div>
            <div class="row">
              <div class="title">标签栏</div>
              <div class="content"><el-switch v-model="theme.isTabEnable" @change="onThemeChange"></el-switch></div>
            </div>
            <div class="row">
              <div class="title">面包屑</div>
              <div class="content"><el-switch v-model="theme.isBreadcrumbEnable" @change="onThemeChange"></el-switch></div>
            </div>
            <div class="row">
              <div class="title">菜单图标</div>
              <div class="content"><el-switch v-model="theme.isMenuIconVisible" @change="onThemeChange"></el-switch></div>
            </div>
            <div class="row">
              <div class="title">标签栏图标</div>
              <div class="content"><el-switch v-model="theme.isTabIconVisible" @change="onThemeChange"></el-switch></div>
            </div>
            <div class="row">
              <div class="title">面包屑图标</div>
              <div class="content"><el-switch v-model="theme.isBreadcrumbIconVisible" @change="onThemeChange"></el-switch></div>
            </div>
          </div>
          <div class="wt-theme-section">
            <div class="header">权限和认证</div>
            <div class="row">
              <div class="title">
                <span>权限限制</span>
                <el-tooltip>
                  <div slot="content">
                    <div>打开后按钮会根据权限配置来显示或隐藏，生产环境必须打开</div>
                    <div>!!!开发场景下，页面不会实时刷新，需要重新打开页面!!!</div>
                  </div>
                  <i class="el-icon-info wt-tip"></i>
                </el-tooltip>
              </div>
              <div class="content">
                <el-switch v-model="theme.isAuthEnable" @change="onThemeChange"></el-switch>
              </div>
            </div>
          </div>
          <div class="wt-theme-section">
            <div class="header">全局配置</div>
            <div class="row">
              <div class="title">
                <span>静态菜单</span>
                <el-tooltip>
                  <div slot="content">
                    <div>打开后会使用@/router/staticRoutes.js</div>
                  </div>
                  <i class="el-icon-info wt-tip"></i>
                </el-tooltip>
              </div>
              <div class="content">
                <el-switch v-model="theme.isStaticRouter" @change="onStaticRouterChange"></el-switch>
              </div>
            </div>
          </div>
        </div>
        <div class="wt-drawer-footer">
          <el-button type="primary" @click="onClickSave">生成配置</el-button>
          <el-button @click="onClickReset">恢复默认</el-button>
          <el-button @click="isDrawerVisiable = false">关闭</el-button>
        </div>
      </div>
    </el-drawer>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import { THEME_ENUM } from '../constants/helper'
import wtLayoutConfig from '@/wtLayoutConfig'
export default {
  name: 'WtThemeSetting',
  data() {
    return {
      THEME_ENUM: THEME_ENUM,
      isDrawerVisiable: false
      // theme: Object.assign({}, wtLayoutConfig)
    }
  },
  components: {
  },
  methods: {
    onClickSave() {
      // this.$store.dispatch('updateTheme', { name: val })
    },
    onClickReset() {
      this.theme = Object.assign({}, wtLayoutConfig)
      this.$store.dispatch('updateTheme', this.theme)
    },
    onThemeChange() {
      this.$store.dispatch('updateTheme', this.theme)
    },
    async onStaticRouterChange() {
      await this.$store.dispatch('updateTheme', this.theme)
      await this.$router.push({ path: '/' })
      location.reload() // 为了重新实例化vue-router对象 避免bug
    }
  },
  computed: {
    ...mapState({
      theme: state => state.app.theme
    })
  }
}
</script>

<style lang="scss" scoped>
@import "@/styles/variables.scss";
.wt-theme-setting{
  position: relative;
  width: 0;
  height: 0;
}
.wt-theme-setting-btn {
  position: fixed;
  top: 20%;
  right: 0;
  z-index: 1001;
  padding: 0;
  margin: 0;
  cursor: pointer;
  border: 1px solid #dcdfe6;
  border-top-left-radius: 5.5px;
  border-bottom-left-radius: 5.5px;
  box-shadow: 0 0 50px 0 rgb(82 63 105 / 15%);
  transform: translateY(-50%);
  width: 45px;
  height: 45px;
  background-color: $--color-primary;
  display: flex;
  align-items: center;
  justify-content: center;

  > i {
    color: #FFFFFF;
    font-size: 20px
  }
}
.wt-theme-section{
  margin: 24px 16px;
  .header {
    font-size: 16px;
    font-weight: bold;
    color: #333333;
  }
  .row{
    display: flex;
    align-items: center;
    margin-top: 16px;
    justify-content: space-between;
    .title {
      font-size: 14px;
      color: #666666;
    }
    .content {
      text-align: right;
      width: 100px;
    }
  }
  .wt-tip{
    cursor: pointer;
    margin-left: 4px;
    font-size: 16px;
  }
}

.wt-setting-drawer-container {
  width: 100%;
  height: 100%;
  overflow: hidden;

  .wt-drawer-wrapper {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: calc(100% - 50px);
  }

  .wt-drawer-footer {
    box-shadow: 1px 0px 4px rgb(0 21 41 / 8%);
    position: absolute;
    bottom: 0;
    right: 0;
    width: 100%;
    height: 50px;
    display: flex;
    align-items: center;
    justify-content: flex-end;
  }
}

.wt-drawer-footer {
  > button:last-child {
    margin-right: 16px;
  }
}

.wt-theme-tip{
  line-height: 18px;
}
</style>
