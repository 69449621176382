import Vue from 'vue'
import Router from 'vue-router'
import store from '../store/index'
// in development-env not use lazy-loading, because lazy-loading too many pages will cause webpack hot update too slow. so only in production use lazy-loading;
// detail: https://panjiachen.github.io/vue-element-admin-site/#/lazy-loading

Vue.use(Router)

/**
* hidden: true                   if `hidden:true` will not show in the sidebar(default is false)
* alwaysShow: true               if set true, will always show the root menu, whatever its child routes length
*                                if not set alwaysShow, only more than one route under the children
*                                it will becomes nested mode, otherwise not show the root menu
* redirect: noredirect           if `redirect:noredirect` will no redirct in the breadcrumb
* name:'router-name'             the name is used by <keep-alive> (must set!!!)
* meta : {
    title: 'title'               the name show in submenu and breadcrumb (recommend set)
    icon: 'svg-name'             the icon show in the sidebar,
  }
**/
const dynamicRouters = [
  {
    path: '/login',
    component: () => import('@/modules/login/index'),
    meta: { hidden: true, hiddenInTab: true }
  },
  {
    path: '/ssoLogin',
    component: () => import('@/modules/login/ssoLogin'),
    meta: { hidden: true, hiddenInTab: true }
  },
  {
    path: '/404',
    component: () => import('@/modules/layout/404'),
    meta: { hidden: true, hiddenInTab: true }
  },
  {
    path: '/500',
    component: () => import('@/modules/layout/500'),
    meta: { hidden: true, hiddenInTab: true }
  },
  {
    path: '/',
    redirect: '/welcome',
    component: () => import('@/components/WtLayout/WtMainLayout'),
    meta: { hidden: true, hiddenInTab: true },
    children: [{
      path: 'welcome',
      name: 'WELCOME',
      component: () => import('@/modules/home/dashboard.vue'),
      meta: { title: '首页', icon: 'el-icon-s-platform', hidden: false, hiddenInTab: true }
    // },
    // {
    //   path: 'companyInfo',
    //   name: 'COMPANYINFO',
    //   component: () => import('@/modules/gsgl/companyInfo.vue'),
    //   meta: { title: '首页', icon: 'el-icon-s-platform', hidden: false, hiddenInTab: true }
    }]
  },
  {
    path: '/gsgl',
    name: 'GSGL',
    component: () =>
      import('@/components/WtLayout/WtMainLayout.vue'),
    meta: { title: '公司管理', icon: 'el-icon-s-operation' },
    children: [{
      path: 'companyInfo',
      name: 'COMPANYINFO',
      component: () =>
        import('@/modules/gsgl/companyInfo.vue'),
      meta: { title: '公司管理', icon: 'el-icon-warning-outline' }
    }]
  },
  {
    path: '/xmgl',
    name: 'XMGL',
    component: () =>
      import('@/components/WtLayout/WtMainLayout.vue'),
    meta: { title: '项目管理', icon: 'el-icon-s-operation' },
    children: [{
      path: 'xmglInfo',
      name: 'XMGLINFO',
      component: () =>
        import('@/modules/xmgl/xmglInfo.vue'),
      meta: { title: '项目管理', icon: 'el-icon-warning-outline' }
    }]
  },
  {
    path: '/cybz',
    name: 'CYBZ',
    component: () =>
      import('@/components/WtLayout/WtMainLayout.vue'),
    meta: { title: '查验标准', icon: 'el-icon-s-operation' },
    children: [{
      path: 'bzglInfo',
      name: 'BZGLINFO',
      component: () =>
        import('@/modules/cybz/bzglInfo.vue'),
      meta: { title: '查验标准', icon: 'el-icon-warning-outline' }
    }]
  },
  {
    path: '/wdgl',
    name: 'WDGL',
    component: () =>
      import('@/components/WtLayout/WtMainLayout.vue'),
    meta: { title: '文档管理', icon: 'el-icon-s-operation' },
    children: [{
      path: 'wdglInfo',
      name: 'WDGLINFO',
      component: () =>
        import('@/modules/wdgl/wdglInfo.vue'),
      meta: { title: '文档管理', icon: 'el-icon-warning-outline' }
    }, {
      path: 'alglInfo',
      name: 'ALGLINFO',
      component: () =>
        import('@/modules/wdgl/alglInfo.vue'),
      meta: { title: '案例管理', icon: 'el-icon-warning-outline' }
    }]
  },
  {
    path: '/qxgl',
    name: 'QXGL',
    component: () =>
      import('@/components/WtLayout/WtMainLayout.vue'),
    meta: { title: '权限管理', icon: 'el-icon-s-operation' },
    children: [{
      path: 'roleList',
      name: 'ROLELIST',
      component: () =>
        import('@/modules/system/roleList.vue'),
      meta: { title: '角色管理', icon: 'el-icon-warning-outline' }
    }, {
      path: 'managerList',
      name: 'MANAGERLIST',
      component: () =>
        import('@/modules/system/userList.vue'),
      meta: { title: '管理员管理', icon: 'el-icon-warning-outline' }
    }, {
      path: 'orgList',
      name: 'ORGLIST',
      component: () =>
        import('@/modules/system/orgList.vue'),
      meta: { title: '架构管理', icon: 'el-icon-warning-outline' }
    }]
  },
  {
    path: '/rzgl',
    name: 'RZGL',
    component: () =>
      import('@/components/WtLayout/WtMainLayout.vue'),
    meta: { title: '日志管理', icon: 'el-icon-s-operation' },
    children: [{
      path: 'rzglInfo',
      name: 'RZGLINFO',
      component: () =>
        import('@/modules/rzgl/rzglInfo.vue'),
      meta: { title: '日志管理', icon: 'el-icon-warning-outline' }
    }]
  },
  {
    path: '/bbgl',
    name: 'BBGL',
    component: () =>
      import('@/components/WtLayout/WtMainLayout.vue'),
    meta: { title: '报表管理', icon: 'el-icon-s-operation' },
    children: [{
      path: 'bbglInfo',
      name: 'BBGLINFO',
      component: () =>
        import('@/modules/bbgl/bbglInfo.vue'),
      meta: { title: '报表管理', icon: 'el-icon-warning-outline' }
    }]
  },
  {
    path: '/setting',
    name: 'SETTING',
    component: () =>
      import('@/components/WtLayout/WtMainLayout.vue'),
    meta: { title: '设置管理', icon: 'el-icon-s-operation' },
    children: [{
      path: 'rzglList',
      name: 'RZGLLIST',
      component: () =>
        import('@/modules/setting/rzglList.vue'),
      meta: { title: '日志管理', icon: 'el-icon-warning-outline' }
    }, {
      path: 'about',
      name: 'ABOUT',
      component: () =>
        import('@/modules/setting/about.vue'),
      meta: { title: '关于我们', icon: 'el-icon-warning-outline' }
    }, {
      path: 'yszc',
      name: 'YSZC',
      component: () =>
        import('@/modules/setting/yszc.vue'),
      meta: { title: '隐私政策', icon: 'el-icon-warning-outline' }
    }, {
      path: 'xieyi',
      name: 'XIEYI',
      component: () =>
        import('@/modules/setting/xieyi.vue'),
      meta: { title: '协议管理', icon: 'el-icon-warning-outline' }
    }, {
      path: 'banben',
      name: 'BANBEN',
      component: () =>
        import('@/modules/setting/banben.vue'),
      meta: { title: '版本管理', icon: 'el-icon-warning-outline' }
    }, {
      path: 'mine',
      name: 'MINE',
      component: () =>
        import('@/modules/setting/mine.vue'),
      meta: { title: '个人中心', icon: 'el-icon-warning-outline' }
    }]
  }
]

const originalPush = Router.prototype.push
Router.prototype.push = function push(location) {
  return originalPush.call(this, location).catch(err => err)
}

const router = new Router({
  // mode: 'history', // 后端支持可开
  scrollBehavior: () => ({ y: 0 }),
  routes: dynamicRouters
})

router.afterEach((to, from) => {
  store.dispatch('updateCurrentRoute', to)
  if (to.meta && !to.meta.hiddenInTab) {
    store.dispatch('addView', to)
  }
})

export default router
