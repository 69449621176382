<template>
  <el-dropdown class="wt-user-panel" placement="bottom" size="default" @command="onClickCommand">
    <div class="wt-dropdown-link" >
      <el-avatar class="wt-avator" size="medium">{{userInfo.realname}}</el-avatar>
      <span class="title">{{userInfo.realname}}</span>
      <i class="el-icon-arrow-down el-icon--right"></i>
    </div>
    <el-dropdown-menu slot="dropdown">
      <el-dropdown-item command="userCenter"><i class="el-icon-user"></i>个人中心</el-dropdown-item>
      <el-dropdown-item command="logout"><i class="el-icon-switch-button"></i>退出登录</el-dropdown-item>
    </el-dropdown-menu>
  </el-dropdown>
</template>

<script>
import { mapState } from 'vuex'

export default {
  name: 'WtUserPanel',
  methods: {
    onClickCommand(command) {
      if (command === 'logout') {
        this.onClickLogout()
      }
      if (command === 'userCenter') {
        this.$router.push({ path: '/setting/mine' })
      }
    },
    onClickLogout() {
      this.$confirm('是否退出?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'info'
      }).then(() => {
        this.$store.dispatch('requestLogout').then(() => {
          location.reload()
        })
      }).catch(() => {
      })
    }
  },
  computed: {
    ...mapState({
      userInfo: state => state.auth.userInfo
    })
  }
}
</script>

<style lang="scss" scoped>
.wt-user-panel{
}
.wt-dropdown-link{
  display: flex;align-items: center;
  > * {
    cursor: pointer;
  }
  .wt-avator {
    margin-left: 16px;
    margin-right: 6px;
  }
}
</style>
