<template>
  <div class="page-container">
    <wt-data-form class="wt-table-full" ref="dataForm" :form.sync="form"
                  request-list-url="/auth/userList"
                  request-delete-url="/auth/userDelete"
                  request-detail-url="/auth/userDetail">
      <template slot="search">
        <wt-search-form-item title="用户名" v-model="form.formData.username" type="input"/>
        <wt-search-form-item title="用户姓名" v-model="form.formData.realname" type="input"/>
        <wt-search-form-item title="所属角色" v-model="form.formData.roleIdList" type="multi-select" :multipleLimit="5" :dataList="roleList"/>
        <wt-search-form-item title="账号状态" v-model="form.formData.isPublish" type="select" :dataList="$wtConst.ISPUBLISH_ENUM_OPTIONS"/>
      </template>
      <template slot="toolbar">
        <wt-button type="primary" v-has-any-premission="['SYSUSER:ADD']" @click="onClickAdd">新建账号</wt-button>
      </template>
      <template slot="table">
        <el-table-column type="index" label="序号"></el-table-column>
        <el-table-column prop="username" label="用户名"></el-table-column>
        <el-table-column prop="realname" label="用户姓名" sortable></el-table-column>
        <el-table-column prop="mobile" label="手机号码"></el-table-column>
        <el-table-column prop="roleNameList" label="所属角色">
          <template slot-scope="scope">
            <span>{{scope.row.roleNameList && scope.row.roleNameList.length > 0 ? scope.row.roleNameList.join(',') : ''}}</span>
          </template>
        </el-table-column>
        <el-table-column prop="mobile" label="电话"></el-table-column>
        <el-table-column prop="isPublish" label="状态">
          <template slot-scope="scope"><wt-enum-content v-bind="$wtConst.ISPUBLISH_ENUM_CONTENT[scope.row.isPublish]"></wt-enum-content></template>
        </el-table-column>
        <el-table-column label="操作" fixed="right" width="150">
          <template slot-scope="scope">
            <wt-text-button v-has-any-premission="['SYSUSER:VIEW']" @click="onClickView(scope.row)">查看</wt-text-button>
            <wt-text-button v-has-any-premission="['SYSUSER:UPDATE']" @click="onClickEdit(scope.row)">编辑</wt-text-button>
            <wt-text-button v-if="scope.row.isPublish === 0" v-has-any-premission="['SYSUSER:DELETE']" @click="onClickDelete(scope.row)" class="danger">删除</wt-text-button>
          </template>
        </el-table-column>
      </template>
    </wt-data-form>
    <!--弹层 开始-->
    <wt-page-dialog ref="editDrawer" title="账号详情">
      <user-detail :detail-data="form.detailData" :view-type="form.dialogViewType"
                    @on-close="$refs.editDrawer.close()" @on-refresh="$refs.dataForm.requestDataList()"></user-detail>
    </wt-page-dialog>
    <!--弹层 结束-->
  </div>
</template>

<script>
import { mapState } from 'vuex'

export default {
  name: 'userList',
  data() {
    return {
      form: {
        table: { flag: 'result' },
        isFromLoading: false,
        isViewDialogVisible: false,
        isEditDialogVisible: false,
        dialogViewType: 'view',
        detailData: {},
        formData: {
          isPublish: '',
          username: '',
          realname: '',
          roleIdList: []
        },
        pageData: { currentPage: 1, pageSize: 20, sortName: '', sortOrder: '' },
        formResult: {}
      },
      areaList: [],
      roleList: []
    }
  },
  mounted() {
    this.$refs.dataForm.requestDataList()
    this.getRoleList()
  },
  created() {
  },
  methods: {
    onClickView(rowData) {
      this.form.dialogViewType = 'view'
      this.form.detailData = rowData
      this.$refs.editDrawer.open()
    },
    onClickEdit(rowData) {
      this.form.dialogViewType = 'edit'
      this.form.detailData = rowData
      this.$refs.editDrawer.open()
    },
    onClickAdd() {
      this.form.dialogViewType = 'add'
      this.$refs.editDrawer.open()
    },
    onClickDelete(rowData) {
      this.$confirm('此操作将永久删除账号, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        this.$wtRequest({
          url: '/auth/userDelete',
          method: 'post',
          data: {
            list: [rowData.id]
          }
        }).then(res => {
          if (res.code === '0') {
            this.$message({ type: 'success', message: '删除成功' })
            this.$refs.dataForm.requestDataList()
          }
        })
      }).catch(() => {
        this.$message({ type: 'success', message: '删除失败' })
      })
    },
    // 获取角色
    async getRoleList() {
      this.roleList = await this.$store.dispatch('getRoleList')
    }
  },
  computed: {
    ...mapState({})
  }
}
</script>
