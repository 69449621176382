<template>
  <wt-page>
    <div class="wt-page-container" v-loading="isFromLoading">
      <div class="page-detail">
        <wt-page-section title="基本信息">
           <div class="module-permission-content">
            <div class="form-container">
            <el-form ref="form" :model="form" class="wt-page-form" label-width="120px" size="small">
              <el-form-item label="用户名：" prop="id">
                <!-- <el-input v-model="detail.code" maxlength="20" placeholder="请输入" :disabled="viewType === 'view'"></el-input> -->
                <div class="text">{{detail.username}}</div>
              </el-form-item>
              <el-form-item label="用户姓名：" prop="name">
                <div class="text">{{detail.realname}}</div>
              </el-form-item>
              <el-form-item label="当前密码：" prop="password"
                            :rules="[ { required: true, message: '当前密码不能为空', validator: $wtValidator.ValidatorNotBlank, trigger: 'blur', }, ]">
                <el-input v-model="form.password" type="password" maxlength="20" placeholder="请输入"></el-input>
              </el-form-item>
              <el-form-item class="" label="密码：" prop="newPassword"
                :rules="[ { required: true, message: '密码不能为空', validator: $wtValidator.ValidatorNotBlank, trigger: 'blur', }, ]">
                <el-input v-model="form.newPassword" type="password" maxlength="20" placeholder="请输入"></el-input>
              </el-form-item>
              <el-form-item class="" label="确认密码：" prop="confirmPassword"
                :rules="[ { required: true, message: '确认密码不能为空', validator: $wtValidator.ValidatorNotBlank, trigger: 'blur', }, ]">
                <el-input v-model="form.confirmPassword" type="password" maxlength="20" placeholder="请输入"></el-input>
              </el-form-item>
            </el-form>
            </div>
          </div>
          <!-- <div class="footer">
            <wt-button type="primary" :disabled="!form.password.trim() || !form.newPassword.trim() || !form.confirmPassword.trim()" @click="onClickSave">保存</wt-button>
          </div> -->
        </wt-page-section>
      </div>
    </div>
    <!-- <div class="wt-page-footer">
      <wt-button type="primary" :disabled="!form.password.trim() || !form.newPassword.trim() || !form.confirmPassword.trim()" @click="onClickSave">保存</wt-button>
    </div> -->
    <div slot="footer">
      <!-- <wt-button type="default" @click="onClickClose">取消</wt-button> -->
      <wt-button type="primary" :disabled="!form.password.trim() || !form.newPassword.trim() || !form.confirmPassword.trim()" @click="onClickSave">保存</wt-button>
    </div>
  </wt-page>
</template>

<script>
import { mapState } from 'vuex'

export default {
  name: 'companyDetail',
  isAutoComponent: true,
  props: ['viewType', 'detailData'],
  data() {
    return {
      isButtonLoading: false,
      isFromLoading: false,
      detail: {},
      form: {
        password: '',
        newPassword: '',
        confirmPassword: ''
      }
    }
  },
  async mounted() {
    this.requestDetail()
  },
  created() {},
  methods: {
    onClickClose() {
      this.$emit('on-close')
    },
    valueChange(val) {
      this.value = val
    },
    onClickSave() {
      const that = this
      if (that.form.newPassword !== that.form.confirmPassword) {
        that.$message.error('新密码与确认密码不一致')
        return false
      }
      that.readonly = true
      that.isFromLoading = true
      that.$wtRequest({
        url: '/auth/userUpdatePwd',
        method: 'post',
        data: {
          id: that.orgId,
          password: that.form.password,
          newPassword: that.form.newPassword,
          confirmPassword: that.form.confirmPassword
        }
      }).then((resp) => {
        that.isFromLoading = false
        that.$message({ type: 'success', message: '保存成功' })
      }).catch((e) => {
        that.isFromLoading = false
        // that.$message({ type: 'error', message: '保存失败' })
      })
    },
    onClickEdit() {
      const that = this
      that.readonly = false
    },
    initDetailData(data) {
      this.detail = data
    },
    requestDetail() {
      const that = this
      that.isFromLoading = true
      that.$wtRequest({
        url: '/auth/userDetail',
        method: 'post',
        data: {
        }
      }).then((resp) => {
        console.log('requestDetail resp', resp)
        this.initDetailData(resp.data)
        that.isFromLoading = false
      }).catch((e) => {
        that.isFromLoading = false
      })
    }
  },
  computed: {
    ...mapState({
      orgId: state => state.auth.orgId
    })
  }
}
</script>
<style lang="scss" scoped>
  .wt-page-container {
    padding: 0;
    min-height: 500px;
  }
  .main-box {
    padding: 20px 10px;
    display: flex;
    .input {
      width: 400px;
      margin-right: 20px;
    }
  }
  .footer {
    display: flex;
    padding: 15px 120px;
    justify-content: flex-start;
  }
  .form-container {
    padding-top: 40px;
    width: 400px;
    .text {
      color: #666;
    }
  }
</style>
